import React from 'react'
import { Link } from 'react-router-dom'
import { Image } from '../../AbstractElements'
import { dynamicImage } from '../../Utils'

export default function LogoIconWrapper() {
  return (
    <div className="logo-icon-wrapper">
      <Link to={`${process.env.PUBLIC_URL}/dashboard/default`}>
        <Image className="img-fluid" src={dynamicImage(`logos/letter-p__64x64.png`)} alt="Portlab" />
        {/* <Image className="img-fluid" src={dynamicImage(`logos/yellow-collapsed-logo.png`)} alt="Portlab" /> */}
      </Link>
    </div>
  )
}
