import { Dispatch } from 'redux';
import { setName, setUserDetailsOwn, setUserId } from '../reducers/UserReducer/UserReducer';
import { getUser, getUserDetailOwn } from 'Services/UserService';

import i18n from '../../i18n';

import { toast } from 'react-toastify';

export const fetchUser = (userId: string) => async (dispatch: Dispatch) => {
  try {
    const userData = await getUser(userId);
    dispatch(setName(userData.email));
  } catch (error: any) {
    toast.error(i18n.t(`ERRORS.${error?.response?.data?.meta?.errorCode}`));
  }
};

export const fetchUserDetailOwn = () => async (dispatch: Dispatch) => {
  try {
    const userData = await getUserDetailOwn();
    dispatch(setUserDetailsOwn(userData));
    dispatch(setUserId(userData.id));
  } catch (error: any) {
    (error?.response?.status !== 401) && toast.error(i18n.t(`ERRORS.${error?.response?.data?.meta?.errorCode}`));
  }
};
