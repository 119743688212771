import * as Paths from "./../files/Paths";
import * as Headers from "./../files/Headers";
import request from "./../files/Request";

// Types
import { ListFilterType } from "Types/Common/ListFilterType";
import { BrandType } from "Types/Debit/BrandType";

// Constants
import { defaultPageAndSizeForAll } from "Utils/Constants";

// Brands
export const getBrands = async (
  brandListFilter: ListFilterType,
  filterObj: any
) => {
  const filterParams = {
    name: filterObj?.name,
    active: filterObj?.active,
    createdDate: filterObj?.createdDate
  };

  return request.get(
    `${Paths.debit}/brand/find-all/paginated`,
    {
      page: brandListFilter.page,
      size: brandListFilter.size,
      ...(brandListFilter?.sort && {sort: brandListFilter?.sort[0]}),
      ...filterParams
    },
    { headers: Headers.headers }
  );

  // const filterParams = {
  //   name: filterObj?.name,
  //   active: filterObj?.active,
  // };

  // const queryDateParams = filterObj?.createdDate
  //   ? filterObj.createdDate
  //       .map((date: any) => `createdDate=${encodeURIComponent(date)}`)
  //       .join("&")
  //   : "";

  // const baseUrl = `${Paths.debit}/brand/find-all/paginated`;
  // const queryParams = [
  //   `page=${brandListFilter.page}`,
  //   `size=${brandListFilter.size}`,
  //   brandListFilter?.sort
  //     ? `sort=${encodeURIComponent(brandListFilter?.sort[0])}`
  //     : null,
  //   queryDateParams
  // ]
  //   .filter(Boolean)
  //   .join("&");

  // const fullUrl = `${baseUrl}?${queryParams}`;

  // return request.get(
  //   fullUrl,
  //   { ...filterParams },
  //   {
  //     headers: Headers.headers,
  //   }
  // );
};

export const getBrandsAll = async () => {
  return request.get(
    `${Paths.debit}/brand/find-all/paginated`,
    {
      page: defaultPageAndSizeForAll.page,
      size: defaultPageAndSizeForAll.size,
    },
    { headers: Headers.headers }
  );
};

export const getBrandById = async (id: any) => {
  return request.get(
    `${Paths.debit}/brand/find-by-id/${id}`,
    {},
    { headers: Headers.headers }
  );
};

export const deleteBrand = async (brandId: string) => {
  return request.delete(
    `${Paths.debit}/brand/toggle-delete/${brandId}`,
    {},
    {
      headers: Headers.headers,
    }
  );
};

export const updateBrand = async (brand: BrandType) => {
  return request.put(`${Paths.debit}/brand/update`, brand, {
    headers: Headers.headers,
  });
};

export const addBrand = async (brand: BrandType) => {
  return request.post(
    `${Paths.debit}/brand/create`,
    brand,
    {},
    {
      headers: Headers.headers,
    }
  );
};
