import React from "react";
import { Link } from "react-router-dom";
import { Image } from "../../AbstractElements";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux-toolkit/store";
import { setSideBarToggle } from "../../redux-toolkit/reducers/LayoutReducer";
import SvgIcon from "../../Utils/CommonComponents/CommonIcons/CommonSvgIcons";
import { dynamicImage } from "../../Utils";

export default function LogoWrapper() {
  const dispatch = useDispatch();
  const layoutState = useSelector((state: RootState) => state.layout);
  const handleSidebarToggle = () => {
    dispatch(setSideBarToggle(!layoutState.sideBarToggle));
  };

  return (
    <div className='logo-wrapper'>
      <Link to={`${process.env.PUBLIC_URL}/dashboard/default`} className='d-flex align-items-center'>
        <Image className='img-fluid mr-2' src={dynamicImage(`logos/letter-p__64x64.png`)} alt='Portlab' />
        <span className='brand-name font-weight-bold'><strong>Portlab</strong></span>
        {/* <Image className='img-fluid mr-2' src={dynamicImage(`logos/white-yellow.png`)} alt='Portlab' /> */}
      </Link>
      <div className='toggle-sidebar' onClick={handleSidebarToggle}>
        <SvgIcon className='sidebar-toggle' iconId='toggle-icon' />
      </div>
    </div>
  )
}
