import React from "react";
import { Col, Row } from "reactstrap";
import { H6 } from "AbstractElements";
import { useTranslation } from "react-i18next";

import { PiIdentificationBadge, PiGenderIntersex } from "react-icons/pi";
import {
  MdOutlinePermContactCalendar,
  MdOutlineBloodtype,
} from "react-icons/md";
import { GrLocation } from "react-icons/gr";
import { RootState } from "redux-toolkit/store";
import { useSelector } from "react-redux";

// Types
import { GenderTypes } from "Enums/User/GenderType";

export default function ProfilePersonelInfos() {
  const { t } = useTranslation();

  const userInfos = useSelector((state: RootState) => state.user);

  return (
    <Col sm={12} xl={12} className="order-sm-1 order-xl-0">
      <Row className="g-3">
        <Col md={4}>
          <div className="text-start tour-email">
            <H6 className="tour-mb-space">
              <PiIdentificationBadge className="me-2" />{" "}
              {t("Common.IdentityNo")}
            </H6>
            <span>
              {userInfos.identityNumber ? userInfos.identityNumber : "-"}
            </span>
          </div>
        </Col>
        <Col md={4}>
          <div className="text-start tour-email">
            <H6 className="tour-mb-space">
              <PiGenderIntersex className="me-2" /> {t("Common.Gender")}
            </H6>
            <span>
              {userInfos.genderType
                ? t(
                    `Common.${
                      GenderTypes[
                        userInfos.genderType as keyof typeof GenderTypes
                      ]
                    }`
                  )
                : "-"}
            </span>
          </div>
        </Col>
        <Col md={4}>
          <div className="text-start tour-email">
            <H6 className="tour-mb-space">
              <i className="fa fa-envelope me-2" /> {t("Common.Email")}
            </H6>
            <span>{userInfos.email ? userInfos.email : "-"}</span>
          </div>
        </Col>
        <Col md={4} className="mt-0 mt-sm-3">
          <div className="text-start ttl-xs-mt tour-email">
            <H6 className="tour-mb-space">
              <i className="fa fa-phone me-2" /> {t("Common.Phone")}
            </H6>
            <span>{userInfos.phoneNumber ? userInfos.phoneNumber : "-"}</span>
          </div>
        </Col>
        <Col md={4}>
          <div className="text-start ttl-sm-mb-0 tour-email">
            <H6 className="tour-mb-space">
              <i className="fa fa-calendar me-2" /> {t("Common.Birthday")}
            </H6>
            <span>{userInfos.birthdate ? userInfos.birthdate : "-"}</span>{" "}
            {/* what is date format */}
          </div>
        </Col>
        <Col md={4}>
          <div className="text-start ttl-sm-mb-0 tour-email">
            <H6 className="tour-mb-space">
              <MdOutlinePermContactCalendar className="me-2" />{" "}
              {t("Common.Contact")}
            </H6>
            <span>{userInfos.contact ? userInfos.contact : "-"}</span>
          </div>
        </Col>
        <Col md={4}>
          <div className="text-start ttl-sm-mb-0 tour-email">
            <H6 className="tour-mb-space">
              <GrLocation className="me-2" /> {t("Common.Address")}
            </H6>
            <span>{userInfos.address ? userInfos.address : "-"}</span>
          </div>
        </Col>
        <Col md={4}>
          <div className="text-start ttl-sm-mb-0 tour-email">
            <H6 className="tour-mb-space">
              <MdOutlineBloodtype className="me-2" /> {t("Common.BloodType")}
            </H6>
            <span>{userInfos.bloodType ? userInfos.bloodType : "-"}</span>
          </div>
        </Col>
      </Row>
    </Col>
  );
}
