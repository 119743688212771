import * as Paths from "./../files/Paths";
import * as Headers from "./../files/Headers";
import request from "./../files/Request";


export const getCountries = async () => {
  return request.get(
    `${Paths.crm}/area/countries`, {},
    {
      headers: Headers.headers,
    },
  );
};

export const getCities = async (countryId: string) => {
  return request.get(
    `${Paths.crm}/area/cities/${countryId}`, {},
    {
      headers: Headers.headers,
    },
  );
};

export const getDistricts = async (cityId: string) => {
  return request.get(
    `${Paths.crm}/area/districts/${cityId}`, {},
    {
      headers: Headers.headers,
    },
  );
};