import {ListFilterType} from "Types/Common/ListFilterType";
import request from "Services/files/Request";
import * as Paths from "Services/files/Paths";
import * as Headers from "Services/files/Headers";
import {defaultPageAndSizeForAll} from "Utils/Constants";

export const getTables = async (filter: ListFilterType, filterObj: any) => {
  const filterParams = {
    name: filterObj?.name,
  };
  return request.get(
    `${Paths.emission}/experiment-table/all`,
    {
      page: filter.page,
      size: filter.size,
      ...(filter?.sort && {sort: filter?.sort[0]}),
      ...filterParams
    },
    { headers: Headers.headers }
  );
};

export const getTablesAll = async () => {
  return request.get(
    `${Paths.emission}/experiment-table/all/minimal`,
    {
      page: defaultPageAndSizeForAll.page,
      size: defaultPageAndSizeForAll.size
    },
    { headers: Headers.headers }
  );
};


export const getTablesForOffer = async () => {
  return request.get(
    `${Paths.emission}/experiment-table/all/list`,
    {
      page: defaultPageAndSizeForAll.page,
      size: defaultPageAndSizeForAll.size
    },
    { headers: Headers.headers }
  );
};

export const deleteTable = async (id: string) => {
  return request.delete(
    `${Paths.emission}/experiment-table/${id}`,
    {},
    {
      headers: Headers.headers,
    }
  );
};

export const addTable = async (table: any) => {
  return request.post(`${Paths.emission}/experiment-table`, table, {}, {
    headers: Headers.headers,
  });
};

export const updateTable = async (table: any) => {
  return request.put(`${Paths.emission}/experiment-table`, table, {
    headers: Headers.headers,
  });
};

export const getById = async (id: any) => {
  return request.get(
    `${Paths.emission}/experiment-table/${id}`,
    {},
    { headers: Headers.headers }
  );
};