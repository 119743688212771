import { Card, CardBody, Col } from "reactstrap";
import { useTranslation } from "react-i18next";
import ReactApexChart from "react-apexcharts";
import DropdownWithHeader from "../../../../../Utils/CommonComponents/DashboardsCommon/DropdownWithHeader";
import {
  monthlyDropdownList,
  salesOverviewOptions,
} from "Data/DashboardsData/DefaultData";

export default function SalesOverview() {
  const { t } = useTranslation();

  return (
    <Col xxl={9} xl={80} md={8} className="box-col-6 proorder-xl-8">
      <Card className="sales overview">
        <DropdownWithHeader
          headerClass="card-no-border pb-0"
          heading={t("Common.SalesOverview")}
          dropDownList={monthlyDropdownList}
          dropDownClass="icon-dropdown"
          dropDownIcon={true}
        />
        <CardBody className="p-0">
          <div className="sales-chart">
            <div className="shap-2">
              <div className="rounded-shap animate-bg-secondary">
                <i></i>
                <i></i>
              </div>
            </div>
            <div className="shap-2">
              <div className="rounded-shap animate-bg-secondary">
                <i></i>
                <i></i>
              </div>
            </div>
            <div className="shap-2">
              <div className="rounded-shap animate-bg-secondary">
                <i></i>
                <i></i>
              </div>
            </div>
            <div className="shap-2">
              <div className="rounded-shap animate-bg-secondary">
                <i></i>
                <i></i>
              </div>
            </div>
            <div id="sales-overview">
              <ReactApexChart
                options={salesOverviewOptions}
                series={salesOverviewOptions.series}
                type="line"
                height={257}
              />
            </div>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
}
