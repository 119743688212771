import {ListFilterType} from "Types/Common/ListFilterType";
import request from "Services/files/Request";
import * as Paths from "Services/files/Paths";
import * as Headers from "Services/files/Headers";
import {defaultPageAndSizeForAll} from "Utils/Constants";

export const getAnalysisMethods = async (filter: ListFilterType, filterObj: any) => {
  const filterParams = {
    name: filterObj?.name,
    standardNo: filterObj?.standardNo,
    "laboratory.id": filterObj?.laboratory
  };
  return request.get(
    `${Paths.emission}/analysis-method/all`,
    {
      page: filter.page,
      size: filter.size,
      ...(filter?.sort && {sort: filter?.sort[0]}),
      ...filterParams
    },
    { headers: Headers.headers }
  );
};

export const getAnalysisMethodsAll = async () => {
  return request.get(
    `${Paths.emission}/analysis-method/all/minimal`,
    {
      page: defaultPageAndSizeForAll.page,
      size: defaultPageAndSizeForAll.size
    },
    { headers: Headers.headers }
  );
};

export const deleteAnalysisMethod = async (id: string) => {
  return request.delete(
    `${Paths.emission}/analysis-method/${id}`,
    {},
    {
      headers: Headers.headers,
    }
  );
};

export const addAnalysisMethod = async (analysisMethod: any) => {
  return request.post(`${Paths.emission}/analysis-method`, analysisMethod, {}, {
    headers: Headers.headers,
  });
};

export const updateAnalysisMethod = async (analysisMethod: any) => {
  return request.put(`${Paths.emission}/analysis-method`, analysisMethod, {
    headers: Headers.headers,
  });
};

export const getById = async (id: any) => {
  return request.get(
    `${Paths.emission}/analysis-method/${id}`,
    {},
    { headers: Headers.headers }
  );
};