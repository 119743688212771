import React, { useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Collapse,
  Container,
  Input,
  Label,
  Row,
} from "reactstrap";
import { Form as AntForm } from "antd";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Btn, LI, P, UL } from "AbstractElements";
import { toast } from "react-toastify";
import { SubmitHandler } from "react-hook-form";
import DataTable, { TableColumn } from "react-data-table-component";
import { defaultPageAndSize, Href } from "Utils/Constants";
import { Plus } from "react-feather";

// Services
import * as educationSubjectService from "Services/EducationServices/Subject";
import * as educationCategoryService from "Services/EducationServices/Category";

// Types
import { ListFilterType } from "Types/Common/ListFilterType";
import { Subject } from "Types/Education/Subject";

export default function CategorySubjects({ category }: any) {
  const { t } = useTranslation();
  const [form] = AntForm.useForm();

  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [tableData, setTableData] = useState(
    category?.subjects ? category.subjects : ([] as Subject[])
  );

  const [tableFilter, setTableFilter] = useState(
    defaultPageAndSize as ListFilterType
  );
  const [totalRows, setTotalRows] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  const handleFilterToggle = () => {
    setIsFilterOpen((prevState) => !prevState);
  };

  const handleCollapseCancel = () => {
    handleFilterToggle();
  };

  const addSubject: SubmitHandler<any> = async (formData) => {
    const newData = {
      ...formData,
      category: {
        id: category?.id,
      },
    };
    const { data, error }: any = await educationSubjectService.addSubject(
      newData
    );
    if (error) {
      toast.error(t(`ERRORS.${error?.errorCode}`));
    } else {
      toast.success(t("COMMON_MESSAGES.addedSuccessfully"));
      // setTableData([...tableData, formData]);
      getCategory(category.id);
      form.resetFields();
    }
  };

  const getCategory = async (id: any) => {
    const { data, error }: any = await educationCategoryService.getCategory(id);
    setTableData([...data?.body?.subjects]);
    if (error) toast.error(t(`ERRORS.${error?.errorCode}`));
  };

  const deleteSubject = async (id: string) => {
    const { error }: any = await educationSubjectService.deleteSubject(id);
    if (error) {
      toast.error(t(`ERRORS.${error?.errorCode}`));
    } else {
      toast.success(t("COMMON_MESSAGES.deletedSuccessfully"));
      setTableData(tableData.filter((item: any) => item.id !== id));
    }
  };

  const tableColumns: TableColumn<any>[] = [
    {
      name: t("Common.name"),
      selector: (row) => row.name,
      sortable: true,
      cell: (row) => (
        <P className="f-light-darker">{row.name ? row.name : "-"}</P>
      ),
    },
    {
      name: t("Common.Actions"),
      width: "80px",
      cell: (row) => (
        <>
          <UL className="action simple-list flex-row">
            <LI className="delete">
              <Link onClick={() => deleteSubject(row.id)} to={""}>
                <i className="icon-trash" />
              </Link>
            </LI>
          </UL>
        </>
      ),
      sortable: false,
    },
  ];

  return (
    <div>
      <Container fluid className="add-contact-form">
        <Row>
          <Col sm={12}>
            <Card>
              <CardBody>
                <div className="light-box">
                  <div className="d-flex justify-content-between align-items-center">
                    <label>{t("EducationPages.Subjects")}</label>
                    <a href={Href} onClick={handleFilterToggle}>
                      {isFilterOpen ? (
                        <i className="icon-close filter-close" />
                      ) : (
                        <Plus />
                      )}
                    </a>
                  </div>
                </div>
                <Collapse className={isFilterOpen ? "show" : ""}>
                  <Card className="list-product-body">
                    <CardBody>
                      <AntForm
                        form={form}
                        className="theme-form"
                        onFinish={addSubject}
                        autoComplete="off"
                        initialValues={{
                          name: "",
                          ibanNumber: "",
                        }}
                      >
                        <Row>
                          <Col md={12}>
                            <Label>
                              {t("EducationPages.SubjectName") + " *"}
                            </Label>
                            <AntForm.Item
                              name="name"
                              validateTrigger={["onChange", "onBlur"]}
                              rules={[
                                {
                                  required: true,
                                  whitespace: true,
                                  message: t(
                                    "FormValidations.EducationPages.SubjectNameRequired"
                                  ),
                                },
                              ]}
                            >
                              <Input
                                placeholder={`${t(
                                  "EducationPages.SubjectName"
                                )} *`}
                              />
                            </AntForm.Item>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className="text-end">
                              <Btn color="outline-success" className="me-3">
                                {t("Common.Save")}
                              </Btn>
                              <Btn
                                color="outline-danger"
                                className="btn"
                                onClick={handleCollapseCancel}
                              >
                                {t("Common.cancel")}
                              </Btn>
                            </div>
                          </Col>
                        </Row>
                      </AntForm>
                    </CardBody>
                  </Card>
                </Collapse>
                <DataTable
                  data={tableData}
                  columns={tableColumns}
                  pagination={false}
                  noDataComponent={<>{t("Common.noData")}</>}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
