import React, { useEffect, useState } from "react";
import Breadcrumbs from "CommonElements/Breadcrumbs";
import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Label,
  Row,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import { dateFormats } from "Utils/Constants";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Btn } from "AbstractElements";
import { toast } from "react-toastify";
import { DatePicker, Switch } from "antd";
import { SubmitHandler, useForm } from "react-hook-form";
import dayjs from "dayjs";

// Services
import * as brandService from "Services/DebitServices/Brand";
import * as deviceService from "Services/DebitServices/Device";

// Types
import { DeviceType } from "Types/Debit/Device/DeviceType";

export default function UpdateDevice() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { id } = useParams<{ id: string | undefined }>();

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
  } = useForm<any>();

  const [brandList, setBrandList] = useState([]);
  const [takeBeforeMesure, setTakeBeforeMesure] = useState(false);
  const [shouldCalibrate, setShouldCalibrate] = useState(false);
  const [shouldIntermediateCalibration, setShouldIntermediateCalibration] =
    useState(false);
  const [warrantyDate, setWarrantyDate] = useState("");

  const [deviceDetails, setDeviceDetails] = useState({} as DeviceType);

  const [selectedBrand, setSelectedBrand] = useState(null as any);

  const updateDevice: SubmitHandler<any> = async (formData) => {
    const updatedData = {
      ...deviceDetails,
      name: formData.name ? formData.name : deviceDetails?.name,
      brand: {
        // ...selectedBrand,
        id: formData.brand ? formData.brand : deviceDetails?.brand?.id,
      },
      serialNumber: formData.serialNumber ? formData.serialNumber : deviceDetails?.serialNumber,
      features: formData.features ? formData.features : deviceDetails?.features,
      takeBeforeMeasure: takeBeforeMesure,
      shouldCalibrate: shouldCalibrate,
      shouldIntermediateCalibration: shouldIntermediateCalibration,
      warrantyDate: dayjs(warrantyDate).format(dateFormats.D_M_Y_H_m_s_Z),
      productType: "DEVICE",
    };
    const { error }: any = await deviceService.updateDevice(updatedData);
    navigate(`${process.env.PUBLIC_URL}/debit/device/list`);
    if (error) toast.error(t(`ERRORS.${error?.errorCode}`));
    else toast.success(t("COMMON_MESSAGES.updatedSuccessfully"));
  };

  const getDeviceById = async () => {
    const { data, error }: any = await deviceService.getDeviceById(id);
    setDeviceDetails(data?.body);
    setSelectedBrand(data?.brand);
    setWarrantyDate(data?.body?.warrantyDate);
    setShouldCalibrate(data?.body?.shouldCalibrate);
    setShouldIntermediateCalibration(data?.body?.shouldIntermediateCalibration);
    setValue("name", data?.body?.name);
    setValue("brand", data?.body?.brand?.id);
    setValue("serialNumber", data?.body?.serialNumber);
    setValue("quantity", data?.body?.quantity);
    setValue("features", data?.body?.features);
    if (error) toast.error(t(`ERRORS.${error?.errorCode}`));
  };

  const getBrands = async () => {
    const { data, error }: any = await brandService.getBrandsAll();
    setBrandList(data?.body?.content);
    if (error) toast.error(error?.meta.errorDescription);
  };

  useEffect(() => {
    getDeviceById();
  }, [id]);

  useEffect(() => {
    getBrands();
  }, []);

  return (
    <div className="page-body">
      <Breadcrumbs
        pageTitle={t("DebitPages.EditDevice")}
        parent={t("DebitPages.Debit")}
        subParent={t("DebitPages.DevicePageTitle")}
        subParentLink={`${process.env.PUBLIC_URL}/debit/device/list`}
        title={t("DebitPages.EditDevice")}
      />
      <Container fluid>
        <Row>
          <Col sm={12}>
            <Card>
              <CardBody>
                <Form
                  className="theme-form"
                  onSubmit={handleSubmit(updateDevice)}
                >
                  <Row>
                    <Col xs={12} md={6}>
                      <FormGroup>
                        <Label>{t("Common.name") + " *"}</Label>
                        <input
                          className="form-control"
                          type="text"
                          placeholder={`${t("Common.name")}`}
                          {...register("name", {
                            validate: (value) =>
                              value !== "" || getValues("name") !== "",
                          })}
                        />
                        <span className="form-error-text">
                          {errors.name &&
                            t("FormValidations.DebitPages.NameRequired")}
                        </span>
                      </FormGroup>
                    </Col>
                    <Col xs={12} md={6}>
                      <FormGroup>
                        <Label>{t("Common.brand") + " *"}</Label>
                        <select
                          className="form-control"
                          {...register("brand", {
                            validate: (value) =>
                              value !== "" || getValues("brand") !== "",
                          })}
                          onChange={(event) => {
                            const selectedId = event.target.value;
                            const selectedBrandObj = brandList.find(
                              (item: any) => item.id === selectedId
                            );
                            setSelectedBrand(selectedBrandObj);
                            setValue("brand", selectedId);
                          }}
                        >
                          <option value="">{t("Common.PleaseSelect")}</option>
                          {brandList.map((item: any, ind) => (
                            <option
                              key={ind}
                              value={item.id}
                              selected={item.id === deviceDetails?.brand?.id}
                            >
                              {item?.name}
                            </option>
                          ))}
                        </select>
                        <span className="form-error-text">
                          {errors.brand &&
                            t("FormValidations.DebitPages.BrandRequired")}
                        </span>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={6}>
                      <FormGroup>
                        <Label>{t("Common.serialNumber")}</Label>
                        <input
                          className="form-control"
                          type="text"
                          placeholder={`${t("Common.serialNumber")}`}
                          {...register("serialNumber", {
                            validate: (value) =>
                              value !== "" || getValues("serialNumber") !== "",
                          })}
                        />
                        <span className="form-error-text">
                          {errors.serialNumber &&
                            t(
                              "FormValidations.DebitPages.SerialNumberRequired"
                            )}
                        </span>
                      </FormGroup>
                    </Col>
                    <Col xs={12} md={6}>
                      <FormGroup>
                        <Label>{t("Common.warrantyDate") + " *"}</Label>
                        <DatePicker
                          // showTime
                          onChange={(e: any) => {
                            setWarrantyDate(e);
                          }}
                          defaultOpenValue={dayjs(deviceDetails?.warrantyDate)}
                          value={dayjs(warrantyDate)}
                          format={dateFormats.Y_M_D}
                          className={`form-control`}
                        />
                        <span className="form-error-text">
                          {errors.warrantyDate &&
                            t(
                              "FormValidations.DebitPages.WarrantyDateRequired"
                            )}
                        </span>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className="mb-4">
                    <Col xs={12} md={4}>
                      <FormGroup className="d-flex align-items-center h-100">
                        <Label>{t("Common.shouldCalibrate")}</Label>
                        <div className="mx-3">
                          <Switch
                            checked={shouldCalibrate}
                            onChange={(e: any) => {
                              setShouldCalibrate(e);
                            }}
                          />
                        </div>
                      </FormGroup>
                    </Col>
                    <Col xs={12} md={4}>
                      <FormGroup className="d-flex align-items-center h-100">
                        <Label>
                          {t("Common.shouldIntermediateCalibration")}
                        </Label>
                        <div className="mx-3">
                          <Switch
                            checked={shouldIntermediateCalibration}
                            onChange={(e: any) => {
                              setShouldIntermediateCalibration(e);
                            }}
                          />
                        </div>
                      </FormGroup>
                    </Col>
                    <Col xs={12} md={4}>
                      <FormGroup className="d-flex align-items-center h-100">
                        <Label>{t("Common.takeBeforeMesure")}</Label>
                        <div className="mx-3">
                          <Switch
                            checked={takeBeforeMesure}
                            onChange={(e: any) => {
                              setTakeBeforeMesure(e);
                            }}
                          />
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>{t("Common.features")}</Label>
                        <textarea
                          className="form-control"
                          cols={30}
                          rows={5}
                          {...register("features", { required: false })}
                        ></textarea>
                        <span className="form-error-text">
                          {errors.features &&
                            t("FormValidations.DebitPages.FeaturesRequired")}
                        </span>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="text-end">
                        <Btn color="success" className="me-3">
                          {t("Common.update")}
                        </Btn>
                        <Link
                          className="btn btn-danger"
                          to={`${process.env.PUBLIC_URL}/debit/device/list`}
                        >
                          {t("Common.cancel")}
                        </Link>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
