import React, { useEffect, useState } from "react";
import Breadcrumbs from "CommonElements/Breadcrumbs";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Label,
  Row,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Btn } from "AbstractElements";
import { toast } from "react-toastify";
import { SubmitHandler, useForm } from "react-hook-form";
import { Select, Switch, Tabs } from "antd";
import FeatherIconCom from "Utils/CommonComponents/CommonIcons/FeatherIconCom";

// Services
import * as laboratoryService from "Services/MetriksServices/Laboratory";
import * as analysisMethodsService from "Services/MetriksServices/AnalysisMethod";
import * as tableService from "Services/MetriksServices/Table";
import * as companyService from "Services/CrmServices/Company";
import ParameterLimitSettings from "./components/ParameterLimitSettings";
import * as experimentService from "Services/MetriksServices/Experiment";

// Types
import { ExperimentCriterionType } from "Enums/Metriks/ExperimentCriterionType";
import { SampleCollectMethodType } from "Enums/Metriks/SampleCollectMethodType";
import { StorageContainerType } from "Enums/Metriks/StorageContainerType";
import { StorageConditionType } from "Enums/Metriks/StorageConditionType";
import { SampleMeasurementType } from "Enums/Metriks/SampleMeasurementType";
import { MeasurementResultsType } from "Enums/Metriks/MeasurementResultsType";
import { defaultPageAndSize } from "Utils/Constants";
import { Experiment } from "Types/Emission/Experiment";
const criterionTypes = ExperimentCriterionType;
const sampleCollectMethods = SampleCollectMethodType;
const storageContainerTypes = StorageContainerType;
const storageConditionTypes = StorageConditionType;
const sampleMeasurementTypes = SampleMeasurementType;
const measurementResultsTypes = MeasurementResultsType;

export default function UpdateExperiment() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { id } = useParams<{ id: string | undefined }>();

  const [experimentDetails, setExperimentDetails] = useState({} as Experiment);

  const [selectedAnalysisMethods, setSelectedAnalysisMethods] = useState([]);
  const [selectedExperimentTables, setSelectedExperimentTables] = useState([]);
  const [selectedCriterionType, setSelectedCriterionType] = useState("");
  const [selectedSampleCollectMethodType, setSelectedSampleCollectMethodType] =
    useState("");
  const [selectedStorageContainerType, setSelectedStorageContainerType] =
    useState("");
  const [selectedStorageConditionType, setSelectedStorageConditionType] =
    useState("");
  const [selectedSampleAndMeasurementType, setSelectedSampleAndMeasurementType] =
    useState("");
  const [selectedIncludedParameters, setSelectedIncludedParameters] = useState(
    []
  );
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [selectedUnitTypes, setSelectedUnitTypes] = useState([]);
  const [notUsedInReport, setNotUsedInReport] = useState(false);
  const [storedInSeparateCup, setStoredInSeparateCup] = useState(false);
  const [selectedLab, setSelectedLab] = useState("");
  const [isOnlyOffer, setIsOnlyOffer] = useState(false);

  const [labListData, setLabListData] = useState([]);
  const [analysisMethodListData, setAnalysisMethodListData] = useState([]);
  const [experimentTableListData, setExperimentTableListData] = useState([]);
  const [partnerCompanyListData, setPartnerCompListData] = useState([]);
  const [experimentUnitTypes, setExperimentUnitTypes] = useState([]);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<any>();

  const [openAccordion, setOpenAccordion] = useState("1");
  const toggleAccordion = (id: string) => {
    openAccordion === id ? setOpenAccordion("") : setOpenAccordion(id);
  };
  const [openAccordion2, setOpenAccordion2] = useState("2");
  const toggleAccordion2 = (id: string) => {
    openAccordion2 === id ? setOpenAccordion2("") : setOpenAccordion2(id);
  };
  const [openAccordion3, setOpenAccordion3] = useState("3");
  const toggleAccordion3 = (id: string) => {
    openAccordion3 === id ? setOpenAccordion3("") : setOpenAccordion3(id);
  };

  const updateExperiment: SubmitHandler<any> = async (formData) => {
    const updateData = {
      id: experimentDetails?.id,
      laboratory: {
        id: formData.laboratory.split(".")[0],
      },
      active: true,
      name: formData.name,
      code: formData.code,
      analysisMethods: selectedAnalysisMethods.map((sam: string) => {
        return { id: sam };
      }),
      experimentTables: selectedExperimentTables.map((tab: string) => {
        return { id: tab };
      }),
      price: formData.price ? Number(formData.price) : 0,
      vat: formData.vat ? Number(formData.vat) : 0,
      description: formData.description,
      criterionType: formData.criterionType,
      sampleAndMeasurementType: selectedSampleAndMeasurementType ? selectedSampleAndMeasurementType : null,
      resultType: formData.resultType ? formData.resultType : null,
      normalSampleNumber: formData.normalSampleNumber
        ? Number(formData.normalSampleNumber)
        : 0,
      numberWitnessSample: formData.numberWitnessSample
        ? Number(formData.numberWitnessSample)
        : 0,
      subcontractorCompanyIds: selectedCompanies,
      onlyOffer: isOnlyOffer,
      ...(selectedLab === "EMISSION"
        ? {
            experimentEmission: {
              scope: formData.scope,
              includedParameters: selectedIncludedParameters.map(
                (p: string) => {
                  return { id: p };
                }
              ),
            },
          }
        : {
            experimentEmission: null,
          }),
      ...(selectedLab === "WASTE_WATER"
        ? {
            experimentWastewater: {
              sampleCollectMethodTypes: selectedSampleCollectMethodType ? selectedSampleCollectMethodType : null,
              storageContainerTypes: selectedStorageContainerType ? selectedStorageContainerType : null,
              storageConditionTypes: selectedStorageConditionType ? selectedStorageConditionType : null,
              requiredAmount: formData.requiredAmount
                ? Number(formData.requiredAmount)
                : 0,
              requiredAmountUnit: formData.requiredAmountUnit
                ? formData.requiredAmountUnit
                : null,
              storageTime: formData.storageTime
                ? Number(formData.storageTime)
                : 0,
              uncertaintyPercentage: formData.uncertaintyPercentage
                ? Number(formData.uncertaintyPercentage)
                : 0,
              storedInSeparateCup: storedInSeparateCup,
              notUsedInReport: notUsedInReport,
              unitTypes: selectedUnitTypes?.map((ut: any) => {
                return {
                  id: ut,
                };
              }),
            },
          }
        : {
            experimentWastewater: null,
          }),
    };
    const { error }: any = await experimentService.updateExperiment(updateData);
    if (error) toast.error(t(`ERRORS.${error?.errorCode}`));
    else {
      toast.success(t("COMMON_MESSAGES.updatedSuccessfully"));
      navigate(`${process.env.PUBLIC_URL}/experiments/experiment/list`);
    }
  };

  const getLaboratoryAll = async () => {
    const { data, error }: any = await laboratoryService.getLaboratoriesAll();
    setLabListData(data?.body?.content);
    if (error) {
      toast.error(t(`ERRORS.${error?.errorCode}`));
    }
  };

  const getAnalysisMethods = async () => {
    const { data, error }: any =
      await analysisMethodsService.getAnalysisMethodsAll();
    setAnalysisMethodListData(data?.body.content);
    if (error) {
      toast.error(t(`ERRORS.${error?.errorCode}`));
    }
  };

  const getExperimentTablesAll = async () => {
    const { data, error }: any = await tableService.getTablesAll();
    setExperimentTableListData(data?.body.content);
    if (error) {
      toast.error(t(`ERRORS.${error?.errorCode}`));
    }
  };

  const getPartnerCompaniesAll = async () => {
    const { data, error }: any = await companyService.getCompanies(
      { page: defaultPageAndSize.page, size: defaultPageAndSize.size },
      { companyType: "PARTNER" }
    );
    setPartnerCompListData(data?.body.content);
    if (error) {
      toast.error(t(`ERRORS.${error?.errorCode}`));
    }
  };

  const getExperimentUnitTypes = async () => {
    const { data, error }: any = await experimentService.getExperimentUnitTypes(
      selectedLab
    );
    setExperimentUnitTypes(data?.body?.content);
    if (error) {
      toast.error(t(`ERRORS.${error?.errorCode}`));
    }
  };

  const getExperimentById = async () => {
    const { data, error }: any = await experimentService.getExperimentById(id);
    setExperimentDetails(data?.body);
    setValue("laboratory", data?.body?.laboratory?.id);
    setSelectedLab(data?.body?.laboratory?.type.type);
    setValue("name", data?.body?.name);
    setSelectedAnalysisMethods(
      data?.body?.analysisMethods?.map((method: any) => method.id)
    );
    setSelectedExperimentTables(
      data?.body?.experimentTables?.map((method: any) => method.id)
    );
    setIsOnlyOffer(data?.body?.onlyOffer);
    setValue("code", data?.body?.code);
    setValue("price", data?.body?.price);
    setValue("vat", data?.body?.vat);
    setValue("description", data?.body?.description);
    setValue("criterionType", data?.body?.criterionType);
    setValue("sampleAndMeasurementType", data?.body?.sampleAndMeasurementType);
    setValue("resultType", data?.body?.resultType);
    setValue("normalSampleNumber", data?.body?.normalSampleNumber);
    setValue("numberWitnessSample", data?.body?.numberWitnessSample);
    setSelectedCompanies(data?.body?.subcontractorCompanyIds);
    setSelectedUnitTypes(
      data?.body?.experimentWastewater?.unitTypes?.map((ut: any) => ut.id)
    );
    setSelectedStorageConditionType(data?.body?.experimentWastewater?.storageConditionTypes);
    setSelectedStorageContainerType(data?.body?.experimentWastewater?.storageContainerTypes);
    setSelectedSampleCollectMethodType(data?.body?.experimentWastewater?.sampleCollectMethodTypes);

    setSelectedSampleAndMeasurementType(data?.body?.sampleAndMeasurementType);
    setValue(
      "requiredAmount",
      data?.body?.experimentWastewater?.requiredAmount
    );
    setValue(
      "requiredAmountUnit",
      data?.body?.experimentWastewater?.requiredAmountUnit
    );
    setValue("storageTime", data?.body?.experimentWastewater?.storageTime);
    setValue(
      "uncertaintyPercentage",
      data?.body?.experimentWastewater?.uncertaintyPercentage
    );
    setNotUsedInReport(data?.body?.experimentWastewater?.notUsedInReport);
    setStoredInSeparateCup(
      data?.body?.experimentWastewater?.storedInSeparateCup
    );
    // emisyon
    setSelectedIncludedParameters(
      data?.body?.experimentEmission?.includedParameters.map((p: any) => p.id)
    );
    setValue("scope", data?.body?.experimentEmission?.scope);

    if (error) toast.error(error?.meta.errorDescription);
  };

  useEffect(() => {
    getExperimentById();
  }, [id]);

  useEffect(() => {
    getLaboratoryAll();
    getAnalysisMethods();
    getExperimentTablesAll();
    getPartnerCompaniesAll();
  }, []);

  useEffect(() => {
    getExperimentUnitTypes();
  }, [selectedLab]);

  const returnExperimentUpdateFormContent = () => {
    return (
      <Form className="theme-form" onSubmit={handleSubmit(updateExperiment)}>
        <Row>
          <Col>
            <FormGroup>
              <Label>
                {t("ExperimentPages.LaboratoryPages.Laboratory") + " *"}
              </Label>
              <select
                className="form-control"
                {...register("laboratory", { required: true })}
                onChange={(e) => {
                  setSelectedLab(e.target.value.split(".")[1]);
                  register("laboratory").onChange(e);
                }}
              >
                <option value="">{t("Common.PleaseSelect")}</option>
                {labListData.map((lab: any) => (
                  <option
                    key={lab.id}
                    value={lab.id + "." + lab.type.type}
                    selected={experimentDetails?.laboratory?.id === lab.id}
                  >
                    {lab.name}
                  </option>
                ))}
              </select>
              <span className="form-error-text">
                {errors.laboratory &&
                  t(
                    "FormValidations.ExperimentPages.LaboratoryPages.MeasurementTypeRequired"
                  )}
              </span>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={8}>
            <FormGroup>
              <Label>{t("Common.name") + " *"}</Label>
              <input
                className="form-control"
                type="text"
                placeholder={`${t("Common.name")} *`}
                {...register("name", { required: true })}
              />
              <span className="form-error-text">
                {errors.name &&
                  t(
                    "FormValidations.ExperimentPages.LaboratoryPages.NameRequired"
                  )}
              </span>
            </FormGroup>
          </Col>
          <Col xs={12} md={4}>
            <FormGroup>
              <Label>
                {t("ExperimentPages.ExperimentPages.experimentCode")}
              </Label>
              <input
                className="form-control"
                type="text"
                placeholder={`${t(
                  "ExperimentPages.ExperimentPages.experimentCode"
                )}`}
                {...register("code", { required: false })}
              />
              <span className="form-error-text">
                {errors.code &&
                  t(
                    "FormValidations.ExperimentPages.LaboratoryPages.CodeRequired"
                  )}
              </span>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <Label>
                {t("ExperimentPages.ExperimentPages.analysisMethods")}
              </Label>
              <Select
                mode="multiple"
                {...register("analysisMethods", { required: false })}
                style={{ width: "100%" }}
                placeholder={t("Common.PleaseSelect")}
                size="large"
                value={selectedAnalysisMethods}
                onChange={(e) => {
                  setSelectedAnalysisMethods(e);
                }}
                options={analysisMethodListData?.map((met: any) => {
                  return {
                    label:
                      met.name === ""
                        ? met.standardNo
                        : met.standardNo + " (" + met.name + ")",
                    value: met.id,
                  };
                })}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <Label>
                {t("ExperimentPages.ExperimentPages.experimentTables")}
              </Label>
              <Select
                mode="multiple"
                {...register("experimentTables", { required: false })}
                style={{ width: "100%" }}
                placeholder={t("Common.PleaseSelect")}
                size="large"
                value={selectedExperimentTables}
                onChange={(e) => {
                  setSelectedExperimentTables(e);
                }}
                options={experimentTableListData?.map((tb: any) => {
                  return {
                    label: tb.name,
                    value: tb.id,
                  };
                })}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={8}>
            <FormGroup>
              <Label>{t("Common.price")}</Label>
              <input
                className="form-control"
                type="text"
                placeholder={`${t("Common.price")}`}
                {...register("price", { required: false })}
              />
            </FormGroup>
          </Col>
          <Col xs={12} md={4}>
            <FormGroup>
              <Label>{t("Common.vat")}</Label>
              <input
                className="form-control"
                type="number"
                defaultValue={20}
                placeholder={`${t("Common.vat")}`}
                {...register("vat", { required: false })}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <Label>{t("Common.description")}</Label>
              <textarea
                className="form-control"
                cols={30}
                rows={4}
                {...register("description", { required: false })}
              ></textarea>
            </FormGroup>
          </Col>
        </Row>
        {/* Genel Ayarlar */}
        <Row>
          <Col>
            <Accordion
              open={openAccordion}
              toggle={toggleAccordion}
              className="dark-accordion"
            >
              <AccordionItem>
                <AccordionHeader
                  className="bg-light-primary font-primary"
                  targetId="1"
                >
                  <div className="font-primary">
                    {t("Common.generalSettings")}
                  </div>
                  <FeatherIconCom
                    iconName={
                      openAccordion === "1" ? "ChevronUp" : "ChevronDown"
                    }
                    className="svg-color font-primary"
                  />
                </AccordionHeader>
                <AccordionBody accordionId="1">
                  <Row>
                    <Col xs={12}>
                      <FormGroup>
                        <Label>
                          {t("ExperimentPages.ExperimentPages.Accredite") +
                            " *"}
                        </Label>
                        <select
                          className="form-control"
                          {...register("criterionType", {
                            required: true,
                          })}
                          onChange={(e) => {
                            setSelectedCriterionType(e.target.value);
                            register("criterionType").onChange(e);
                          }}
                        >
                          <option value="">{t("Common.PleaseSelect")}</option>
                          {Object.keys(criterionTypes).map((key) => (
                            <option
                              key={key}
                              value={key}
                              selected={
                                experimentDetails?.criterionType === key
                              }
                            >
                              {t(
                                `ExperimentPages.ExperimentPages.${
                                  ExperimentCriterionType[
                                    key as keyof typeof ExperimentCriterionType
                                  ]
                                }`
                              )}
                            </option>
                          ))}
                        </select>
                        <span className="form-error-text">
                          {errors.criterionType &&
                            t(
                              "FormValidations.ExperimentPages.ExperimentPages.AccrediteRequired"
                            )}
                        </span>
                      </FormGroup>
                    </Col>
                    <Col xs={12}>
                      <FormGroup>
                        <Label>
                          {t(
                            "ExperimentPages.ExperimentPages.SampleMeasurement"
                          ) + " *"}
                        </Label>
                        <select
                          className="form-control"
                          {...register("sampleAndMeasurementType", {
                            required: true,
                          })}
                          onChange={(e) => {
                            // setSelectedSampleCollectMethodType(e.target.value);
                            setSelectedSampleAndMeasurementType(e.target.value);
                            if (
                              e.target.value !==
                              SampleMeasurementType.SAMPLING_IS_CARRIED_OUT_IN_THE_FIELD
                            ) {
                              setValue("normalSampleNumber", 0);
                              setValue("numberWitnessSample", 0);
                            }
                            if (
                              e.target.value !==
                              SampleMeasurementType.MEASUREMENTS_ARE_CARRIED_OUT_IN_THE_FIELD
                            ) {
                              setValue("resultType", "");
                            }
                            register("sampleAndMeasurementType").onChange(e);
                          }}
                        >
                          <option value="">{t("Common.PleaseSelect")}</option>
                          {Object.keys(sampleMeasurementTypes).map((key) => (
                            <option
                              key={key}
                              value={key}
                              selected={
                                experimentDetails?.sampleAndMeasurementType ===
                                key
                              }
                            >
                              {t(
                                `ExperimentPages.ExperimentPages.${
                                  SampleMeasurementType[
                                    key as keyof typeof SampleMeasurementType
                                  ]
                                }`
                              )}
                            </option>
                          ))}
                        </select>
                        <span className="form-error-text">
                          {errors.sampleAndMeasurementType &&
                            t(
                              "FormValidations.ExperimentPages.ExperimentPages.SampleMeasurementRequired"
                            )}
                        </span>
                      </FormGroup>
                    </Col>
                    {selectedSampleAndMeasurementType ===
                      "SAMPLING_IS_CARRIED_OUT_IN_THE_FIELD" && (
                      <>
                        <Col xs={6} md={6}>
                          <FormGroup>
                            <Label>
                              {t(
                                "ExperimentPages.ExperimentPages.NormalSampleCount"
                              )}
                            </Label>
                            <input
                              className="form-control"
                              type="number"
                              placeholder={`${t(
                                "ExperimentPages.ExperimentPages.NormalSampleCount"
                              )}`}
                              {...register("normalSampleNumber", {
                                required: false,
                              })}
                            />
                          </FormGroup>
                        </Col>
                        <Col xs={6} md={6}>
                          <FormGroup>
                            <Label>
                              {t(
                                "ExperimentPages.ExperimentPages.WitnessSampleCount"
                              )}
                            </Label>
                            <input
                              className="form-control"
                              type="number"
                              placeholder={`${t(
                                "ExperimentPages.ExperimentPages.WitnessSampleCount"
                              )}`}
                              {...register("numberWitnessSample", {
                                required: false,
                              })}
                            />
                          </FormGroup>
                        </Col>
                      </>
                    )}
                    {selectedSampleAndMeasurementType ===
                      "MEASUREMENTS_ARE_CARRIED_OUT_IN_THE_FIELD" && (
                      <Col xs={12}>
                        <FormGroup>
                          <Label>
                            {t(
                              "ExperimentPages.ExperimentPages.MeasurementResults"
                            )}
                          </Label>
                          <select
                            className="form-control"
                            {...register("resultType", {
                              required: false,
                            })}
                            onChange={(e) => {
                              register("resultType").onChange(e);
                            }}
                          >
                            <option value="">{t("Common.PleaseSelect")}</option>
                            {Object.keys(measurementResultsTypes).map((key) => (
                              <option
                                key={key}
                                value={key}
                                selected={key === experimentDetails?.resultType}
                              >
                                {t(
                                  `ExperimentPages.ExperimentPages.${
                                    MeasurementResultsType[
                                      key as keyof typeof MeasurementResultsType
                                    ]
                                  }`
                                )}
                              </option>
                            ))}
                          </select>
                          <span className="form-error-text">
                            {errors.resultType &&
                              t(
                                "FormValidations.ExperimentPages.ExperimentPages.SampleMeasurementRequired"
                              )}
                          </span>
                        </FormGroup>
                      </Col>
                    )}
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>
                          {t(
                            "ExperimentPages.ExperimentPages.responsibleCompanies"
                          )}{" "}
                          <span>
                            (
                            {t(
                              "ExperimentPages.ExperimentPages.responsibleCompaniesDesc"
                            )}
                            )
                          </span>
                        </Label>
                        <Select
                          mode="multiple"
                          {...register("subcontractorCompanyIds", {
                            required: false,
                          })}
                          style={{ width: "100%" }}
                          placeholder={t("Common.PleaseSelect")}
                          size="large"
                          value={selectedCompanies}
                          onChange={(e) => {
                            setSelectedCompanies(e);
                          }}
                          options={partnerCompanyListData?.map((comp: any) => {
                            return {
                              label: comp.name,
                              value: comp.id,
                            };
                          })}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup className="d-flex align-items-center h-100">
                        <div className="me-3 flex align-items-center">
                          <Switch
                            checked={isOnlyOffer}
                            onChange={(e: any) => {
                              setIsOnlyOffer(e);
                            }}
                          />
                        </div>
                        <Label className="mb-0">
                          {t(
                            "ExperimentPages.ExperimentPages.willNotBeTransferred"
                          )}
                        </Label>
                      </FormGroup>
                    </Col>
                  </Row>
                </AccordionBody>
              </AccordionItem>
            </Accordion>
          </Col>
        </Row>
        {/* Atik ve Su Ayarlari */}
        {selectedLab === "WASTE_WATER" && (
          <Row className="mt-3">
            <Col>
              <Accordion
                open={openAccordion3}
                toggle={toggleAccordion3}
                className="dark-accordion"
              >
                <AccordionItem>
                  <AccordionHeader
                    className="bg-light-primary font-primary"
                    targetId="3"
                  >
                    <div className="font-primary">
                      {t(
                        "ExperimentPages.ExperimentPages.experimentWastewaterSettings"
                      )}
                    </div>
                    <FeatherIconCom
                      iconName={
                        openAccordion3 === "3" ? "ChevronUp" : "ChevronDown"
                      }
                      className="svg-color font-primary"
                    />
                  </AccordionHeader>
                  <AccordionBody accordionId="3">
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label>
                            {t(
                              "ExperimentPages.ExperimentPages.availableUnitTypes"
                            )}{" "}
                            <span>
                              (
                              {t(
                                "ExperimentPages.ExperimentPages.availableUnitTypesDesc"
                              )}
                              )
                            </span>
                          </Label>
                          <Select
                            mode="multiple"
                            {...register("unitTypes", {
                              required: false,
                            })}
                            style={{ width: "100%" }}
                            placeholder={t("Common.PleaseSelect")}
                            size="large"
                            value={selectedUnitTypes}
                            onChange={(e) => {
                              setSelectedUnitTypes(e);
                            }}
                            options={experimentUnitTypes?.map((ut: any) => {
                              return {
                                label: t(
                                  `ExperimentPages.ExperimentPages.${ut.displayName}type`
                                ),
                                value: ut.id,
                              };
                            })}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label>
                            {t(
                              "ExperimentPages.ExperimentPages.receivingMethods"
                            )}
                          </Label>
                          <Select
                            mode="multiple"
                            {...register("sampleCollectMethodType", {
                              required: false,
                            })}
                            style={{ width: "100%" }}
                            placeholder={t("Common.PleaseSelect")}
                            size="large"
                            value={selectedSampleCollectMethodType}
                            onChange={(e) => {
                              setSelectedSampleCollectMethodType(e);
                            }}
                            options={Object.keys(sampleCollectMethods).map(
                              (key) => {
                                return {
                                  label: t(
                                    `ExperimentPages.ExperimentPages.${
                                      SampleCollectMethodType[
                                        key as keyof typeof SampleCollectMethodType
                                      ]
                                    }`
                                  ),
                                  value: key,
                                };
                              }
                            )}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label>
                            {t(
                              "ExperimentPages.ExperimentPages.storageContainers"
                            )}{" "}
                            <span>
                              (
                              {t(
                                "ExperimentPages.ExperimentPages.storageContainersDesc"
                              )}
                              )
                            </span>
                          </Label>
                          <Select
                            mode="multiple"
                            {...register("storageContainerType", {
                              required: false,
                            })}
                            style={{ width: "100%" }}
                            placeholder={t("Common.PleaseSelect")}
                            size="large"
                            value={selectedStorageContainerType}
                            onChange={(e) => {
                              setSelectedStorageContainerType(e);
                            }}
                            options={Object.keys(storageContainerTypes).map(
                              (key) => {
                                return {
                                  label: t(
                                    `ExperimentPages.ExperimentPages.${
                                      StorageContainerType[
                                        key as keyof typeof StorageContainerType
                                      ]
                                    }`
                                  ),
                                  value: key,
                                };
                              }
                            )}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label>
                            {t(
                              "ExperimentPages.ExperimentPages.storageConditions"
                            )}{" "}
                            <span>
                              (
                              {t(
                                "ExperimentPages.ExperimentPages.storageContainersDesc"
                              )}
                              )
                            </span>
                          </Label>
                          <Select
                            mode="multiple"
                            {...register("storageConditionType", {
                              required: false,
                            })}
                            style={{ width: "100%" }}
                            placeholder={t("Common.PleaseSelect")}
                            size="large"
                            value={selectedStorageConditionType}
                            onChange={(e) => {
                              setSelectedStorageConditionType(e);
                            }}
                            options={Object.keys(storageConditionTypes).map(
                              (key) => {
                                return {
                                  label: t(
                                    `ExperimentPages.ExperimentPages.${
                                      StorageConditionType[
                                        key as keyof typeof StorageConditionType
                                      ]
                                    }`
                                  ),
                                  value: key,
                                };
                              }
                            )}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={7} md={9}>
                        <FormGroup>
                          <Label>
                            {t(
                              "ExperimentPages.ExperimentPages.requiredQuantity"
                            )}
                          </Label>
                          <input
                            className="form-control"
                            type="number"
                            placeholder={`${t(
                              "ExperimentPages.ExperimentPages.requiredQuantity"
                            )}`}
                            {...register("requiredAmount", {
                              required: false,
                            })}
                          />
                        </FormGroup>
                      </Col>
                      <Col xs={5} md={3}>
                        <FormGroup>
                          <Label>
                            {t(
                              "ExperimentPages.ExperimentPages.requiredQuantityUnitType"
                            )}
                          </Label>
                          <select
                            className="form-control"
                            {...register("requiredAmountUnit", {
                              required: false,
                            })}
                          >
                            <option value="">{t("Common.PleaseSelect")}</option>
                            <option value={"GRAM"}>{"Gram"}</option>
                            <option value={"MILLIGRAM"}>{"Miligram"}</option>
                            <option value={"MILLILITER"}>{"Mililitre"}</option>
                          </select>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={6}>
                        <FormGroup>
                          <Label>
                            {t("ExperimentPages.ExperimentPages.storageTime")}
                          </Label>
                          <input
                            className="form-control"
                            type="number"
                            placeholder={`${t(
                              "ExperimentPages.ExperimentPages.storageTime"
                            )}`}
                            {...register("storageTime", {
                              required: false,
                            })}
                          />
                        </FormGroup>
                      </Col>
                      <Col xs={6}>
                        <FormGroup>
                          <Label>
                            {t("ExperimentPages.ExperimentPages.uncertainty")}
                          </Label>
                          <input
                            className="form-control"
                            type="number"
                            placeholder={`${t(
                              "ExperimentPages.ExperimentPages.uncertainty"
                            )}`}
                            {...register("uncertaintyPercentage", {
                              required: false,
                            })}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} md={6}>
                        <FormGroup className="d-flex align-items-center h-100">
                          <div className="me-3 flex align-items-center">
                            <Switch
                              checked={storedInSeparateCup}
                              onChange={(e: any) => {
                                setStoredInSeparateCup(e);
                              }}
                            />
                          </div>
                          <Label className="mb-0">
                            {t(
                              "ExperimentPages.ExperimentPages.keepParameterAlone"
                            )}
                          </Label>
                        </FormGroup>
                      </Col>
                      <Col xs={12} md={6}>
                        <FormGroup className="d-flex align-items-center h-100">
                          <div className="me-3 flex align-items-center">
                            <Switch
                              checked={notUsedInReport}
                              onChange={(e: any) => {
                                setNotUsedInReport(e);
                              }}
                            />
                          </div>
                          <Label className="mb-0">
                            {t(
                              "ExperimentPages.ExperimentPages.notUsedInReport"
                            )}
                          </Label>
                        </FormGroup>
                      </Col>
                    </Row>
                  </AccordionBody>
                </AccordionItem>
              </Accordion>
            </Col>
          </Row>
        )}
        {/* Emisyon Ayarlari */}
        {selectedLab === "EMISSION" && (
          <Row className="mt-3">
            <Col>
              <Accordion
                open={openAccordion2}
                toggle={toggleAccordion2}
                className="dark-accordion"
              >
                <AccordionItem>
                  <AccordionHeader
                    className="bg-light-primary font-primary"
                    targetId="2"
                  >
                    <div className="font-primary">
                      {t(
                        "ExperimentPages.ExperimentPages.experimentEmissionSettings"
                      )}
                    </div>
                    <FeatherIconCom
                      iconName={
                        openAccordion2 === "2" ? "ChevronUp" : "ChevronDown"
                      }
                      className="svg-color font-primary"
                    />
                  </AccordionHeader>
                  <AccordionBody accordionId="2">
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label>
                            {t("ExperimentPages.ExperimentPages.unitTypes")}
                          </Label>
                          <Select
                            mode="multiple"
                            {...register("includedParameters", {
                              required: false,
                            })}
                            style={{ width: "100%" }}
                            placeholder={t("Common.PleaseSelect")}
                            size="large"
                            value={selectedIncludedParameters}
                            onChange={(e) => {
                              setSelectedIncludedParameters(e);
                            }}
                            options={experimentUnitTypes?.map((ut: any) => {
                              return {
                                label: t(
                                  `ExperimentPages.ExperimentPages.${ut.code}type`
                                ),
                                value: ut.id,
                              };
                            })}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label>{t("Common.description")}</Label>
                          <input
                            className="form-control"
                            placeholder={`${t("Common.description")}`}
                            {...register("scope", {
                              required: false,
                            })}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </AccordionBody>
                </AccordionItem>
              </Accordion>
            </Col>
          </Row>
        )}
        <Row className="mt-3">
          <Col>
            <div className="text-end">
              <Btn color="success" className="me-3">
                {t("Common.update")}
              </Btn>
              <Link
                className="btn btn-danger"
                to={`${process.env.PUBLIC_URL}/experiments/experiment/list`}
              >
                {t("Common.cancel")}
              </Link>
            </div>
          </Col>
        </Row>
      </Form>
    );
  };

  const tabItems = [
    {
      label: t("ExperimentPages.ExperimentPages.ExperimentInformations"),
      key: "1",
      children: returnExperimentUpdateFormContent(),
    },
    // {
    //   label: t("ExperimentPages.ExperimentPages.ParameterLimitSettings"),
    //   key: "2",
    //   children: (
    //     <ParameterLimitSettings
    //       experimentId={experimentDetails?.id}
    //       experimentMethods={experimentDetails?.analysisMethods}
    //     />
    //   ),
    // },
  ];

  return (
    <div className="page-body">
      <Breadcrumbs
        pageTitle={t("ExperimentPages.ExperimentPages.UpdateExperiment")}
        parent={t("ExperimentPages.LaboratoryPages.Metriks")}
        subParent={t("ExperimentPages.ExperimentPages.ExperimentPageTitle")}
        subParentLink={`${process.env.PUBLIC_URL}/experiments/experiment/list`}
        title={t("ExperimentPages.ExperimentPages.UpdateExperiment")}
      />
      <Container fluid>
        <Row>
          <Col sm={12}>
            <Card>
              <CardBody>
                <Tabs
                  defaultActiveKey="1"
                  type="card"
                  size={"middle"}
                  style={{ marginBottom: 32 }}
                  items={tabItems}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
