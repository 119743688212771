export enum ProductStates {
  IDLE = 'IdleState',
  RETURN = 'ReturnState',
  TRANSFER = 'TransferState',
  ORDER_PLACED = 'OrderPlacedState',
  CALIBRATION = 'CalibrationState',
  MAINTENANCE = 'MaintenanceState',
  EXTERMINATION = 'ExterminationState',
  OUT_OF_STOCK = 'OutOfStockState',
  IN_USE = 'InUseState'
}