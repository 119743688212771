// userSlice.ts
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { User } from "Types/User/UserType";

const initialState: User = {
  firstName: "",
  email: "",
  id: "",
  userId: "",
  identityNumber: "",
  lastName: "",
  birthdate: "",
  phoneNumber: "",
  contact: "",
  address: "",
  genderType: "",
  bloodType: "",
  picUrl: "",
  registrationNumber: "",
  supervisor: "",
  organizationChain: "",
  jobStartDate: "",
  mealCardType: "",
  employmentType: "",
  workingType: "",
  contractType: "",
  contractStartDate: "",
  contractEndDate: "",
  timesheetRequired: false,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setName: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
    setUserDetailsOwn: (state, action: PayloadAction<User>) => {
      return { ...state, ...action.payload };
    },
    setUserId: (state, action: PayloadAction<string>) => {
      state.id = action.payload;
    },
  },
});

export const { setName, setUserDetailsOwn, setUserId } = userSlice.actions;
export default userSlice.reducer;
