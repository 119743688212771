import * as Paths from "./../files/Paths";
import * as Headers from "./../files/Headers";
import request from "./../files/Request";

// Types
import { ListFilterType } from "Types/Common/ListFilterType";
import { Category } from "Types/Education/Category";

export const getCategories = async (filter: ListFilterType, filterObj: any) => {
  const filterParams = {
    name: filterObj?.name
  };
  return request.get(
    `${Paths.education}/categories/all`,
    {
      page: filter.page,
      size: filter.size,
      ...(filter?.sort && {sort: filter?.sort[0]}),
      ...filterParams
    },
    { headers: Headers.headers }
  );
};

export const getCategoriesAll = async () => {
  return request.get(
    `${Paths.education}/categories/all`, {},
    { headers: Headers.headers }
  );
};

export const getCategory = async (id: any) => {
  return request.get(
    `${Paths.education}/categories/${id}`, {},
    { headers: Headers.headers }
  );
};

export const deleteCategory = async (id: string) => {
  return request.delete(
    `${Paths.education}/categories/${id}`, {},
    {
      headers: Headers.headers,
    }
  );
};

export const addCategory = async (category: Category) => {
  return request.post(
    `${Paths.education}/categories`, category, {},
    {
      headers: Headers.headers,
    }
  );
};

export const updateCategory = async (category: Category) => {
  return request.put(
    `${Paths.education}/categories`, category,
    {
      headers: Headers.headers,
    }
  );
};