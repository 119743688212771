import axios, { AxiosHeaders } from 'axios';
import { stateError } from './Errors';
// const axios = require('axios').default;

const baseURL = process.env.REACT_APP_API_BASE_URL;

axios.defaults.baseURL = baseURL;
axios.defaults.withCredentials = true;

axios.interceptors.request.use(function (config: { headers: AxiosHeaders }) {
  config.headers['Authorization'] = `Bearer ${JSON.parse(JSON.stringify(localStorage.getItem("prtlb-token"))) || ''}`;
  config.headers['Access-Control-Allow-Origin'] = '*/*';
  config.headers['Application-Type'] = 'WEB';
  config.headers['Accept'] = 'application/json';
  return config;
});

// TO DO: interceptor for refresh_token
// axios.interceptors.response.use(
//   response => response,
//   async error => {
//     // const originalRequest = error.config;
//     if (error.response.status === 401) {
//       // originalRequest._retry = true;
//       const newAccessToken = await refreshToken();
//       if (newAccessToken) {
//         axios.defaults.headers.common['Authorization'] = `Bearer ${newAccessToken}`;
//         // return api(originalRequest);
//       }
//     }
//     return Promise.reject(error);
//   }
// );

class request {
  static async get(url = '', params = {}, headers = {}, responseType: any = '') {
    try {
      return await axios.get(url, { params, headers, responseType });
    } catch (error) {
      return stateError(error);
    }
  }

  static async post(url = '', body = {}, params = {}, headers = {}, responseType: any= '') {
    try {
      return await axios.post(url, body, { params, headers, responseType });
    } catch (error: any) {
      // return stateError(error);
      return error;
    }
  }
  
  static async put(url = '', body = {}, headers = {}) {
    if (Object.keys(body).length !== 0) {
      // let params = this.setParams({ params: body });
     // url = url + '?' + params;
    }

    try {
      return await axios.put(url, body, { headers });
    } catch (error) {
      return stateError(error);
    }
  }

  static async delete(url = '', data = {}, headers = {}) {
    try {
      return await axios.delete(url, { data, headers });
    } catch (error) {
      return stateError(error);
    }
  }

  static setParams({ params }: { params: {} }) {
    return Object.entries(params)
      .map((e) => e.join('='))
      .join('&');
  }
}
export default request;