import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import _ from "lodash";

import { Option } from "Types/FormType";

export default function FilterSelectMulti({
  inputName,
  setFilterObjFun,
  options,
  scrollToBottomFunc,
  loading,
  clearSignal,
}: any) {
  const { t } = useTranslation();
  const [selectedOption, setSelectedOption] = useState<null | string>(null);
  const multiSelectRef = useRef<any>(null);
  const [page, setPage] = useState(0);
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    if (clearSignal) {
      setSelectedOption(null);
      multiSelectRef?.current?.clearValue();
    }
  }, [clearSignal]);

  const handleChangeMulti = (newValue: any, actionMeta: any) => {
    setSelectedOption(newValue);
    if (
      actionMeta.action === "select-option" ||
      actionMeta.action === "clear" ||
      clearSignal
    ) {
      const newSelectedOption = newValue as Option;
      setSelectedOption(newSelectedOption?.value || null);
    }

    if (newValue.length > 0) {
      setFilterObjFun((prevData: any) => ({
        ...prevData,
        [actionMeta.name]: _.map(newValue, "value"),
      }));
    } else {
      setFilterObjFun((prevData: any) => {
        const newData: any = { ...prevData };
        delete newData[actionMeta.name];
        return newData;
      });
    }
  };

  const onMenuScrollToBottom = () => {
    const newPage = page + 1;
    setPage(newPage);
    scrollToBottomFunc(newPage, searchText);
  };

  const onChangeSearch = (searcValue?: any) => {
    setSearchText(searcValue);
    setPage(0);
    scrollToBottomFunc(0, searcValue);
  };

  useEffect(() => {
    //
  }, [searchText]);

  return (
    <>
      <Select
        ref={multiSelectRef}
        name={inputName}
        isMulti={true}
        isClearable
        options={options}
        placeholder={t("Common.Select")}
        isLoading={loading}
        value={options.find((option: any) => option.value === selectedOption)}
        onChange={handleChangeMulti}
        onMenuScrollToBottom={onMenuScrollToBottom}
        onInputChange={onChangeSearch}
      />
    </>
  );
}
