import React from 'react';
import { Col } from 'reactstrap'
import { UL } from '../../../AbstractElements'
import SearchContainer from './SearchContainer';
import Notification from './Notification';
import MoonLight from './MoonLight';
import ProfileHeader from './ProfileHeader';
import BookmarkHeader from './BookmarkHeader';
import MaximizeScreen from './MaximizeScreen';
import LanguageSelect from './LanguageSelect';

export default function RightHeader() {

  return (
    <Col xxl={7} xl={8} xs='auto' className="nav-right box-col-6 pull-right right-header p-0 ms-auto">
      <UL className="nav-menus simple-list flex-row">
        {/* <SearchContainer /> */}
        <MaximizeScreen />
        {/* <Notification /> */}
        {/* <LanguageSelect /> */}
        {/* <BookmarkHeader /> */}
        <MoonLight />
        <ProfileHeader />
      </UL>
    </Col>
  )
}
