import React, { useEffect, useState } from "react";
import Breadcrumbs from "CommonElements/Breadcrumbs";
import {
  Card,
  CardBody,
  Col,
  Collapse,
  Container,
  Label,
  Row,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import DataTable, { TableColumn } from "react-data-table-component";
import { Href, defaultPageAndSize } from "Utils/Constants";
import { Link } from "react-router-dom";
import { Filter } from "react-feather";
import { P, UL, LI, Btn } from "AbstractElements";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import { Spin, Tooltip } from "antd";
import FilterInput from "Components/Filters/FilterInput";
import FilterSelect from "Components/Filters/FilterSelect";

// Services
import * as deviceService from "Services/DebitServices/Device";

// Types
import { ListFilterType } from "Types/Common/ListFilterType";
import { DeviceType } from "Types/Debit/Device/DeviceType";

export default function Device() {
  const { t } = useTranslation();

  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);
  const handleFilterToggle = () => {
    setIsFilterOpen((prevState) => !prevState);
  };
  const [deviceListData, setDeviceListData] = useState([] as DeviceType[]);

  const [deviceListFilter, setDeviceListFilter] = useState(
    defaultPageAndSize as ListFilterType
  );
  const [totalRows, setTotalRows] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);

  const tableListColumns: TableColumn<any>[] = [
    {
      name: t("Common.brand"),
      sortField: "brand.name",
      selector: (row) => row.brand.name,
      sortable: true,
      cell: (row) => <P className="f-light-darker">{row.brand.name}</P>,
    },
    {
      name: t("Common.name"),
      sortField: "name",
      selector: (row) => row.name,
      sortable: true,
      cell: (row) => <P className="f-light-darker">{row.name}</P>,
    },
    {
      name: t("Common.serialNumber"),
      sortField: "serialNumber",
      selector: (row) => row.serialNumber,
      sortable: true,
      cell: (row) => <P className="f-light-darker">{row.serialNumber}</P>,
    },
    {
      name: t("Common.shouldCalibrate"),
      sortField: "shouldCalibrate",
      selector: (row) => row.shouldCalibrate,
      sortable: true,
      cell: (row) => (
        <P className="f-light-darker text-center w-100">
          {row.shouldCalibrate ? (
            <i className="fa fa-check-circle-o text-success fs-5"></i>
          ) : (
            <i className="fa fa-times-circle-o text-danger fs-5"></i>
          )}
        </P>
      ),
    },
    {
      name: t("Common.shouldIntermediateCalibration"),
      sortField: "shouldIntermediateCalibration",
      selector: (row) => row.shouldIntermediateCalibration,
      sortable: true,
      cell: (row) => (
        <P className="f-light-darker text-center w-100">
          {row.shouldIntermediateCalibration ? (
            <i className="fa fa-check-circle-o text-success fs-5"></i>
          ) : (
            <i className="fa fa-times-circle-o text-danger fs-5"></i>
          )}
        </P>
      ),
    },
    {
      name: t("Common.createdDate"),
      sortField: "createdDate",
      width: "200px",
      selector: (row) => row.createdDate,
      sortable: true,
      cell: (row) => (
        <P className="f-light-darker">
          {dayjs(row.createdDate).format("DD-MM-YYYY HH:mm")}
        </P>
      ),
    },
    {
      name: t("Common.Actions"),
      width: "80px",
      cell: (row) => (
        <>
          <UL className="action simple-list flex-row">
            <LI className="edit">
              <Tooltip placement="bottom" title={t("Common.Edit")}>
                <Link
                  to={`${process.env.PUBLIC_URL}/debit/device/edit/${row.id}`}
                >
                  <i className="icon-pencil-alt" />
                </Link>
              </Tooltip>
            </LI>
            <LI className="delete">
              <Tooltip placement="bottom" title={t("Common.Delete")}>
                <Link onClick={() => deleteDevice(row.id)} to={""}>
                  <i className="icon-trash" />
                </Link>
              </Tooltip>
            </LI>
          </UL>
        </>
      ),
      sortable: false,
    },
  ];

  const [loadingTable, setLoadingTable] = useState(false);
  const [filterObj, setFilterObj] = useState({});
  const [clearSignal, setClearSignal] = useState(false);

  const getDevices = async (deviceListFilter: ListFilterType) => {
    setLoadingTable(true);
    const { data, error }: any = await deviceService.getDevices(
      deviceListFilter,
      filterObj
    );
    setLoadingTable(false);
    setDeviceListData(data?.body?.content);
    setTotalRows(data?.body?.totalElements);
    setTotalPages(data?.body?.totalPages);
    if (error) {
      setLoadingTable(false);
      toast.error(t(`ERRORS.${error?.errorCode}`));
    }
  };

  const handlePageChange = (page: number) => {
    setDeviceListFilter({
      ...deviceListFilter,
      page: page - 1,
    });
  };

  const handleRowsPerPageChange = (newRowsPerPage: number) => {
    if (!deviceListData.length) return;
    setDeviceListFilter({
      ...deviceListFilter,
      size: newRowsPerPage,
    });
  };

  // table sorting
  const handleSortColumn = (column: any, direction: string) => {
    getDevices({
      ...deviceListFilter,
      sort: [`${column.sortField + "," + direction.toUpperCase()}`],
    });
  };

  const deleteDevice = async (devicedId: string) => {
    const { error }: any = await deviceService.deleteDevice(devicedId);
    toast.success(t("COMMON_MESSAGES.deletedSuccessfully"));
    getDevices(deviceListFilter);
    if (error) toast.error(t(`ERRORS.${error?.errorCode}`));
  };

  useEffect(() => {
    getDevices(deviceListFilter);
  }, [deviceListFilter]);

  // Flter functions
  const searchFunc = (filterObj: any) => {
    setFilterObj({
      ...filterObj,
    });
    getDevices(deviceListFilter);
  };

  const clearFilter = () => {
    setClearSignal(true);
    setFilterObj({});
  };

  useEffect(() => {
    if (clearSignal) {
      getDevices(deviceListFilter);
      setTimeout(() => setClearSignal(false), 0);
    }
  }, [clearSignal, filterObj]);

  return (
    <div className="page-body">
      <Breadcrumbs
        pageTitle={t("Menu.Device")}
        parent={t("DebitPages.Debit")}
        title={t("DebitPages.DevicePageTitle")}
      />
      <Container fluid>
        <Row>
          <Col sm={12}>
            <Card>
              <CardBody>
                <div className="list-product-header">
                  <div>
                    <a href={Href} onClick={handleFilterToggle}>
                      <div className="light-box">
                        {isFilterOpen ? (
                          <i className="icon-close filter-close" />
                        ) : (
                          <Filter />
                        )}
                      </div>
                    </a>
                    <Link
                      className="btn btn-primary"
                      to={`${process.env.PUBLIC_URL}/debit/device/add`}
                    >
                      <i className="fa fa-plus me-2"></i>{" "}
                      {t("DebitPages.AddDevice")}
                    </Link>
                  </div>
                  <Collapse className={isFilterOpen ? "show" : ""}>
                    <Card className="list-product-body">
                      <CardBody>
                        <Row className="row-cols-xl-5 row-cols-lg-4 row-cols-md-4 row-cols-sm-2 row-cols-2 g-3">
                          <Col>
                            <Label className="mb-2">{t("Common.brand")}</Label>
                            <FilterInput
                              inputType={"text"}
                              inputName={"brand"}
                              setFilterObjFun={setFilterObj}
                              clearSignal={clearSignal}
                            />
                          </Col>
                          <Col>
                            <Label className="mb-2">{t("Common.name")}</Label>
                            <FilterInput
                              inputType={"text"}
                              inputName={"name"}
                              setFilterObjFun={setFilterObj}
                              clearSignal={clearSignal}
                            />
                          </Col>
                          <Col>
                            <Label className="mb-2">
                              {t("Common.serialNumber")}
                            </Label>
                            <FilterInput
                              inputType={"number"}
                              inputName={"serialNumber"}
                              setFilterObjFun={setFilterObj}
                              clearSignal={clearSignal}
                            />
                          </Col>
                          <Col>
                            <Label className="mb-2">
                              {t("Common.shouldCalibrate")}
                            </Label>
                            <FilterSelect
                              inputName={"calibration"}
                              setFilterObjFun={setFilterObj}
                              isStatus={true}
                              options={[
                                { text: t("Common.Active"), value: "true" },
                                { text: t("Common.Passive"), value: "false" },
                              ]}
                              clearSignal={clearSignal}
                            />
                          </Col>
                          <Col>
                            <Label className="mb-2">
                              {t("Common.shouldIntermediateCalibration")}
                            </Label>
                            <FilterSelect
                              inputName={"shouldIntermediateCalibration"}
                              setFilterObjFun={setFilterObj}
                              isStatus={true}
                              options={[
                                { text: t("Common.Active"), value: "true" },
                                { text: t("Common.Passive"), value: "false" },
                              ]}
                              clearSignal={clearSignal}
                            />
                          </Col>
                        </Row>
                        <Row className="my-2">
                          <Col className="d-flex justify-content-end">
                            <Btn
                              color="default"
                              className="me-3"
                              size="small"
                              onClick={() => clearFilter()}
                            >
                              {t("Common.clearAllFilters")}
                            </Btn>
                            <Btn
                              color="primary"
                              className="me-3"
                              size="small"
                              onClick={() => searchFunc(filterObj)}
                            >
                              <i className="icofont icofont-ui-search"></i>
                            </Btn>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Collapse>
                </div>
                <div className="list-product">
                  <DataTable
                    data={deviceListData ? deviceListData : []}
                    columns={tableListColumns}
                    progressComponent={<Spin />}
                    progressPending={loadingTable}
                    pagination
                    paginationServer
                    paginationTotalRows={totalRows}
                    paginationPerPage={deviceListFilter.size}
                    onChangePage={handlePageChange}
                    onChangeRowsPerPage={handleRowsPerPageChange}
                    onSort={handleSortColumn}
                    paginationComponentOptions={{
                      rowsPerPageText: t("Common.rowsPerPage"),
                      rangeSeparatorText: t("Common.rangeSeparatorText"),
                    }}
                    noDataComponent={<>{t("Common.noData")}</>}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
