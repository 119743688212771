import React, { useEffect, useState } from "react";
import Breadcrumbs from "CommonElements/Breadcrumbs";
import { Card, CardBody, Col, Container, Input, Label, Row } from "reactstrap";
import { Form as AntForm } from "antd";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Btn } from "AbstractElements";
import { toast } from "react-toastify";
import { SubmitHandler, useForm } from "react-hook-form";
import { InputMask } from "@react-input/mask";

// Services
import * as companyService from "Services/CrmServices/Company";
import * as countryCityDistrictService from "Services/CrmServices/CountryCityDistrict";

// Types
import { CompanyType } from "Enums/Crm/CompanyType";
import { CountryCityDistrict } from "Types/Crm/CountryCityDistrict";

// Components
import CompanyContacts from "Pages/Crm/Contact/Components/CompanyContacts";
import CompanyBanks from "Pages/Crm/Company/Components/CompanyBanks";

export default function UpdateCompany() {
  const { t } = useTranslation();
  const [form] = AntForm.useForm();

  const companyTypes = CompanyType;

  const [savedData, setSavedData] = useState<any>({});
  const { id } = useParams<{ id: string | undefined }>();

  const [countries, setCountries] = useState([] as CountryCityDistrict[]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [cities, setCities] = useState([] as CountryCityDistrict[]);
  const [selectedCity, setSelectedCity] = useState("");
  const [districts, setDistricts] = useState([] as CountryCityDistrict[]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<any>();

  const getCompany = async (id: any) => {
    const { data, error }: any = await companyService.getCompany(id);
    setSavedData(data?.body);
    if (error) toast.error(t(`ERRORS.${error?.errorCode}`));
    form.setFieldsValue(data?.body);

    getCountries();
  };

  const updateCompany: SubmitHandler<any> = async (formData) => {
    const updatedData = {
      ...formData,
      id: id
    };
    const { data, error }: any = await companyService.updateCompany(updatedData);
    if (error) toast.error(t(`ERRORS.${error?.errorCode}`))
    else {
      toast.success(t("COMMON_MESSAGES.addedSuccessfully"));
      setSavedData(data?.body);
    }
  };

  const getCountries = async () => {
    const { data, error }: any = await countryCityDistrictService.getCountries();
    setCountries(data?.body);

    // get selected country and then get this country cities
    const selectedCo = data?.body?.find((country: any) => country.name === savedData?.country);
    setSelectedCountry(selectedCo?.id as any);
    selectedCo?.id && getCities(selectedCo?.id);

    if (error) {
      toast.error(t(`ERRORS.${error?.errorCode}`));
    }
  };

  const getCities = async (countryId: string) => {
    const { data, error }: any = await countryCityDistrictService.getCities(countryId);
    setCities(data?.body);

    // get selected city and then get this city districts
    const selectedCi = data?.body?.find((city: any) => city.name === savedData?.city);
    setSelectedCity(selectedCi?.id as any);
    selectedCi?.id && getDistricts(selectedCi?.id);

    if (error) {
      toast.error(t(`ERRORS.${error?.errorCode}`));
    }
  };

  const getDistricts = async (cityId: string) => {
    const { data, error }: any = await countryCityDistrictService.getDistricts(cityId);
    setDistricts(data?.body);
    if (error) {
      toast.error(t(`ERRORS.${error?.errorCode}`));
    }
  };

  useEffect(() => {
    getCompany(id);
  }, [id, selectedCountry]);

  return (
    <div className="page-body">
      <Breadcrumbs
        pageTitle={t("CrmPages.CompanyPageTitle")}
        parent={t("CrmPages.Crm")}
        subParent={t("CrmPages.CompanyPageTitle")}
        subParentLink={`${process.env.PUBLIC_URL}/crm/company/list`}
        title={t("CrmPages.AddCompany")}
      />
      <Container fluid className="add-contact-form">
        <Row>
          <Col sm={12}>
            <Card>
              <CardBody>
                <AntForm
                  className="theme-form"
                  onFinish={updateCompany}
                  autoComplete="off"
                  form={form}
                >
                  <Row>
                    <Col>
                      <Label>{t("CrmPages.CompanyName") + " *"}</Label>
                      <AntForm.Item
                        name="name"
                        validateTrigger={["onChange", "onBlur"]}
                        rules={[
                          {
                            required: true,
                            whitespace: true,
                            message: t(
                              "FormValidations.CrmPages.CompanyNameRequired"
                            ),
                          },
                        ]}
                      >
                        <Input placeholder={`${t("CrmPages.CompanyName")}`} />
                      </AntForm.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Label>{t("CrmPages.CompanyType") + " *"}</Label>
                      <AntForm.Item
                        name="companyType"
                        validateTrigger={["onChange", "onBlur"]}
                        rules={[
                          {
                            required: true,
                            whitespace: true,
                            message: t(
                              "FormValidations.CrmPages.CompanyTypeRequired"
                            ),
                          },
                        ]}
                      >
                        <select className="form-control">
                          <option value="">{t("Common.PleaseSelect")}</option>
                          {Object.keys(companyTypes).map((key) => (
                            <option key={key} value={key}>
                              {t(
                                `Common.${
                                  CompanyType[key as keyof typeof CompanyType]
                                }`
                              )}
                            </option>
                          ))}
                        </select>
                      </AntForm.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={3}>
                      <Label>{t("Common.country") + " *"}</Label>
                      <AntForm.Item
                        name="country"
                        validateTrigger={["onChange", "onBlur"]}
                        rules={[
                          {
                            required: true,
                            whitespace: true,
                            message: t(
                              "FormValidations.CrmPages.CountryRequired"
                            ),
                          },
                        ]}
                      >
                        <select
                          className="form-control"
                          onChange={(e) => {
                            const selectedOption = e.target.options[e.target.selectedIndex];
                            const selectedId = selectedOption?.getAttribute('data-id');
                            setSelectedCountry(selectedId as any);
                            getCities(selectedId as any);
                          }}
                        >
                          <option value="">{t("Common.PleaseSelect")}</option>
                          {countries?.map((c) => (
                            <option key={c.id} value={c.name} data-id={c.id}>
                              {c.name}
                            </option>
                          ))}
                        </select>
                      </AntForm.Item>
                    </Col>
                    <Col md={3}>
                      <Label>{t("Common.city") + " *"}</Label>
                      <AntForm.Item
                        name="city"
                        validateTrigger={["onChange", "onBlur"]}
                        rules={[
                          {
                            required: true,
                            whitespace: true,
                            message: t(
                              "FormValidations.CrmPages.CityRequired"
                            ),
                          },
                        ]}
                      >
                        <select
                          className="form-control"
                          onChange={(e) => {
                            const selectedOption = e.target.options[e.target.selectedIndex];
                            const selectedId = selectedOption?.getAttribute('data-id');
                            // const splittedVal = e.target.value.split("_")[0];
                            setSelectedCity(selectedId as any);
                            getDistricts(selectedId as any);
                          }}
                        >
                          {/* <option value="">{t("Common.PleaseSelect")}</option> */}
                          {cities?.map((c) => (
                            <option key={c.id} value={c.name} data-id={c.id}>
                              {c.name}
                            </option>
                          ))}
                        </select>
                      </AntForm.Item>
                    </Col>
                    <Col md={3}>
                      <Label>{t("Common.district") + " *"}</Label>
                      <AntForm.Item
                        name="district"
                        validateTrigger={["onChange", "onBlur"]}
                        rules={[
                          {
                            required: true,
                            whitespace: true,
                            message: t(
                              "FormValidations.CrmPages.DistrictRequired"
                            ),
                          },
                        ]}
                      >
                        <select
                          className="form-control"
                        >
                          {/* <option value="">{t("Common.PleaseSelect")}</option> */}
                          {districts?.map((d) => (
                            <option key={d.id} value={d.name} data-id={d.id}>
                              {d.name}
                            </option>
                          ))}
                        </select>
                      </AntForm.Item>
                    </Col>
                    <Col md={3}>
                      <Label>{t("Common.postalCode")}</Label>
                      <AntForm.Item
                        name="postCode"
                        validateTrigger={["onChange", "onBlur"]}
                      >
                        <Input placeholder={`${t("Common.postalCode")}`} />
                      </AntForm.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Label>{t("Common.Address") + " *"}</Label>
                      <AntForm.Item
                        name="address"
                        validateTrigger={["onChange", "onBlur"]}
                        rules={[
                          {
                            required: true,
                            message: t(
                              "FormValidations.CrmPages.AddressRequired"
                            ),
                          },
                        ]}
                      >
                        <Input placeholder={`${t("Common.Address")}`} />
                      </AntForm.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <Label>{t("Common.Phone") + " *"}</Label>
                      <AntForm.Item
                        name="phone"
                        validateTrigger={["onChange", "onBlur"]}
                        rules={[
                          {
                            required: true,
                            message: t(
                              "FormValidations.CrmPages.PhoneRequired"
                            ),
                          },
                        ]}
                      >
                        <InputMask
                          className="form-control"
                          type="text"
                          mask="+90 (___) ___-__-__"
                          replacement={{ _: /\d/ }}
                          placeholder="+90 (___) ___-__-__"
                          showMask
                          separate
                        />
                        {/* <Input placeholder={`${t("Common.Phone")} *`} /> */}
                      </AntForm.Item>
                    </Col>
                    <Col md={6}>
                      <Label>{t("Common.Email") + " *"}</Label>
                      <AntForm.Item
                        name="email"
                        validateTrigger={["onChange", "onBlur"]}
                        rules={[
                          {
                            type: "email",
                            message: t(
                              "FormValidations.CrmPages.EmailValidType"
                            ),
                          },
                          {
                            required: true,
                            message: t(
                              "FormValidations.CrmPages.EmailRequired"
                            ),
                          },
                        ]}
                      >
                        <Input placeholder={`${t("Common.Email")} *`} />
                      </AntForm.Item>
                    </Col>
                    {/* <Col md={4}>
                      <Label>{t("Common.Fax")}</Label>
                      <AntForm.Item
                        name="fax"
                        validateTrigger={["onChange", "onBlur"]}
                      >
                        <Input placeholder={`${t("Common.Fax")}`} />
                      </AntForm.Item>
                    </Col> */}
                  </Row>
                  <Row>
                    <Col md={6}>
                      <Label>{t("Common.taxNo")}</Label>
                      <AntForm.Item
                        name="taxNo"
                        validateTrigger={["onChange", "onBlur"]}
                        rules={[
                          {
                            required: true,
                            message: t(
                              "FormValidations.CrmPages.TaxNoRequired"
                            ),
                          },
                        ]}
                      >
                        <Input placeholder={`${t("Common.taxNo")}`} />
                      </AntForm.Item>
                    </Col>
                    <Col md={6}>
                      <Label>{t("Common.taxOffice")}</Label>
                      <AntForm.Item
                        name="taxOffice"
                        validateTrigger={["onChange", "onBlur"]}
                        rules={[
                          {
                            required: true,
                            message: t(
                              "FormValidations.CrmPages.TaxOfficeRequired"
                            ),
                          },
                        ]}
                      >
                        <Input placeholder={`${t("Common.taxOffice")}`} />
                      </AntForm.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="text-end">
                        <Btn color="success" className="me-3">
                          {t("Common.Save")}
                        </Btn>
                        <Link
                          className="btn btn-danger"
                          to={`${process.env.PUBLIC_URL}/crm/contact/list`}
                        >
                          {t("Common.cancel")}
                        </Link>
                      </div>
                    </Col>
                  </Row>
                </AntForm>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      {savedData?.id ? (
        <div>
          <CompanyContacts company={savedData} />
          <CompanyBanks company={savedData} />
        </div>
      ) : null}
    </div>
  );
}
