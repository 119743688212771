import React, { useEffect, useState } from "react";
import Breadcrumbs from "CommonElements/Breadcrumbs";
import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Label,
  Row,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Btn } from "AbstractElements";
import { toast } from "react-toastify";
import { SubmitHandler, useForm } from "react-hook-form";

// Services
import * as inventoryService from "Services/DebitServices/Inventory";
import * as vehicleService from "Services/DebitServices/Vehicle";
import * as deviceService from "Services/DebitServices/Device";
import * as disposableService from "Services/DebitServices/Disposable";

// Types
import { InventoryType } from "Types/Debit/InventoryType";
import { ProductTypes } from "Enums/Debit/Product";

export default function UpdateInventory() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { id } = useParams<{ id: string | undefined }>();

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
  } = useForm<any>({ shouldUnregister: false });

  const productTypes = ProductTypes;

  const [selectedType, setSelectedType] = useState("");
  const [productList, setProductList] = useState([]);

  const [inventoryDetails, setInventoryDetails] = useState({} as InventoryType);

  const updateDisposable: SubmitHandler<any> = async (formData) => {
    const updatedData = {
      ...inventoryDetails,
      productType: formData.productType
        ? formData.productType
        : inventoryDetails.productType,
      productId: formData.productId
        ? formData.productId
        : inventoryDetails.productId,
      laboratories: formData.laboratories
        ? formData.laboratories
        : inventoryDetails.laboratories,
      stock: formData.stock ? Number(formData.stock) : inventoryDetails.stock,
      controlPeriod: formData.controlPeriod
        ? Number(formData.controlPeriod)
        : inventoryDetails.controlPeriod,
    };
    const { error }: any = await inventoryService.updateInventory(updatedData);
    navigate(`${process.env.PUBLIC_URL}/debit/inventory/list`);
    if (error) toast.error(t(`ERRORS.${error?.errorCode}`));
    else toast.success(t("COMMON_MESSAGES.updatedSuccessfully"));
  };

  const getInventoryById = async () => {
    const { data, error }: any = await inventoryService.getInventoryById(id);
    setInventoryDetails(data?.body);
    setSelectedType(data?.body?.productType);
    setValue("productType", data?.body?.productType);
    setValue("productId", data?.body?.productId);
    setValue("stock", data?.body?.stock);
    setValue("controlPeriod", data?.body?.controlPeriod);
    setValue("laboratories", data?.body?.laboratories);
    if (error) toast.error(error?.meta.errorDescription);
  };

  const getDevices = async () => {
    const { data, error }: any = await deviceService.getDevicesAll();
    setProductList(data?.body?.content);
    if (error) toast.error(error?.meta.errorDescription);
  };

  const getVehicles = async () => {
    const { data, error }: any = await vehicleService.getVehiclesAll();
    setProductList(data?.body?.content);
    if (error) toast.error(error?.meta.errorDescription);
  };

  const getDisposables = async () => {
    const { data, error }: any = await disposableService.getDisposableAll();
    setProductList(data?.body?.content);
    if (error) toast.error(error?.meta.errorDescription);
  };

  useEffect(() => {
    getInventoryById();
  }, [id]);

  const showNamesWithType = (productItem: any) => {
    switch (selectedType) {
      case "DEVICE":
        return productItem?.brand?.name + " " + productItem?.name;
      case "VEHICLE":
        return productItem?.brand?.name + " " + productItem?.model + " " + productItem?.licencePlate;
      case "DISPOSABLE":
        return productItem?.brand?.name + " " + productItem?.name;
    }
  }

  useEffect(() => {
    selectedType === "DEVICE" && getDevices();
    selectedType === "VEHICLE" && getVehicles();
    selectedType === "DISPOSABLE" && getDisposables();
  }, [selectedType]);

  return (
    <div className="page-body">
      <Breadcrumbs
        pageTitle={t("DebitPages.EditInventory")}
        parent={t("DebitPages.Debit")}
        subParent={t("DebitPages.InventoryPageTitle")}
        subParentLink={`${process.env.PUBLIC_URL}/debit/inventory/list`}
        title={t("DebitPages.EditInventory")}
      />
      <Container fluid>
        <Row>
          <Col sm={12}>
            <Card>
              <CardBody>
                <Form
                  className="theme-form"
                  onSubmit={handleSubmit(updateDisposable)}
                >
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>{t("Common.productType") + " *"}</Label>
                        <select
                          className="form-control"
                          defaultValue={inventoryDetails?.productType}
                          {...register("productType", {
                            validate: (value) =>
                              value !== "" || getValues("productType") !== "",
                          })}
                          onChange={(e) => {
                            setSelectedType(e.target.value);
                            register("productType").onChange(e);
                          }}
                        >
                          <option value="">{t("Common.PleaseSelect")}</option>
                          {Object.keys(productTypes).map((key) => (
                            <option
                              key={key}
                              value={key}
                              selected={key === inventoryDetails?.productType}
                            >
                              {t(
                                `Common.${
                                  ProductTypes[key as keyof typeof ProductTypes]
                                }`
                              )}
                            </option>
                          ))}
                        </select>
                        <span className="form-error-text">
                          {errors.productType &&
                            t("FormValidations.DebitPages.ProductTypeRequired")}
                        </span>
                      </FormGroup>
                    </Col>
                  </Row>
                  {selectedType.length > 0 && (
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label>{t("Common.product") + " *"}</Label>
                          <select
                            className="form-control"
                            defaultValue={inventoryDetails?.productId}
                            {...register("productId", {
                              validate: (value) =>
                                value !== "" || getValues("productId") !== "",
                            })}
                          >
                            <option value="">{t("Common.PleaseSelect")}</option>
                            {productList.map((item: any, ind) => (
                              <option
                                key={ind}
                                value={item.id}
                                selected={
                                  item.id === inventoryDetails?.productId
                                }
                              >
                                {showNamesWithType(item)}
                              </option>
                            ))}
                          </select>
                          <span className="form-error-text">
                            {errors.productId &&
                              t("FormValidations.DebitPages.ProductRequired")}
                          </span>
                        </FormGroup>
                      </Col>
                    </Row>
                  )}
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>{t("Common.stock") + " *"}</Label>
                        <input
                          className="form-control"
                          type="number"
                          defaultValue={inventoryDetails?.stock}
                          placeholder={`${t("Common.stock")} *`}
                          {...register("stock", {
                            validate: (value) =>
                              value !== "" || getValues("stock") !== "",
                          })}
                        />
                        <span className="form-error-text">
                          {errors.stock &&
                            t("FormValidations.DebitPages.StockRequired")}
                        </span>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>{t("Common.controlPeriod")}</Label>
                        <input
                          className="form-control"
                          type="number"
                          defaultValue={inventoryDetails?.controlPeriod}
                          placeholder={`${t("Common.controlPeriod")}`}
                          {...register("controlPeriod", { required: false })}
                        />
                        <span className="form-error-text">
                          {errors.controlPeriod &&
                            t(
                              "FormValidations.DebitPages.ControlPeriodRequired"
                            )}
                        </span>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>{t("Common.laboratories")}</Label>
                        <textarea
                          className="form-control"
                          cols={30}
                          rows={5}
                          defaultValue={inventoryDetails?.laboratories}
                          {...register("laboratories", { required: false })}
                        ></textarea>
                        <span className="form-error-text">
                          {errors.laboratories &&
                            t(
                              "FormValidations.DebitPages.LaboratoriesRequired"
                            )}
                        </span>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="text-end">
                        <Btn color="success" className="me-3">
                          {t("Common.update")}
                        </Btn>
                        <Link
                          className="btn btn-danger"
                          to={`${process.env.PUBLIC_URL}/debit/inventory/list`}
                        >
                          {t("Common.cancel")}
                        </Link>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
