import { ConfigProvider } from "antd";
import { ToastContainer } from "react-toastify";
import Routers from "./Routes";

import tr_TR from 'antd/es/locale/tr_TR';
import en_US from 'antd/es/locale/en_US';
import { useTranslation } from "react-i18next";

function App() {
  const { i18n } = useTranslation();
  return (
    <>
      <ConfigProvider locale={i18n.language === "tr" ? tr_TR : en_US}>
        <Routers />
        <ToastContainer />
      </ConfigProvider>
    </>
  );
}

export default App;
