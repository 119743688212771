import * as Paths from "./../files/Paths";
import * as Headers from "./../files/Headers";
import request from "./../files/Request";

// Types
import { ListFilterType } from "Types/Common/ListFilterType";

export const getDocuments = async (filter: ListFilterType, filterObj: any) => {
  const filterParams = {
    name: filterObj?.name
  };
  return request.get(
    `${Paths.education}/documents/all`,
    {
      page: filter.page,
      size: filter.size,
      ...(filter?.sort && {sort: filter?.sort[0]}),
      ...filterParams
    },
    { headers: Headers.headers }
  );
};

export const deleteDocument = async (id: string) => {
  return request.delete(
    `${Paths.education}/documents/${id}`, {},
    {
      headers: Headers.headers,
    }
  );
};

export const addDocument = async (document: any) => {
  const newFormData = new FormData();
  newFormData.append('name', document.name);
  newFormData.append('subject.id', document.subject);
  newFormData.append('file', document.file);

  return request.post(
    `${Paths.education}/documents`, newFormData, {},
    {
      headers: {
        ...Headers.headers, 
        "Content-Type": "multipart/form-data"
      },
    }
  );
};

export const updateDocument = async (document: any) => {
  const newFormData = new FormData();
  newFormData.append('name', document.name);
  newFormData.append('subject.id', document.subject);
  newFormData.append('file', document.file);

  return request.post(
    `${Paths.education}/documents`, newFormData, {},
    {
      headers: {
        ...Headers.headers, 
        "Content-Type": "multipart/form-data"
      },
    }
  );
};

export const documentPreSign = async (key: any) => {
  return request.post(
    `${Paths.s3}/pre-sign`, key, {},
    {
      headers: Headers.headers,
    }
  );
};