import React, { useState, useEffect } from "react";
import { Col, Form, FormFeedback, Label, Row } from "reactstrap";
import ProfilePersonelInfos from "./ProfilePersonelInfos";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router";
import ProfileCompanyInfos from "./ProfileCompanyInfos";
import { useTranslation } from "react-i18next";
import { Drawer, DatePicker } from "antd";
import { useForm } from "react-hook-form";
import { Btn } from "AbstractElements";
import { BloodTypes } from "Enums/User/BloodType";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux-toolkit/store";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import { dateFormats } from "Utils/Constants";
import { fetchUserDetailOwn } from "redux-toolkit/actions/UserActions";
import InputMask from "react-input-mask";

// Types
import { UserDetailOwn } from "Types/User/UserType";
import { UserEditFormData } from "Types/User/UserEditType";

// Services
import * as userService from "Services/UserService";

export default function ProfileDetail() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [openEditProfile, setOpenEditProfile] = useState(false);
  const [validate, setValidate] = useState(false);
  const [birthdate, setBirthdate] = useState("");
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<UserEditFormData>();

  const dispatch = useDispatch<any>();
  const userInfos = useSelector((state: RootState) => state.user);

  const onSubmit = async (e: any) => {
    try {
      // setValidate(true);
      const updatedOwnData: UserDetailOwn = {
        ...e,
        userId: userInfos.id || "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        picUrl: "",
        birthdate: birthdate ? birthdate : userInfos.birthdate,
      };
      console.log(updatedOwnData);
      const { data, error } = await userService.saveUserDetailOwn(
        updatedOwnData
      );
      setOpenEditProfile(false); // for user
      dispatch(fetchUserDetailOwn());
      // setValidate(false);
      toast.success(t("ProfilePages.updatedProfileSuccessfully"));
    } catch (error: any) {
      if (error?.response?.data.meta.status === "FAILURE") {
        toast.error(t(`ERRORS.${error?.response?.data?.meta?.errorCode}`));
      }
    }
  };

  const showDrawer = () => {
    setOpenEditProfile(true); // for user
    // navigate(`${process.env.PUBLIC_URL}/profile/edit-profile`); // for admin/ik
  };

  const onClose = () => {
    setOpenEditProfile(false);
    setValidate(false);
    reset();
  };

  const onChange = (date: any, dateString: any) => {
    setBirthdate(dateString);
  };

  useEffect(() => {
    dispatch(fetchUserDetailOwn());
  }, [dispatch]);

  return (
    <div className="info profile-detail-wrapper">
      <Row className="g-3 step3">
        <Btn
          className={`btn-pill btn-air-primary edit-profile-btn d-none`}
          color="primary"
          onClick={() => showDrawer()}
        >
          <i className="icofont icofont-pencil-alt-5 me-1" />{" "}
          {t("ProfilePages.EditProfile")}
        </Btn>
        <Col sm={12} xl={12} className="order-sm-0 order-xl-1">
          <div className="user-designation tour-email">
            <div className="title">
              <Link to={``}>
                {userInfos.firstName} {userInfos.lastName}
              </Link>{" "}
            </div>
            {/* <div className="desc mt-2"> {'Developer'}</div> */}
          </div>
        </Col>
      </Row>
      <hr />
      <div className="user-des-titles">
        <div className="title">{t("ProfilePages.PersonalInfos")}</div>
      </div>
      <Row>
        <ProfilePersonelInfos />
      </Row>
      <div className="user-des-titles mt-3">
        <div className="title">{t("ProfilePages.CompanyInfos")}</div>
      </div>
      <Row>
        <ProfileCompanyInfos />
      </Row>

      {/* Edit Profile Drawer */}
      <Drawer
        title={t("ProfilePages.EditProfile")}
        placement={"right"}
        closable={true}
        className="drawer--edit-profile"
        onClose={onClose}
        open={openEditProfile}
        key={"right"}
      >
        <Form
          className="needs-validation custom-input"
          onSubmit={handleSubmit(onSubmit)}
          noValidate
        >
          <Row className="g-3">
            <Col md={12} className="position-relative">
              <Label>{t("Common.Email")}</Label>
              <input
                type="email"
                defaultValue={userInfos.email}
                className={`form-control ${errors.email ? "is-invalid" : ""} ${
                  validate && !errors.email ? "is-valid" : ""
                }`}
                placeholder="abc@mail.com"
                {...register("email", {
                  required: t("FormValidations.ProfilePages.EmailRequired"),
                })}
              />
              {errors.email && (
                <FormFeedback tooltip>{errors.email.message}</FormFeedback>
              )}
            </Col>
            <Col md={12} className="position-relative">
              <Label>{t("Common.Phone")}</Label>
              {/* <InputMask
                mask="+90 (999) 999-99-99"
                placeholder="+90 (___) ___-__-__"
              >
                {(inputProps) => (
                  <input
                    {...inputProps}
                    // type="text"
                    defaultValue={userInfos.phoneNumber}
                    className={`form-control ${
                      errors.phoneNumber ? "is-invalid" : ""
                    } ${validate && !errors.phoneNumber ? "is-valid" : ""}`}
                    // placeholder="5351234567"
                    {...register("phoneNumber", {
                      required: t("FormValidations.ProfilePages.PhoneRequired"),
                    })}
                  />
                )}
              </InputMask> */}
              <input
                type="text"
                defaultValue={userInfos.phoneNumber}
                className={`form-control ${
                  errors.phoneNumber ? "is-invalid" : ""
                } ${validate && !errors.phoneNumber ? "is-valid" : ""}`}
                placeholder="5351234567"
                {...register("phoneNumber", {
                  required: t("FormValidations.ProfilePages.PhoneRequired"),
                })}
              />
              {errors.phoneNumber && (
                <FormFeedback tooltip>
                  {errors.phoneNumber.message}
                </FormFeedback>
              )}
            </Col>
            <Col md={12} className="position-relative">
              <Label>{t("Common.Birthday")}</Label>
              {/* <input className={`digits form-control ${errors.birthdate ? 'is-invalid' : ''} ${validate && !errors.birthdate ? 'is-valid' : ''}`} type={"date"} {...register("birthdate", { required: "Please enter your phone birth date" })} /> */}
              <DatePicker
                onChange={onChange}
                defaultValue={
                  userInfos.birthdate ? dayjs(userInfos.birthdate) : ""
                }
                format={dateFormats.Y_M_D}
                className={`form-control ${
                  errors.birthdate ? "is-invalid" : ""
                } ${validate && !errors.birthdate ? "is-valid" : ""}`}
                required
              />
              {errors.birthdate && (
                <FormFeedback tooltip>{errors.birthdate.message}</FormFeedback>
              )}
            </Col>
            <Col md={12} className="position-relative">
              <Label>{t("Common.Contact")}</Label>
              <input
                type="text"
                defaultValue={userInfos.contact}
                className={`form-control ${
                  errors.contact ? "is-invalid" : ""
                } ${validate && !errors.contact ? "is-valid" : ""}`}
                placeholder={t("Common.Contact")}
                {...register("contact", {
                  required: t("FormValidations.ProfilePages.ContactRequired"),
                })}
              />
              {errors.contact && (
                <FormFeedback tooltip>{errors.contact.message}</FormFeedback>
              )}
            </Col>
            <Col md={12} className="position-relative">
              <Label>{t("Common.BloodType")}</Label>
              <select
                defaultValue={userInfos.bloodType}
                className={`form-control ${
                  errors.bloodType ? "is-invalid" : ""
                } ${validate && !errors.bloodType ? "is-valid" : ""}`}
                {...register("bloodType", {
                  required: t("FormValidations.ProfilePages.BloodTypeRequired"),
                })}
              >
                <option value="">{t("Common.PleaseSelect")}</option>
                {Object.values(BloodTypes).map((value: string, ind: number) => (
                  <option key={ind} value={Object.keys(BloodTypes)[ind]}>
                    {value}
                  </option>
                ))}
              </select>
              {errors.bloodType && (
                <FormFeedback tooltip>{errors.bloodType.message}</FormFeedback>
              )}
            </Col>
            <Col md={12} className="position-relative">
              <Label>{t("Common.Address")}</Label>
              <textarea
                defaultValue={userInfos.bloodType}
                className={`form-control ${
                  errors.address ? "is-invalid" : ""
                } ${validate && !errors.address ? "is-valid" : ""}`}
                {...register("address", {
                  required: t("FormValidations.ProfilePages.AddressRequired"),
                })}
              />
              {errors.address && (
                <FormFeedback tooltip>{errors.address.message}</FormFeedback>
              )}
            </Col>
            <Col xs={12} className="float-end">
              <Btn color="primary">{t("Common.Update")}</Btn>
            </Col>
          </Row>
        </Form>
      </Drawer>
    </div>
  );
}
