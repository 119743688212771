import React, { useEffect, useState } from "react";
import Breadcrumbs from "CommonElements/Breadcrumbs";
import {
  Card,
  CardBody,
  Col,
  Collapse,
  Container,
  Label,
  Row,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import DataTable, { TableColumn } from "react-data-table-component";
import { Href, defaultPageAndSize } from "Utils/Constants";
import { Link } from "react-router-dom";
import { Filter } from "react-feather";
import { P, UL, LI, Btn } from "AbstractElements";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { Spin, Tooltip } from "antd";

// Services
import * as userService from "Services/UserService";

// Types
import { ListFilterType } from "Types/Common/ListFilterType";
import { UserList } from "Types/User/UserType";
import FilterInput from "Components/Filters/FilterInput";
import FilterSelect from "Components/Filters/FilterSelect";

export default function Users() {
  const { t } = useTranslation();

  const dispatch = useDispatch<any>();
  // const userName = useSelector((state: RootState) => state.user.email);

  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const handleFilterToggle = () => {
    setIsFilterOpen((prevState) => !prevState);
  };

  const [userListData, setUserListData] = useState([] as UserList[]);
  const [filter, setFilter] = useState(defaultPageAndSize as ListFilterType);
  const [totalRows, setTotalRows] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  const tableListColumns: TableColumn<any>[] = [
    {
      name: t("ProfilePages.Name"),
      sortField: "firstName",
      selector: (row) => row.firstName,
      sortable: true,
      cell: (row) => <P className="f-light-darker">{row.firstName}</P>,
    },
    {
      name: t("ProfilePages.Surname"),
      sortField: "lastName",
      selector: (row) => row.lastName,
      sortable: true,
      cell: (row) => <P className="f-light-darker">{row.lastName}</P>,
    },
    {
      name: t("Common.Email"),
      sortField: "email",
      selector: (row) => row.email,
      sortable: true,
      cell: (row) => <P className="f-light-darker">{row.email}</P>,
    },
    {
      name: t("Common.Enabled"),
      sortField: "enabled",
      selector: (row) => row.enabled,
      sortable: true,
      cell: (row) => (
        <P className="f-light-darker">
          {row.enabled ? (
            <i className="fa fa-check-circle-o text-success text-success fs-5"></i>
          ) : (
            <i className="fa fa-times-circle-o text-danger text-success fs-5"></i>
          )}
        </P>
      ),
    },
    {
      name: t("Common.Actions"),
      width: "80px",
      cell: (row) => (
        <>
          <UL className="action simple-list flex-row">
            {/* <LI className="edit">
              <Tooltip placement="bottom" title={t("Common.Edit")}>
                <Link to={`${process.env.PUBLIC_URL}/management/user/edit`}>
                  <i className="icon-pencil-alt" />
                </Link>
              </Tooltip>
            </LI> */}
            <LI className="delete">
              <Tooltip placement="bottom" title={t("Common.Delete")}>
                <Link onClick={() => deleteUser(row.id)} to={""}>
                  <i className="icon-trash" />
                </Link>
              </Tooltip>
            </LI>
          </UL>
        </>
      ),
      sortable: false,
    },
  ];

  const [loadingTable, setLoadingTable] = useState(false);
  const [filterObj, setFilterObj] = useState({});
  const [clearSignal, setClearSignal] = useState(false);

  const getUsers = async (inventoryListFilter: ListFilterType) => {
    setLoadingTable(true);
    const { data, error }: any = await userService.getUsers(
      inventoryListFilter,
      filterObj
    );
    setLoadingTable(false);
    setUserListData(data?.body?.content);
    setTotalRows(data?.body?.totalElements);
    setTotalPages(data?.body?.totalPages);
    if (error) {
      setLoadingTable(false);
      toast.error(t(`ERRORS.${error?.errorCode}`));
    }
  };

  const handlePageChange = (page: number) => {
    setFilter({
      ...filter,
      page: page - 1,
    });
  };

  const handleRowsPerPageChange = (newRowsPerPage: number) => {
    if (!userListData.length) return;
    setFilter({
      ...filter,
      size: newRowsPerPage,
    });
  };

  // table sorting
  const handleSortColumn = (column: any, direction: string) => {
    getUsers({
      ...filter,
      sort: [`${column.sortField + "," + direction.toUpperCase()}`],
    });
  };

  const deleteUser = async (id: string) => {
    const { error }: any = await userService.deleteUser(id);
    if (error) toast.error(t(`ERRORS.${error?.errorCode}`));
    else {
      toast.success(t("COMMON_MESSAGES.deletedSuccessfully"));
      getUsers(filter);
    }
  };

  useEffect(() => {
    getUsers(filter);
  }, [filter]);

  // Flter functions
  const searchFunc = (filterObj: any) => {
    setFilterObj({
      ...filterObj,
    });
    getUsers(filter);
  };

  const clearFilter = () => {
    setClearSignal(true);
    setFilterObj({});
  };

  useEffect(() => {
    if (clearSignal) {
      getUsers(filter);
      setTimeout(() => setClearSignal(false), 0);
    }
  }, [clearSignal, filterObj]);

  return (
    <div className="page-body">
      {/* {userName} */}
      <Breadcrumbs
        pageTitle={t("UserPages.UserPageTitle")}
        parent={t("UserPages.ManagementPanel")}
        title={t("UserPages.UserPageTitle")}
      />
      <Container fluid>
        <Row>
          <Col sm={12}>
            <Card>
              <CardBody>
                <div className="list-product-header">
                  <div>
                    <div className="light-box">
                      <a href={Href} onClick={handleFilterToggle}>
                        {isFilterOpen ? (
                          <i className="icon-close filter-close" />
                        ) : (
                          <Filter />
                        )}
                      </a>
                    </div>
                    <Link
                      className="btn btn-primary"
                      to={`${process.env.PUBLIC_URL}/management/user/add`}
                    >
                      <i className="fa fa-plus me-2"></i>{" "}
                      {t("UserPages.AddUser")}
                    </Link>
                  </div>
                  <Collapse className={isFilterOpen ? "show" : ""}>
                    <Card className="list-product-body">
                      <CardBody>
                        <Row className="row-cols-xl-5 row-cols-lg-4 row-cols-md-4 row-cols-sm-2 row-cols-2 g-3">
                          <Col>
                            <Label className="mb-2">
                              {t("ProfilePages.Name")}
                            </Label>
                            <FilterInput
                              inputType={"text"}
                              inputName={"firstName"}
                              setFilterObjFun={setFilterObj}
                              clearSignal={clearSignal}
                            />
                          </Col>
                          <Col>
                            <Label className="mb-2">
                              {t("ProfilePages.Surname")}
                            </Label>
                            <FilterInput
                              inputType={"text"}
                              inputName={"lastName"}
                              setFilterObjFun={setFilterObj}
                              clearSignal={clearSignal}
                            />
                          </Col>
                          <Col>
                            <Label className="mb-2">{t("Common.Email")}</Label>
                            <FilterInput
                              inputType={"email"}
                              inputName={"email"}
                              setFilterObjFun={setFilterObj}
                              clearSignal={clearSignal}
                            />
                          </Col>
                          <Col>
                            <Label className="mb-2">
                              {t("Common.Enabled")}
                            </Label>
                            <FilterSelect
                              inputName={"enabled"}
                              setFilterObjFun={setFilterObj}
                              isStatus={true}
                              options={[
                                { text: t("Common.Active"), value: "true" },
                                { text: t("Common.Passive"), value: "false" },
                              ]}
                              clearSignal={clearSignal}
                            />
                          </Col>
                        </Row>
                        <Row className="my-2">
                          <Col className="d-flex justify-content-end">
                            <Btn
                              color="default"
                              className="me-3"
                              size="small"
                              onClick={() => clearFilter()}
                            >
                              {t("Common.clearAllFilters")}
                            </Btn>
                            <Btn
                              color="primary"
                              className="me-3"
                              size="small"
                              onClick={() => searchFunc(filterObj)}
                            >
                              <i className="icofont icofont-ui-search"></i>
                            </Btn>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Collapse>
                </div>
                <div className="list-product">
                  <DataTable
                    data={userListData ? userListData : []}
                    columns={tableListColumns}
                    progressComponent={<Spin />}
                    progressPending={loadingTable}
                    pagination
                    paginationServer
                    paginationTotalRows={totalRows}
                    paginationPerPage={filter.size}
                    onChangePage={handlePageChange}
                    onChangeRowsPerPage={handleRowsPerPageChange}
                    onSort={handleSortColumn}
                    paginationComponentOptions={{
                      rowsPerPageText: t("Common.rowsPerPage"),
                      rangeSeparatorText: t("Common.rangeSeparatorText")
                    }}
                    noDataComponent={<>{t("Common.noData")}</>}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
