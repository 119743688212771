import React, { useEffect, useState } from "react";
import Breadcrumbs from "CommonElements/Breadcrumbs";
import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Label,
  Row,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import { dateFormats } from "Utils/Constants";
import { Link, useNavigate } from "react-router-dom";
import { Btn } from "AbstractElements";
import { toast } from "react-toastify";
import { SubmitHandler, useForm } from "react-hook-form";
import dayjs from "dayjs";
import { DatePicker, Switch } from "antd";

// Services
import * as brandService from "Services/DebitServices/Brand";
import * as vehicleClassService from "Services/DebitServices/VehicleClass";
import * as vehicleService from "Services/DebitServices/Vehicle";

// Enums
import { FuelTypes } from "Enums/Debit/Fuel";
import { GearTypes } from "Enums/Debit/Gear";

export default function AddVehicle() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<any>();

  const fuelTypes = FuelTypes;
  const gearTypes = GearTypes;

  const [brandList, setBrandList] = useState([]);
  const [vehicleClassList, setVehicleClassList] = useState([]);
  const [takeBeforeMesure, setTakeBeforeMesure] = useState(false);
  const [trailer, setTrailer] = useState(false);
  const [activeInsuranceDate, setActiveInsuranceDate] = useState("");
  const [activeTrafficInsuranceDate, setActiveTrafficInsuranceDate] =
    useState("");

  const addVehicle: SubmitHandler<any> = async (formData) => {
    const newData = {
      ...formData,
      brand: {
        id: formData.brand,
      },
      vehicleClass: {
        id: formData.vehicleClass,
      },
      takeBeforeMesure: takeBeforeMesure,
      // trailer: trailer,
      activeInsuranceDate: dayjs(activeInsuranceDate).format(
        dateFormats.D_M_Y_H_m_s_Z
      ),
      activeTrafficInsuranceDate: dayjs(activeTrafficInsuranceDate).format(
        dateFormats.D_M_Y_H_m_s_Z
      ),
      productType: "VEHICLE",
      cargoCapacity: formData.cargoCapacity
        ? Number(formData.cargoCapacity)
        : 0,
      consumeRate: Number(formData.consumeRate),
      emptyWeight: formData.emptyWeight ? Number(formData.emptyWeight) : 0, // kullanici giris yapmayacak
      km: Number(formData.km),
      // maxSpeed: Number(formData.maxSpeed),
      modelYear: Number(formData.modelYear),
      passengerCapacity: formData.passengerCapacity
        ? Number(formData.passengerCapacity)
        : 0, // kullanici giris yapmayacak
      // quantity: Number(formData.quantity),
      // wheelCount: Number(formData.wheelCount),
    };
    const { error }: any = await vehicleService.addVehicle(newData);
    navigate(`${process.env.PUBLIC_URL}/debit/vehicle/list`);
    if (error) toast.error(t(`ERRORS.${error?.errorCode}`));
    else toast.success(t("COMMON_MESSAGES.addedSuccessfully"));
  };

  const getBrands = async () => {
    const { data, error }: any = await brandService.getBrandsAll();
    setBrandList(data?.body?.content);
    if (error) toast.error(t(`ERRORS.${error?.errorCode}`));
  };

  const getVehicleClass = async () => {
    const { data, error }: any = await vehicleClassService.getVehicleClassAll();
    setVehicleClassList(data?.body?.content);
    if (error) toast.error(t(`ERRORS.${error?.errorCode}`));
  };

  useEffect(() => {
    getBrands();
    getVehicleClass();
  }, []);

  return (
    <div className="page-body">
      <Breadcrumbs
        pageTitle={t("DebitPages.AddVehicle")}
        parent={t("DebitPages.Debit")}
        subParent={t("DebitPages.VehiclePageTitle")}
        subParentLink={`${process.env.PUBLIC_URL}/debit/vehicle/list`}
        title={t("DebitPages.AddVehicle")}
      />
      <Container fluid>
        <Row>
          <Col sm={12}>
            <Card>
              <CardBody>
                <Form
                  className="theme-form"
                  onSubmit={handleSubmit(addVehicle)}
                >
                  <Row>
                    <Col xs={12} md={4}>
                      <FormGroup>
                        <Label>{t("Common.brand") + " *"}</Label>
                        <select
                          className="form-control"
                          {...register("brand", { required: true })}
                        >
                          <option value="">{t("Common.PleaseSelect")}</option>
                          {brandList.map((item: any, ind) => (
                            <option key={ind} value={item.id}>
                              {item?.name}
                            </option>
                          ))}
                        </select>
                        <span className="form-error-text">
                          {errors.brand &&
                            t("FormValidations.DebitPages.BrandRequired")}
                        </span>
                      </FormGroup>
                    </Col>
                    <Col xs={12} md={4}>
                      <FormGroup>
                        <Label>{t("Common.vehicleClass") + " *"}</Label>
                        <select
                          className="form-control"
                          {...register("vehicleClass", { required: true })}
                          onChange={(e) => {
                            register("vehicleClass").onChange(e);
                          }}
                        >
                          <option value="">{t("Common.PleaseSelect")}</option>
                          {vehicleClassList.map((item: any, ind) => (
                            <option key={ind} value={item.id}>
                              {item?.name}
                            </option>
                          ))}
                        </select>
                        <span className="form-error-text">
                          {errors.vehicleClass &&
                            t(
                              "FormValidations.DebitPages.VehicleClassRequired"
                            )}
                        </span>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={6}>
                      <FormGroup>
                        <Label>{t("Common.model") + " *"}</Label>
                        <input
                          className="form-control"
                          type="text"
                          placeholder={`${t("Common.model")} *`}
                          {...register("model", { required: true })}
                        />
                        <span className="form-error-text">
                          {errors.model &&
                            t("FormValidations.DebitPages.ModelRequired")}
                        </span>
                      </FormGroup>
                    </Col>
                    <Col xs={12} md={6}>
                      <FormGroup>
                        <Label>{t("Common.modelYear") + " *"}</Label>
                        <input
                          className="form-control"
                          type="number"
                          placeholder={`${t("Common.modelYear")} *`}
                          {...register("modelYear", { required: true })}
                        />
                        <span className="form-error-text">
                          {errors.modelYear &&
                            t("FormValidations.DebitPages.ModelYearRequired")}
                        </span>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={4}>
                      <FormGroup>
                        <Label>{t("Common.gearType") + " *"}</Label>
                        <select
                          className="form-control"
                          {...register("gearType", { required: true })}
                          onChange={(e) => {
                            register("gearType").onChange(e);
                          }}
                        >
                          <option value="">{t("Common.PleaseSelect")}</option>
                          {Object.keys(gearTypes).map((key) => (
                            <option key={key} value={key}>
                              {t(
                                `Common.${
                                  GearTypes[key as keyof typeof GearTypes]
                                }`
                              )}
                            </option>
                          ))}
                        </select>
                        <span className="form-error-text">
                          {errors.gearType &&
                            t("FormValidations.DebitPages.GearTypeRequired")}
                        </span>
                      </FormGroup>
                    </Col>
                    <Col xs={12} md={4}>
                      <FormGroup>
                        <Label>{t("Common.fuelType") + " *"}</Label>
                        <select
                          className="form-control"
                          {...register("fuelType", { required: true })}
                          onChange={(e) => {
                            register("fuelType").onChange(e);
                          }}
                        >
                          <option value="">{t("Common.PleaseSelect")}</option>
                          {Object.keys(fuelTypes).map((key) => (
                            <option key={key} value={key}>
                              {t(
                                `Common.${
                                  FuelTypes[key as keyof typeof FuelTypes]
                                }`
                              )}
                            </option>
                          ))}
                        </select>
                        <span className="form-error-text">
                          {errors.fuelType &&
                            t("FormValidations.DebitPages.FuelTypeRequired")}
                        </span>
                      </FormGroup>
                    </Col>
                    <Col xs={12} md={4}>
                      <FormGroup>
                        <Label>{t("Common.consumeRate") + " *"}</Label>
                        <input
                          className="form-control"
                          type="number"
                          placeholder={`${t("Common.consumeRate")} *`}
                          {...register("consumeRate", { required: true })}
                        />
                        <span className="form-error-text">
                          {errors.consumeRate &&
                            t("FormValidations.DebitPages.ConsumeRateRequired")}
                        </span>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={6}>
                      <FormGroup>
                        <Label>{t("Common.licencePlate") + " *"}</Label>
                        <input
                          className="form-control"
                          type="text"
                          placeholder={`${t("Common.licencePlate")} *`}
                          {...register("licencePlate", { required: true })}
                        />
                        <span className="form-error-text">
                          {errors.licencePlate &&
                            t(
                              "FormValidations.DebitPages.LicencePlateRequired"
                            )}
                        </span>
                      </FormGroup>
                    </Col>
                    <Col xs={12} md={6}>
                      <FormGroup>
                        <Label>{"KM" + " *"}</Label>
                        <input
                          className="form-control"
                          type="number"
                          placeholder={`KM *`}
                          {...register("km", { required: true })}
                        />
                        <span className="form-error-text">
                          {errors.km &&
                            t("FormValidations.DebitPages.KmRequired")}
                        </span>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className="mb-4">
                    <Col xs={12} md={4}>
                      <FormGroup className="d-flex align-items-center h-100">
                        <Label>{t("Common.takeBeforeMesure")}</Label>
                        <div className="mx-3">
                          <Switch
                            checked={takeBeforeMesure}
                            onChange={(e: any) => {
                              setTakeBeforeMesure(e);
                            }}
                          />
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={6}>
                      <FormGroup>
                        <Label>{t("Common.activeInsuranceDate")}</Label>
                        <DatePicker
                          // showTime
                          onChange={(e: any) => {
                            setActiveInsuranceDate(e);
                          }}
                          format={dateFormats.Y_M_D}
                          className={`form-control`}
                          required
                        />
                        <span className="form-error-text">
                          {errors.activeInsuranceDate &&
                            t(
                              "FormValidations.DebitPages.ActiveInsuranceDateRequired"
                            )}
                        </span>
                      </FormGroup>
                    </Col>
                    <Col xs={12} md={6}>
                      <FormGroup>
                        <Label>{t("Common.activeTrafficInsuranceDate")}</Label>
                        <DatePicker
                          // showTime
                          onChange={(e: any) => {
                            setActiveTrafficInsuranceDate(e);
                          }}
                          format={dateFormats.Y_M_D}
                          className={`form-control`}
                          required
                        />
                        <span className="form-error-text">
                          {errors.activeTrafficInsuranceDate &&
                            t(
                              "FormValidations.DebitPages.ActiveTrafficInsuranceDateRequired"
                            )}
                        </span>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>{t("Common.features")}</Label>
                        <textarea
                          className="form-control"
                          cols={30}
                          rows={5}
                          {...register("features", { required: false })}
                        ></textarea>
                        <span className="form-error-text">
                          {errors.features &&
                            t("FormValidations.DebitPages.FeaturesRequired")}
                        </span>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="text-end">
                        <Btn color="success" className="me-3">
                          {t("Common.add")}
                        </Btn>
                        <Link
                          className="btn btn-danger"
                          to={`${process.env.PUBLIC_URL}/debit/vehicle/list`}
                        >
                          {t("Common.cancel")}
                        </Link>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
