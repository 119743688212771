import * as Paths from "./../files/Paths";
import * as Headers from "./../files/Headers";
import request from "./../files/Request";

// Types
import { ListFilterType } from "Types/Common/ListFilterType";
import { defaultPageAndSizeForAll } from "Utils/Constants";

export const getExperiments = async (filter: ListFilterType, filterObj: any) => {
  const filterParams = {
    name: filterObj?.name,
    code: filterObj?.code,
    price: filterObj?.price
  };
  return request.get(
    `${Paths.emission}/experiment/all`,
    {
      page: filter.page,
      size: filter.size,
      ...(filter?.sort && {sort: filter?.sort[0]}),
      ...filterParams
    },
    { headers: Headers.headers }
  );
};

export const getExperimentsAll = async () => {
  return request.get(
    `${Paths.emission}/experiment/all/minimal`,
    {
      page: defaultPageAndSizeForAll.page,
      size: defaultPageAndSizeForAll.size
    },
    { headers: Headers.headers }
  );
};

export const getExperimentUnitTypes = async (selectedLab: any) => {
  const filterParams = {
    unitType: selectedLab
  };
  return request.get(
    `${Paths.emission}/measurement-unit/all`,
    {
      page: defaultPageAndSizeForAll.page,
      size: defaultPageAndSizeForAll.size,
      ...filterParams
    },
    { headers: Headers.headers }
  );
};

export const getExperimentById = async (id: any) => {
  return request.get(
    `${Paths.emission}/experiment/${id}`, {},
    { headers: Headers.headers }
  );
};

export const deleteExperiment = async (id: string) => {
  return request.delete(
    `${Paths.emission}/experiment/${id}`,
    {},
    {
      headers: Headers.headers,
    }
  );
};

export const updateExperiment = async (experiment: any) => {
  return request.put(`${Paths.emission}/experiment`, experiment, {
    headers: Headers.headers,
  });
};

export const addExperiment = async (experiment: any) => {
  return request.post(`${Paths.emission}/experiment`, experiment, {}, {
    headers: Headers.headers,
  });
};
