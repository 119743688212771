import * as Paths from "./../files/Paths";
import * as Headers from "./../files/Headers";
import request from "./../files/Request";

// Types
import { ListFilterType } from "Types/Common/ListFilterType";

// Constants
import { defaultPageAndSizeForAll } from "Utils/Constants";

export const getDisposables = async (disposableListFilter: ListFilterType, filterObj: any) => {
  const filterParams = {
    "brand.name": filterObj?.brand,
    name: filterObj?.name,
    quantity: filterObj?.quantity
  };
  return request.get(
    `${Paths.debit}/disposable/find-all/paginated`,
    {
      page: disposableListFilter.page,
      size: disposableListFilter.size,
      ...(disposableListFilter?.sort && {sort: disposableListFilter?.sort[0]}),
      ...filterParams
    },
    { headers: Headers.headers }
  );
};

export const getDisposableAll = async () => {
  return request.get(
    `${Paths.debit}/disposable/find-all/paginated`,
    {
      page: defaultPageAndSizeForAll.page,
      size: defaultPageAndSizeForAll.size
    },
    { headers: Headers.headers }
  );
};

export const getDisposableById = async (id: any) => {
  return request.get(
    `${Paths.debit}/disposable/find-by-id/${id}`, {},
    { headers: Headers.headers }
  );
};

export const deleteDisposable = async (disposableId: string) => {
  return request.delete(
    `${Paths.debit}/disposable/toggle-delete/${disposableId}`, {},
    {
      headers: Headers.headers,
    }
  );
};

export const updateDisposable = async (disposable: any) => {
  return request.put(`${Paths.debit}/disposable/update`, disposable, {
    headers: Headers.headers,
  });
};

export const addDisposable = async (disposable: any) => {
  return request.post(`${Paths.debit}/disposable/create`, disposable, {}, {
    headers: Headers.headers,
  });
};