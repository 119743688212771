import * as Paths from "./../files/Paths";
import * as Headers from "./../files/Headers";
import request from "./../files/Request";

// Types
import { ListFilterType } from "Types/Common/ListFilterType";
import { Subject } from "Types/Education/Subject";
import { defaultPageAndSizeForAll } from "Utils/Constants";

export const getSubjects = async (filter: ListFilterType, filterObj: any) => {
  const filterParams = {
    name: filterObj?.name
  };
  return request.get(
    `${Paths.education}/subjects/all`,
    {
      page: filter.page,
      size: filter.size,
      ...(filter?.sort && {sort: filter?.sort[0]}),
      ...filterParams
    },
    { headers: Headers.headers }
  );
};

export const getSubjectsAll = async (filterObj?: any) => {
  return request.get(
    `${Paths.education}/subjects/all/minimal`,
    {
      page: defaultPageAndSizeForAll.page,
      size: defaultPageAndSizeForAll.size,
      ...(filterObj?.categoryId && { category: filterObj?.categoryId })
    },
    { headers: Headers.headers }
  );
};

export const getSubject = async (id: any) => {
  return request.get(
    `${Paths.education}/subjects/${id}`, {},
    { headers: Headers.headers }
  );
};

export const deleteSubject= async (id: string) => {
  return request.delete(
    `${Paths.education}/subjects/${id}`, {},
    {
      headers: Headers.headers,
    }
  );
};

export const addSubject = async (subjects: Subject) => {
  return request.post(
    `${Paths.education}/subjects`, subjects, {},
    {
      headers: Headers.headers,
    }
  );
};