import * as Paths from "./../files/Paths";
import * as Headers from "./../files/Headers";
import request from "./../files/Request";

// Types
import { ListFilterType } from "Types/Common/ListFilterType";
import { defaultPageAndSizeForAll } from "Utils/Constants";

export const getLaboratories = async (filter: ListFilterType, filterObj: any) => {
  const filterParams = {
    name: filterObj?.name,
    code: filterObj?.code,
    barcodingCode: filterObj?.barcodingCode
  };
  return request.get(
    `${Paths.emission}/laboratory/all`,
    {
      page: filter.page,
      size: filter.size,
      ...(filter?.sort && {sort: filter?.sort[0]}),
      ...filterParams
    },
    { headers: Headers.headers }
  );
};

export const getLaboratoriesAll = async () => {
  return request.get(
    `${Paths.emission}/laboratory/all/minimal`,
    {
      page: defaultPageAndSizeForAll.page,
      size: defaultPageAndSizeForAll.size
    },
    { headers: Headers.headers }
  );
};

export const getLaboratoryTypeAll = async () => {
  return request.get(
    `${Paths.emission}/laboratory-type/all`,
    {
      page: defaultPageAndSizeForAll.page,
      size: defaultPageAndSizeForAll.size
    },
    { headers: Headers.headers }
  );
};

export const getLaboratoryById = async (id: any) => {
  return request.get(
    `${Paths.emission}/laboratory/${id}`, {},
    { headers: Headers.headers }
  );
};

export const deleteLaboratory = async (id: string) => {
  return request.delete(
    `${Paths.emission}/laboratory/${id}`,
    {},
    {
      headers: Headers.headers,
    }
  );
};

export const updateLaboratory = async (laboratory: any) => {
  return request.put(`${Paths.emission}/laboratory`, laboratory, {
    headers: Headers.headers,
  });
};

export const addLaboratory = async (laboratory: any) => {
  return request.post(`${Paths.emission}/laboratory`, laboratory, {}, {
    headers: Headers.headers,
  });
};
