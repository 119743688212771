import * as Paths from "./../files/Paths";
import * as Headers from "./../files/Headers";
import request from "./../files/Request";

// Types
import { ListFilterType } from "Types/Common/ListFilterType";
import { VehicleClassType } from "Types/Debit/VehicleClass";

// Constants
import { defaultPageAndSizeForAll } from "Utils/Constants";

export const getVehicleClass = async (vehicleClassListFilter: ListFilterType, filterObj: any) => {
  const filterParams = {
    name: filterObj?.name
  };
  return request.get(
    `${Paths.debit}/vehicle-class/find-all/paginated`,
    {
      page: vehicleClassListFilter.page,
      size: vehicleClassListFilter.size,
      ...(vehicleClassListFilter?.sort && {sort: vehicleClassListFilter?.sort[0]}),
      ...filterParams
    },
    { headers: Headers.headers }
  );
};

export const getVehicleClassAll = async () => {
  return request.get(
    `${Paths.debit}/vehicle-class/find-all/paginated`,
    {
      page: defaultPageAndSizeForAll.page,
      size: defaultPageAndSizeForAll.size
    },
    { headers: Headers.headers }
  );
};

export const getVehicleClassById = async (id: any) => {
  return request.get(
    `${Paths.debit}/vehicle-class/find-by-id/${id}`, {},
    { headers: Headers.headers }
  );
};

export const deleteVehicleClass = async (vehicleClassId: string) => {
  return request.delete(
    `${Paths.debit}/vehicle-class/toggle-delete/${vehicleClassId}`, {},
    {
      headers: Headers.headers,
    }
  );
};

export const updateVehicleClass = async (vehicleClass: VehicleClassType) => {
  return request.put(`${Paths.debit}/vehicle-class/update`, vehicleClass, {
    headers: Headers.headers,
  });
};

export const addVehicleClass = async (vehicleClass: any) => {
  return request.post(`${Paths.debit}/vehicle-class/create`, vehicleClass, {}, {
    headers: Headers.headers,
  });
};