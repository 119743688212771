import { Link, useNavigate } from "react-router-dom";
import { Image, LI, P, UL } from "AbstractElements";
import { Logout } from "Utils/Constants";
import { profileHeaderData } from "Data/HeaderData/RightHeaderData";
import FeatherIconCom from "Utils/CommonComponents/CommonIcons/FeatherIconCom";
import { dynamicImage } from "Utils";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

// Services
import * as authService from "Services/AuthService";
import { RootState } from "redux-toolkit/store";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { fetchUserDetailOwn } from "redux-toolkit/actions/UserActions";

export default function ProfileHeader() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  const userInfos = useSelector((state: RootState) => state.user);

  const logout = async () => {
    try {
      const resp = await authService.logOut();
      if (resp.data.error) {
        toast.error(resp.data.error.errorDescription);
      }
      localStorage.clear();
      navigate(`${process.env.PUBLIC_URL}/login`);
    } catch (error: any) {
      if (error?.response?.data.meta.status === "FAILURE") {
        toast.error(t(`ERRORS.${error?.response?.data?.meta?.errorCode}`));
        navigate(`${process.env.PUBLIC_URL}/login`);
      }
    }
  };

  useEffect(() => {
    dispatch(fetchUserDetailOwn());
  }, [dispatch]);

  return (
    <LI className="profile-nav onhover-dropdown p-0">
      <div className="d-flex align-items-center profile-media">
        {(userInfos.picUrl !== null || userInfos.picUrl !== "") ? (
          <div className="avatar no-image">
            <div>
              {userInfos.firstName.charAt(0) + userInfos.lastName.charAt(0)}
            </div>
          </div>
        ) : (
          <div className="avatar">
            <Image
              src={userInfos.picUrl}
              className="step1"
              alt={userInfos.firstName + " " + userInfos.lastName}
            />
          </div>
        )}
        <div className="flex-grow-1">
          <span>{userInfos.firstName + " " + userInfos.lastName}</span>
          <P className="mb-0">
            {t("Common.user")} <i className="middle fa fa-angle-down"></i>
          </P>
        </div>
      </div>
      <UL className="profile-dropdown onhover-show-div simple-list">
        {profileHeaderData.map((data) => (
          <LI key={data.id}>
            <Link to={data.url}>
              <FeatherIconCom iconName={data.icon} />
              <span>{t(`${data.text}`)}</span>
            </Link>
          </LI>
        ))}
        <LI>
          <Link onClick={logout} to={""}>
            <FeatherIconCom iconName={"LogIn"} />
            <span>{t(`${Logout}`)}</span>
          </Link>
        </LI>
      </UL>
    </LI>
  );
}
