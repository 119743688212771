import request from "Services/files/Request";
import * as Paths from "Services/files/Paths";
import * as Headers from "Services/files/Headers";
import {ListFilterType} from "Types/Common/ListFilterType";

export const addWork = async (work: any) => {
  return request.post(`${Paths.emission}/work`, work, {}, {
    headers: Headers.headers,
  });
};

export const  getWorks = async (filter: ListFilterType, filterObj: any) => {
  const filterParams = {
    workGroupId: filterObj.workGroupId,
  };
  return request.get(
    `${Paths.emission}/work/find-all/paginated`,
    {
      page: filter.page,
      size: filter.size,
      ...(filter?.sort && {sort: filter?.sort[0]}),
      ...filterParams
    },
    { headers: Headers.headers }
  );
};