import * as Paths from "./../files/Paths";
import * as Headers from "./../files/Headers";
import request from "./../files/Request";

// Types
import { ListFilterType } from "Types/Common/ListFilterType";
import {Project} from "Types/Crm/Project";

export const getProjects = async (filter: ListFilterType, filterObj: any) => {
  const filterParams = {
    name: filterObj?.name,
    projectCode: filterObj?.projectCode,
    projectLocation: filterObj?.projectLocation
  };
  return request.get(
    `${Paths.crm}/project/find-all/paginated`,
    {
      page: filter.page,
      size: filter.size,
      ...(filter?.sort && {sort: filter?.sort[0]}),
      ...filterParams
    },
    { headers: Headers.headers }
  );
};

export const getProject = async (id: string) => {
  return request.get(
    `${Paths.crm}/project/${id}`, {},
    {
      headers: Headers.headers,
    },
  );
};

export const deleteProject = async (id: string) => {
  return request.delete(
    `${Paths.crm}/project/toggle-delete/${id}`, {},
    {
      headers: Headers.headers,
    }
  );
};

export const addProject = async (project: Project) => {
  return request.post(
    `${Paths.crm}/project/create`, project, {},
    {
      headers: Headers.headers,
    }
  );
};

export const updateProject = async (project: Project) => {
  return request.put(
    `${Paths.crm}/project/update`, project,
    {
      headers: Headers.headers,
    }
  );
};