import React, { useState, useEffect } from "react";
import Breadcrumbs from "CommonElements/Breadcrumbs";
import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Label,
  Row,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { Btn } from "AbstractElements";
import { toast } from "react-toastify";
import { SubmitHandler, useForm } from "react-hook-form";

// Services
import * as laboratoryService from "Services/MetriksServices/Laboratory";

// Types
import { BarcodingType } from "Enums/Metriks/BarcodingType";
import { LaboratoryReportingType } from "Enums/Metriks/LaboratoryReportingType";
import { Switch } from "antd";
import { BarcodingStageType } from "Enums/Metriks/BarcodeStageType";
import { LaboratoryType } from "Types/Emission/LaboratoryType";
const barcodingTypes = BarcodingType;
const reportingTypes = LaboratoryReportingType;
const barcodingStageTypes = BarcodingStageType;

export default function AddLaboratory() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [selectedMeasurementType, setSelectedMeasurementType] = useState("");
  const [selectedBarcodingType, setSelectedBarcodingType] = useState("");
  const [selectedReportingType, setSelectedReportingType] = useState("");
  const [selectedBarcodingStageType, setSelectedBarcodingStageType] =
    useState("");
  const [printParams, setPrintParams] = useState(false);

  const [laboratoryTypes, setLaboratoryTypes] = useState([]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<any>();

  const addLabortory: SubmitHandler<any> = async (formData) => {
    const newData = {
      ...formData,
      type: {
        id: selectedMeasurementType,
      },
      reportingType: selectedReportingType,
      barcodingType: selectedBarcodingType,
      printParameters: printParams,
      barcodingStageType: selectedBarcodingStageType,
      active: true,
    };
    const { error }: any = await laboratoryService.addLaboratory(newData);
    navigate(`${process.env.PUBLIC_URL}/experiments/laboratory/list`);
    if (error) toast.error(t(`ERRORS.${error?.errorCode}`));
    else toast.success(t("COMMON_MESSAGES.addedSuccessfully"));
  };

  const getLaboratoryTypes = async () => {
    const { data, error }: any = await laboratoryService.getLaboratoryTypeAll();
    setLaboratoryTypes(data?.body?.content);
    if (error) {
      toast.error(t(`ERRORS.${error?.errorCode}`));
    }
  };

  useEffect(() => {
    getLaboratoryTypes();
  }, []);

  return (
    <div className="page-body">
      <Breadcrumbs
        pageTitle={t("ExperimentPages.LaboratoryPages.AddLaboratory")}
        parent={t("ExperimentPages.LaboratoryPages.Metriks")}
        subParent={t("ExperimentPages.LaboratoryPages.LaboratoryPageTitle")}
        subParentLink={`${process.env.PUBLIC_URL}/experiments/laboratory/list`}
        title={t("ExperimentPages.LaboratoryPages.AddLaboratory")}
      />
      <Container fluid>
        <Row>
          <Col sm={12}>
            <Card>
              <CardBody>
                <Form
                  className="theme-form"
                  onSubmit={handleSubmit(addLabortory)}
                >
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>
                          {t(
                            "ExperimentPages.LaboratoryPages.Laboratory"
                          ) + " *"}
                        </Label>
                        <select
                          className="form-control"
                          {...register("type", { required: true })}
                          onChange={(e) => {
                            setSelectedMeasurementType(e.target.value);
                            register("type").onChange(e);
                          }}
                        >
                          <option value="">{t("Common.PleaseSelect")}</option>
                          {laboratoryTypes.map((labType: any) => (
                            <option key={labType.id} value={labType.id}>
                              {t(
                                `ExperimentPages.LaboratoryPages.${labType.displayName}`
                              )}
                            </option>
                          ))}
                        </select>
                        <span className="form-error-text">
                          {errors.type &&
                            t(
                              "FormValidations.ExperimentPages.LaboratoryPages.MeasurementTypeRequired"
                            )}
                        </span>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>{t("Common.name")}</Label>
                        <input
                          className="form-control"
                          type="text"
                          placeholder={`${t("Common.name")} *`}
                          {...register("name", { required: true })}
                        />
                        <span className="form-error-text">
                          {errors.name &&
                            t(
                              "FormValidations.ExperimentPages.LaboratoryPages.NameRequired"
                            )}
                        </span>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={6}>
                      <FormGroup>
                        <Label>{t("Common.code")}</Label>
                        <input
                          className="form-control"
                          type="text"
                          placeholder={`${t("Common.code")} *`}
                          {...register("code", { required: true })}
                        />
                        <span className="form-error-text">
                          {errors.code &&
                            t(
                              "FormValidations.ExperimentPages.LaboratoryPages.CodeRequired"
                            )}
                        </span>
                      </FormGroup>
                    </Col>
                    <Col xs={12} md={6}>
                      <FormGroup>
                        <Label>
                          {t("ExperimentPages.LaboratoryPages.barcodingCode")}
                        </Label>
                        <input
                          className="form-control"
                          type="text"
                          placeholder={`${t(
                            "ExperimentPages.LaboratoryPages.barcodingCode"
                          )}`}
                          {...register("barcodingCode", { required: false })}
                        />
                        <span className="form-error-text">
                          {errors.barcodingCode &&
                            t(
                              "FormValidations.ExperimentPages.LaboratoryPages.BarcodingCodeRequired"
                            )}
                        </span>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>
                          {t("ExperimentPages.LaboratoryPages.barcoding") +
                            " *"}
                        </Label>
                        <select
                          className="form-control"
                          {...register("barcodingType", { required: true })}
                          onChange={(e) => {
                            setSelectedBarcodingType(e.target.value);
                            register("barcodingType").onChange(e);
                          }}
                        >
                          <option value="">{t("Common.PleaseSelect")}</option>
                          {Object.keys(barcodingTypes).map((key) => (
                            <option key={key} value={key}>
                              {t(
                                `ExperimentPages.LaboratoryPages.${
                                  BarcodingType[
                                    key as keyof typeof BarcodingType
                                  ]
                                }`
                              )}
                            </option>
                          ))}
                        </select>
                        <span className="form-error-text">
                          {errors.barcodingType &&
                            t(
                              "FormValidations.ExperimentPages.LaboratoryPages.BarcodingTypeRequired"
                            )}
                        </span>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>
                          {t("ExperimentPages.LaboratoryPages.barcodingStage") +
                            " *"}
                        </Label>
                        <select
                          className="form-control"
                          {...register("barcodingStageType", {
                            required: true,
                          })}
                          onChange={(e) => {
                            setSelectedBarcodingStageType(e.target.value);
                            register("barcodingStageType").onChange(e);
                          }}
                        >
                          <option value="">{t("Common.PleaseSelect")}</option>
                          {Object.keys(barcodingStageTypes).map((key) => (
                            <option key={key} value={key}>
                              {t(
                                `ExperimentPages.LaboratoryPages.${
                                  BarcodingStageType[
                                    key as keyof typeof BarcodingStageType
                                  ]
                                }`
                              )}
                            </option>
                          ))}
                        </select>
                        <span className="form-error-text">
                          {errors.barcodingStageType &&
                            t(
                              "FormValidations.ExperimentPages.LaboratoryPages.BarcodingStageRequired"
                            )}
                        </span>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup className="mb-4 h-100">
                        <Label>
                          {t("ExperimentPages.LaboratoryPages.printParameters")}
                        </Label>
                        <div className="d-flex align-items-center">
                          <Switch
                            className="me-2"
                            checked={printParams}
                            onChange={(e: any) => {
                              setPrintParams(e);
                            }}
                          />
                          {t(
                            "ExperimentPages.LaboratoryPages.printParametersText"
                          )}
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>
                          {t("ExperimentPages.LaboratoryPages.reporting") +
                            " *"}
                        </Label>
                        <select
                          className="form-control"
                          {...register("reportingType", { required: true })}
                          onChange={(e) => {
                            setSelectedReportingType(e.target.value);
                            register("reportingType").onChange(e);
                          }}
                        >
                          <option value="">{t("Common.PleaseSelect")}</option>
                          {Object.keys(reportingTypes).map((key) => (
                            <option key={key} value={key}>
                              {t(
                                `ExperimentPages.LaboratoryPages.${
                                  LaboratoryReportingType[
                                    key as keyof typeof LaboratoryReportingType
                                  ]
                                }`
                              )}
                            </option>
                          ))}
                        </select>
                        <span className="form-error-text">
                          {errors.reportingType &&
                            t(
                              "FormValidations.ExperimentPages.LaboratoryPages.ReportingTypeRequired"
                            )}
                        </span>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="text-end">
                        <Btn color="success" className="me-3">
                          {t("Common.add")}
                        </Btn>
                        <Link
                          className="btn btn-danger"
                          to={`${process.env.PUBLIC_URL}/experiments/laboratory/list`}
                        >
                          {t("Common.cancel")}
                        </Link>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
