import React, { useEffect, useState, forwardRef } from "react";
import {
  Card,
  CardBody,
  Col,
  Collapse,
  Container,
  Input,
  Label,
  Row,
} from "reactstrap";
import { Form as AntForm } from "antd";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Btn, LI, P, UL } from "AbstractElements";
import { toast } from "react-toastify";
import { SubmitHandler } from "react-hook-form";
import DataTable, { TableColumn } from "react-data-table-component";
import { defaultPageAndSize, Href } from "Utils/Constants";
import { Plus } from "react-feather";
import { InputMask } from "@react-input/mask";

// Services
import * as contactService from "Services/CrmServices/Contact";
import * as companyService from "Services/CrmServices/Company";

// Types
import { Contact } from "Types/Crm/Contact";

export default function CompanyContacts({ company }: any) {
  const { t } = useTranslation();
  const [form] = AntForm.useForm();

  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [tableData, setTableData] = useState(
    company?.contacts ? company.contacts : ([] as Contact[])
  );

  const handleFilterToggle = () => {
    setIsFilterOpen((prevState) => !prevState);
  };

  const handleCollapseCancel = () => {
    handleFilterToggle();
  };

  const addContact: SubmitHandler<any> = async (formData) => {
    const newData = {
      ...formData,
      company: {
        id: company?.id,
      },
    };
    const { data, error }: any = await contactService.addContact(newData);
    if (error) {
      toast.error(t(`ERRORS.${error?.errorCode}`));
    } else {
      toast.success(t("COMMON_MESSAGES.addedSuccessfully"));
      // setTableData([...tableData, formData]);
      getCompany(company.id);
      form.resetFields();
    }
  };

  const getCompany = async (id: any) => {
    const { data, error }: any = await companyService.getCompany(id);
    setTableData([...data?.body?.contacts]);
    if (error) toast.error(t(`ERRORS.${error?.errorCode}`));
  };

  const deleteContact = async (id: string) => {
    const { error }: any = await contactService.deleteContact(id);
    if (error) {
      toast.error(t(`ERRORS.${error?.errorCode}`));
    } else {
      toast.success(t("COMMON_MESSAGES.deletedSuccessfully"));
      setTableData(tableData.filter((item: any) => item.id !== id));
    }
  };

  const tableColumns: TableColumn<any>[] = [
    {
      name: t("Common.name"),
      selector: (row) => row.name,
      sortable: true,
      cell: (row) => (
        <P className="f-light-darker">{row.name ? row.name : "-"}</P>
      ),
    },
    {
      name: t("Common.Title"),
      selector: (row) => row.title,
      sortable: true,
      cell: (row) => (
        <P className="f-light-darker">{row.title ? row.title : "-"}</P>
      ),
    },
    {
      name: t("Common.Phone"),
      selector: (row) => row.phone,
      sortable: true,
      cell: (row) => (
        <P className="f-light-darker">{row.phone ? row.phone : "-"}</P>
      ),
    },
    {
      name: t("Common.Email"),
      selector: (row) => row.email,
      sortable: true,
      cell: (row) => (
        <P className="f-light-darker">{row.email ? row.email : "-"}</P>
      ),
    },
    {
      name: t("Common.Actions"),
      width: "80px",
      cell: (row) => (
        <>
          <UL className="action simple-list flex-row">
            <LI className="delete">
              <Link onClick={() => deleteContact(row.id)} to={""}>
                <i className="icon-trash" />
              </Link>
            </LI>
          </UL>
        </>
      ),
      sortable: false,
    },
  ];

  return (
    <div>
      <Container fluid className="add-contact-form">
        <Row>
          <Col sm={12}>
            <Card>
              <CardBody>
                <div className="light-box">
                  <div className="d-flex justify-content-between align-items-center">
                    <label>{t("CrmPages.Contacts")}</label>
                    <a href={Href} onClick={handleFilterToggle}>
                      {isFilterOpen ? (
                        <i className="icon-close filter-close" />
                      ) : (
                        <Plus />
                      )}
                    </a>
                  </div>
                </div>
                <Collapse className={isFilterOpen ? "show" : ""}>
                  <Card className="list-product-body">
                    <CardBody>
                      <AntForm
                        form={form}
                        className="theme-form"
                        onFinish={addContact}
                        autoComplete="off"
                        initialValues={{
                          name: "",
                          title: "",
                          phone: "",
                          email: "",
                        }}
                      >
                        <Row>
                          <Col md={3}>
                            <Label>{t("Common.FullName") + " *"}</Label>
                            <AntForm.Item
                              name="name"
                              validateTrigger={["onChange", "onBlur"]}
                              rules={[
                                {
                                  required: true,
                                  whitespace: true,
                                  message: t(
                                    "FormValidations.CrmPages.FullNameRequired"
                                  ),
                                },
                              ]}
                            >
                              <Input
                                placeholder={`${t(
                                  "CrmPages.ContactFullName"
                                )} *`}
                              />
                            </AntForm.Item>
                          </Col>
                          <Col md={3}>
                            <Label>{t("Common.Title")}</Label>
                            <AntForm.Item
                              name="title"
                              validateTrigger={["onChange", "onBlur"]}
                            >
                              <Input placeholder={`${t("Common.Title")}`} />
                            </AntForm.Item>
                          </Col>
                          <Col md={3}>
                            <Label>{t("Common.Phone") + " *"}</Label>
                            <AntForm.Item
                              name="phone"
                              validateTrigger={["onChange", "onBlur"]}
                              rules={[
                                {
                                  required: true,
                                  message: t(
                                    "FormValidations.CrmPages.PhoneRequired"
                                  ),
                                },
                              ]}
                            >
                              <InputMask
                                className="form-control"
                                type="text"
                                mask="+90 (___) ___-__-__"
                                replacement={{ _: /\d/ }}
                                placeholder="+90 (___) ___-__-__"
                                showMask
                                separate
                              />
                              {/* <Input placeholder={`${t("Common.Phone")} *`} /> */}
                            </AntForm.Item>
                          </Col>
                          <Col md={3}>
                            <Label>{t("Common.Email") + " *"}</Label>
                            <AntForm.Item
                              name="email"
                              validateTrigger={["onChange", "onBlur"]}
                              rules={[
                                {
                                  type: "email",
                                  message: t(
                                    "FormValidations.CrmPages.EmailValidType"
                                  ),
                                },
                                {
                                  required: true,
                                  message: t(
                                    "FormValidations.CrmPages.EmailRequired"
                                  ),
                                },
                              ]}
                            >
                              <Input placeholder={`${t("Common.Email")} *`} />
                            </AntForm.Item>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className="text-end">
                              <Btn color="outline-success" className="me-3">
                                {t("Common.Save")}
                              </Btn>
                              <Btn
                                color="outline-danger"
                                className="btn"
                                onClick={handleCollapseCancel}
                              >
                                {t("Common.cancel")}
                              </Btn>
                            </div>
                          </Col>
                        </Row>
                      </AntForm>
                    </CardBody>
                  </Card>
                </Collapse>
                <DataTable
                  data={tableData}
                  columns={tableColumns}
                  pagination={false}
                  noDataComponent={<>{t("Common.noData")}</>}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
