import React, { useEffect, useState } from "react";
import Breadcrumbs from "CommonElements/Breadcrumbs";
import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Label,
  Row,
} from "reactstrap";
import { Descriptions } from "antd";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Btn } from "AbstractElements";
import { toast } from "react-toastify";
import { SubmitHandler, useForm } from "react-hook-form";
import dayjs from "dayjs";

// Services
import * as debitService from "Services/DebitServices/Debit";

// Types
import { DebitType } from "Types/Debit/DebitType";

export default function UpdateDebit() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { id } = useParams<{ id: string | undefined }>();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<any>();

  const [debitDetails, setDebitDetails] = useState({} as DebitType);

  const updateDebit: SubmitHandler<any> = async (formData) => {
    const updatedData = {
      ...debitDetails,
      description: formData.description,
    };
    const { error }: any = await debitService.updateDebit(updatedData);
    navigate(`${process.env.PUBLIC_URL}/debit/main/list`);
    if (error) toast.error(t(`ERRORS.${error?.errorCode}`));
    else toast.success(t("COMMON_MESSAGES.updatedSuccessfully"));
  };

  const getDebitById = async () => {
    const { data, error }: any = await debitService.getDebitById(id);
    setDebitDetails(data?.body);
    if (error) toast.error(t(`ERRORS.${error?.errorCode}`));
  };

  const debitDetailItems = [
    {
      key: "1",
      label: t("Common.productType"),
      children: debitDetails?.productType,
    },
    {
      key: "2",
      label: t("Common.quantity"),
      children: debitDetails?.quantity,
    },
    {
      key: "3",
      label: t("Common.dateOfReceipt"),
      children: dayjs(debitDetails?.dateOfReceipt).format("DD-MM-YYYY HH:mm"),
    },
    {
      key: "4",
      label: t("Common.expectedDeliveryDate"),
      children: dayjs(debitDetails?.expectedDeliveryDate).format(
        "DD-MM-YYYY HH:mm"
      ),
    },
    {
      key: "5",
      label: t("Common.returnDate"),
      children: dayjs(debitDetails?.returnDate).format("DD-MM-YYYY HH:mm"),
    },
    {
      key: "6",
      label: t("Common.maintenance"),
      children: (
        <div className="d-flex align-items-center">
          {debitDetails?.maintenance ? (
            <i className="icon-check-box text-success"></i>
          ) : (
            <i className="icon-close text-danger"></i>
          )}
        </div>
      ),
    },
  ];

  useEffect(() => {
    getDebitById();
  }, [id]);

  return (
    <div className="page-body">
      <Breadcrumbs
        pageTitle={t("DebitPages.EditDebit")}
        parent={t("DebitPages.Debit")}
        subParent={t("DebitPages.DebitPageTitle")}
        subParentLink={`${process.env.PUBLIC_URL}/debit/main/list`}
        title={t("DebitPages.EditDebit")}
      />
      <Container fluid>
        <Row>
          <Col sm={12}>
            <Card>
              <CardBody>
                <Descriptions items={debitDetailItems} className="mb-4" />
                <Form
                  className="theme-form"
                  onSubmit={handleSubmit(updateDebit)}
                >
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>{t("Common.description")}</Label>
                        <textarea
                          className="form-control"
                          cols={30}
                          rows={5}
                          defaultValue={debitDetails?.description}
                          {...register("description", { required: false })}
                        ></textarea>
                        <span className="form-error-text">
                          {errors.features &&
                            t("FormValidations.DebitPages.DescriptionRequired")}
                        </span>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="text-end">
                        <Btn color="success" className="me-3">
                          {t("Common.update")}
                        </Btn>
                        <Link
                          className="btn btn-danger"
                          to={`${process.env.PUBLIC_URL}/debit/main/list`}
                        >
                          {t("Common.cancel")}
                        </Link>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
