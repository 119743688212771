import React, { useEffect, useState } from "react";
import Breadcrumbs from "CommonElements/Breadcrumbs";
import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Label,
  Row,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Btn } from "AbstractElements";
import { toast } from "react-toastify";
import { SubmitHandler, useForm } from "react-hook-form";

// Services
import * as brandService from "Services/DebitServices/Brand";

// Type
import { BrandType } from "Types/Debit/BrandType";

export default function UpdateBrand() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { id } = useParams<{ id: string | undefined }>();

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
  } = useForm<any>();

  const [brandDetails, setBrandDetails] = useState({} as BrandType);

  const updateBrand: SubmitHandler<any> = async (formData) => {
    const updatedData = {
      ...brandDetails,
      name: formData.name,
    };
    const { error }: any = await brandService.updateBrand(updatedData);
    navigate(`${process.env.PUBLIC_URL}/debit/brand/list`);
    if (error) toast.error(t(`ERRORS.${error?.errorCode}`));
    else toast.success(t("COMMON_MESSAGES.updatedSuccessfully"));
  };

  const getBrandById = async () => {
    const { data, error }: any = await brandService.getBrandById(id);
    setBrandDetails(data?.body);
    setValue("name", data?.body?.name);
    if (error) toast.error(t(`ERRORS.${error?.errorCode}`));
  };

  useEffect(() => {
    getBrandById();
  }, [id]);

  return (
    <div className="page-body">
      <Breadcrumbs
        pageTitle={t("DebitPages.EditBrand")}
        parent={t("DebitPages.Debit")}
        subParent={t("DebitPages.BrandPageTitle")}
        subParentLink={`${process.env.PUBLIC_URL}/debit/brand/list`}
        title={t("DebitPages.EditBrand")}
      />
      <Container fluid>
        <Row>
          <Col sm={12}>
            <Card>
              <CardBody>
                <Form
                  className="theme-form"
                  onSubmit={handleSubmit(updateBrand)}
                >
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>{t("Common.name")}</Label>
                        <input
                          className="form-control"
                          type="text"
                          defaultValue={brandDetails?.name}
                          placeholder={`${t("DebitPages.Brand.brandName")} *`}
                          {...register("name", {
                            validate: (value) =>
                              value !== "" || getValues("name") !== "",
                          })}
                        />
                        <span className="form-error-text">
                          {errors.name &&
                            t("FormValidations.DebitPages.NameRequired")}
                        </span>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="text-end">
                        <Btn color="success" className="me-3">
                          {t("Common.update")}
                        </Btn>
                        <Link
                          className="btn btn-danger"
                          to={`${process.env.PUBLIC_URL}/debit/brand/list`}
                        >
                          {t("Common.cancel")}
                        </Link>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
