import React, { useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Collapse,
  Container,
  Input,
  Label,
  Row,
} from "reactstrap";
import { Form as AntForm } from "antd";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Btn, LI, P, UL } from "AbstractElements";
import { toast } from "react-toastify";
import { SubmitHandler } from "react-hook-form";
import DataTable, { TableColumn } from "react-data-table-component";
import { Href } from "Utils/Constants";
import { Plus } from "react-feather";
import { InputMask } from "@react-input/mask";

// Services
import * as bankService from "Services/CrmServices/Bank";
import * as companyService from "Services/CrmServices/Company";

// Types
import { Bank } from "Types/Crm/Bank";

export default function CompanyBanks({ company }: any) {
  const { t } = useTranslation();
  const [form] = AntForm.useForm();

  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [tableData, setTableData] = useState(
    company?.banks ? company.banks : ([] as Bank[])
  );

  const handleFilterToggle = () => {
    setIsFilterOpen((prevState) => !prevState);
  };

  const handleCollapseCancel = () => {
    handleFilterToggle();
  };

  const addBank: SubmitHandler<any> = async (formData) => {
    const newData = {
      ...formData,
      company: {
        id: company?.id,
      },
    };
    const { data, error }: any = await bankService.addBank(newData);
    if (error) {
      toast.error(t(`ERRORS.${error?.errorCode}`));
    } else {
      toast.success(t("COMMON_MESSAGES.addedSuccessfully"));
      // setTableData([...tableData, formData]);
      // setTableData([...tableData, data?.body]);
      getCompany(company.id);
      form.resetFields();
    }
  };

  const getCompany = async (id: any) => {
    const { data, error }: any = await companyService.getCompany(id);
    setTableData([...data?.body?.banks]);
    if (error) toast.error(t(`ERRORS.${error?.errorCode}`));
  };

  const deleteBank = async (id: string) => {
    const { error }: any = await bankService.deleteBank(id);
    if (error) {
      toast.error(t(`ERRORS.${error?.errorCode}`));
    } else {
      toast.success(t("COMMON_MESSAGES.deletedSuccessfully"));
      setTableData(tableData.filter((item: any) => item.id !== id));
    }
  };

  const tableColumns: TableColumn<any>[] = [
    {
      name: t("Common.name"),
      selector: (row) => row.name,
      sortable: true,
      cell: (row) => (
        <P className="f-light-darker">{row.name ? row.name : "-"}</P>
      ),
    },
    {
      name: t("CrmPages.IbanNumber"),
      selector: (row) => row.ibanNumber,
      sortable: true,
      cell: (row) => (
        <P className="f-light-darker">
          {row.ibanNumber ? row.ibanNumber : "-"}
        </P>
      ),
    },
    {
      name: t("Common.Actions"),
      width: "80px",
      cell: (row) => (
        <>
          <UL className="action simple-list flex-row">
            <LI className="delete">
              <Link onClick={() => deleteBank(row.id)} to={""}>
                <i className="icon-trash" />
              </Link>
            </LI>
          </UL>
        </>
      ),
      sortable: false,
    },
  ];

  return (
    <div>
      <Container fluid className="add-contact-form">
        <Row>
          <Col sm={12}>
            <Card>
              <CardBody>
                <div className="light-box">
                  <div className="d-flex justify-content-between align-items-center">
                    <label>{t("Common.bankInfo")}</label>
                    <a href={Href} onClick={handleFilterToggle}>
                      {isFilterOpen ? (
                        <i className="icon-close filter-close" />
                      ) : (
                        <Plus />
                      )}
                    </a>
                  </div>
                </div>
                <Collapse className={isFilterOpen ? "show" : ""}>
                  <Card className="list-product-body">
                    <CardBody>
                      <AntForm
                        form={form}
                        className="theme-form"
                        onFinish={addBank}
                        autoComplete="off"
                        initialValues={{
                          name: "",
                          ibanNumber: "",
                        }}
                      >
                        <Row>
                          <Col md={6}>
                            <Label>{t("CrmPages.BankName") + " *"}</Label>
                            <AntForm.Item
                              name="name"
                              validateTrigger={["onChange", "onBlur"]}
                              rules={[
                                {
                                  required: true,
                                  whitespace: true,
                                  message: t(
                                    "FormValidations.CrmPages.BankNameRequired"
                                  ),
                                },
                              ]}
                            >
                              <Input
                                placeholder={`${t("CrmPages.BankName")} *`}
                              />
                            </AntForm.Item>
                          </Col>
                          <Col md={6}>
                            <Label>{t("CrmPages.IbanNumber") + " *"}</Label>
                            <AntForm.Item
                              name="ibanNumber"
                              validateTrigger={["onChange", "onBlur"]}
                              rules={[
                                {
                                  required: true,
                                  whitespace: true,
                                  message: t(
                                    "FormValidations.CrmPages.IbanNumberRequired"
                                  ),
                                },
                              ]}
                            >
                              <InputMask
                                className="form-control"
                                type="text"
                                mask="SS0000000000000000000000"
                                replacement={{
                                  S: /T|R/,
                                  0: /[0-9]/,
                                }}
                                placeholder="TR000000000000000000000000"
                                showMask
                              />
                              {/* <Input
                                placeholder={`${t("CrmPages.IbanNumber")} *`}
                                maxLength={26}
                              /> */}
                            </AntForm.Item>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className="text-end">
                              <Btn color="outline-success" className="me-3">
                                {t("Common.Save")}
                              </Btn>
                              <Btn
                                color="outline-danger"
                                className="btn"
                                onClick={handleCollapseCancel}
                              >
                                {t("Common.cancel")}
                              </Btn>
                            </div>
                          </Col>
                        </Row>
                      </AntForm>
                    </CardBody>
                  </Card>
                </Collapse>
                <DataTable
                  data={tableData}
                  columns={tableColumns}
                  pagination={false}
                  noDataComponent={<>{t("Common.noData")}</>}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
