export const Href: string = "#Javascript";
export const FooterText: string =
  "Copyright 2024 © Portlab.";

export const dateFormats = {
  Y_M_D: "YYYY-MM-DD",
  D_M_Y: "DD-MM-YYYY",
  Y_M: "YYYY-MM",
  M_Y: "MM-YYYY",
  Y_M_D_H_m: "YYYY-MM-DD HH:mm",
  D_M_Y_H_m: "DD-MM-YYYY HH:mm",
  Y_M_D_H_m_s: "YYYY-MM-DD HH:mm:ss",
  D_M_Y_H_m_s: "DD-MM-YYYY HH:mm:ss",
  D_M_Y_H_m_s_T: "YYYY-MM-DDTHH:mm:ss",
  D_M_Y_H_m_s_Z: "YYYY-MM-DDTHH:mm:ss[Z]",
  D_M_Y_H_m_s_S: "YYYY-MM-DDTHH:mm:ss.SSSSSS",
};

export const documentFileAccept = ".doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.xls,.xlsx,.csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,.ppt,.pptx,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation,.pdf";

export const defaultPageAndSize = {
  page: 0,
  size: 10,
  sort: ["createdDate,DESC"]
};

export const defaultPageAndSizeForAll = {
  page: 0,
  size: 2147483647
};

export const shortMonthListTr = ['Ocak', 'Şub', 'Mar', 'Nis', 'May', 'Haz', 'Tem', 'Ağu', 'Eyl', 'Ekim', 'Kas', 'Ara'];
export const shortMonthListEn = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export const Pinned: string = "Pinned";
export const Emptysearch: string = "Opps!! There are no result found.";
export const Notitications: string = "Notitications";
export const DeliveryProcessing: string = "Delivery processing";
export const OrderComplete: string = "Order Complete";
export const TicketsGenerated: string = "Tickets Generated";
export const DeliveryComplete: string = "Delivery Complete";
export const Checkall: string = "Check all";
export const Cart: string = "Cart";
export const SubTotal: string = "Subtotal :";
export const ViewCart: string = "View Cart";
export const Checkout: string = "Checkout";
export const Message: string = "Message";
export const HelenWalter: string = "Helen Walter";
export const Admin: string = "Admin";
export const Logout: string = "Logout";
export const Bookmark: string = "Bookmark";
export const AddNewBookmark: string = "Add New Bookmark";
export const Back: string = "Back";

// Ui -kits Typography
export const UiKits: string = "Ui Kits";
export const TypographyTitle: string = "Typography";
export const HeadingsTitle: string = "Headings";
export const rem25: string = "2.5rem";
export const rem2: string = "2rem";
export const rem175: string = "1.75rem";
export const rem15: string = "1.5rem";
export const rem125: string = "1.25rem";
export const rem1: string = "1rem";
export const h1: string = "<h1></h1>";
export const h2: string = "<h2></h2>";
export const h3: string = "<h3></h3>";
export const h4: string = "<h4></h4>";
export const h5: string = "<h5></h5>";
export const h6: string = "<h6></h6>";
export const Heading1MEGA: string = " Heading 1 (MEGA)";
export const Heading2XL: string = " Heading 2 (XL)";
export const Heading3LARGE: string = "Heading 3 (LARGE)";
export const Heading4MEDIUM: string = "Heading 4 (MEDIUM)";
export const Heading5SMALL: string = "Heading 5 (SMALL)";
export const Heading6: string = "Heading 6";
export const ColoredHeadingTitle: string = "Colored headings";
export const Heading1: string = "Heading 1";
export const SubHeading: string = " Sub Heading";
export const FontWeightTitle: string = "Font weight";
export const Bolder: string = " You can set bolder font weight Heading 1 using";
export const BoldFont: string = " You can set bold font weight Heading 2 using";
export const Mediumbold: string =
  "You can set medium font weight Heading 3 using";
export const Normalfont: string =
  "You can set normal font weight Heading 4 using";
export const Lightfont: string =
  "You can set light font weight Heading 5 using";
export const fw700: string = "f-w-700";
export const fw600: string = "f-w-600";
export const fw500: string = "f-w-500";
export const fw400: string = "f-w-400";
export const fw300: string = "f-w-300";
export const ListingTypography: string = "Listing Typography";
export const UnorderListTitle: string = "Unorder list";
export const OrderListTitle: string = "Order list";
export const UIKits: string = "Ui Kits";
export const BonusUi: string = "Bonus Ui";
export const Animations: string = "Animations";
export const Typography: string = "Typography";
export const Avatars: string = "Avatars";
export const Grid: string = "Grid";
export const Tagpills: string = "Tag & pills";
export const Alert: string = "Alert";
export const Dropdown: string = "Dropdown";
export const Tabs: string = "Tabs";
export const Accordion: string = "Accordion";
export const DescriptionListTitle: string = "Description list";
export const Display: string = "Display";
export const DisplayHeadingTitle: string = "Display headings";
export const DisplayHeadingSmall: string = "display heading";
export const DisplayText2: string =
  "—a larger, slightly more opinionated heading style.";
export const Inlinetextelements: string = "Inline text elements";
export const DeletText: string =
  "This line of text is meant to be treated as deleted text.";
export const SmallText: string =
  "This line of text is meant to be treated as no longer accurate.";
export const InerText: string =
  "This line of text is meant to be treated as an addition to the document.";
export const UnderlineText: string =
  "This line of text will render as underlined";
export const ExtraSmalltext: string =
  "This line of text is meant to be treated as fine print.";
export const StrongText: string = "This line rendered as bold text.";
export const ItalicText: string = "This line rendered as italicized text.";
export const MarkText: string = "You can use the mark tag to";
export const highlight: string = "highlight";
export const textd: string = " text.";
export const TextColorTitle: string = "Text Color";
export const BlockquotesTitle: string = "Blockquotes";

//Avatars
export const AvatarsTitle: string = "Avatars";
export const Sizes: string = "Sizes";
export const StatusIndicatorTitle: string = "Status Indicator";
export const ShapesTitle: string = "Shapes";
export const RatioTitle: string = "Ratio";
export const GroupingTitle: string = "Grouping";
//Helper Classes
export const HelperClassTitle: string = "Helper Classes";
export const BorderStylesTitle: string = "Styles in borders";
export const CustomBorderTitle: string = "Custom border-radius class";
export const BorderColorTitle: string = "Border color";
export const BorderWidthTitle: string = "Border-width";
export const TextColorsTitle: string = "Text colors";
export const BordersAndDisplaysTitle: string = "Borders and displays";
export const AdditiveBorderTitle: string = "Additive border";
export const SubtractiveBorderTitle: string = "Subtractive border";
export const AdditiveRadiusTitle: string = "Additive Radius";
export const DarkBackgroundTitle: string = "Dark background";
export const LightBackgroundsTitle: string = "Light backgrounds";
export const ExtendedBackgroundTitle: string = "Extended background colors";
export const LightCard: string = ".light-card";
export const LightBackground: string = ".light-background";
export const ImageSizeTitle: string = "Image size";
export const FontStyleTitle: string = "Font-style";
export const FontSizeTitle: string = "Font size";

//Bonus Ui
export const BonusUiTitle: string = "Bonus Ui";
export const ScrollableTitle: string = "Scrollable";
export const CustomScrollbarTitle: string = "Custom Scrollbar";
export const CustomScroll: string = "custom scrollbars";
export const HorizontalVertical: string = "horizontally or vertically";
export const SmallSizeScrollTitle: string = "Small Size Scrollbar";
export const SmallScrollText: string = "Solution for Business Transformation";
export const BadgesScrollbarTitle: string = "Badges Scrollbar";
export const ProfileScrollableTitle: string = "Profile Scrollable";
export const ScrollableContentTitle: string = "Scrollable Content";
export const HorizontalScrollbarTitle: string = "Horizontal Scrollbar";
export const BothSideScrollbarTitle: string = "Both Side Visible Scrollbar";

//Tree View
export const TreeViewTitle: string = "Tree View";
export const BasicTreeTitle: string = "Basic Tree";
export const DisabledTreeTitle: string = "Disabled Tree";

//Toasts
export const ToastsTitle: string = "Toasts";
export const LiveToastTitle: string = "Live Toast";
export const TopRightToastTitle: string = "Top-right toast";
export const LiveToastBodyText: string = "Hello, I'm a web-designer.";
export const PortlabTheme: string = "Portlab Theme";
export const BottomRightToastTitle: string = "Bottom-right toast";
export const BottomRightToastBodyText: string =
  "Your time over after 5 minute.";
export const TopLeftToastTitle: string = "Top-left toast";
export const BottomLeftToastTitle: string = "Bottom-left toast";
export const ColorsSchemesTitle: string = "Colors Schemes";
export const StackingToastsTitle: string = "Stacking Toasts";
export const TranslucentToastsTitle: string = "Translucent Toasts";
export const DefaultToastTitle: string = "Default Toast";
export const UniqueToastTitle: string = "Unique Toast";
export const ToastPlacementTitle: string = "Toast Placement";
//Bootstrap Tabs
export const TabsTitle: string = "Tabs";
export const BootstrapTabs: string = "Bootstrap Tabs";
export const SimpleTabTitle: string = "Simple tabs";
export const Home: string = "Home";
export const Contact: string = "Contact";
export const Profile: string = "Profile";
export const IconTabsTitle: string = "Icons With Tabs";
export const VerticalTabTitle: string = "Vertical Tabs";
export const Components: string = "Components";
export const Settings: string = "Settings";
export const Pages: string = "Pages";
export const PillsTabTitle: string = "Pills tabs";
export const AboutUs: string = "About us";
export const ContactUs: string = "Contact us";
export const Blog: string = "Blog";
export const JustifyTabTitle: string = "Justify Tabs";
export const PortlabProfile: string = "Portlab Profiles For New Employees:";
export const WebDesigner: string = "Web designer";
export const IotDeveloper: string = "IOT developer";
export const MaterialLeftStyleTabTitle: string = "Material Style Left Tabs";
export const MaterialStyleTabTitle: string = "Material Style Tabs";
export const User: string = "User";
export const Description: string = "Description";
export const Review: string = "Review";
export const BorderTabsTitle: string = "Border Tabs";

//Lists
export const ListsTitle: string = "Lists";
export const DefaultListTitle: string = "Default lists";
export const LogoDesign: string = "Logo Design";
export const WebDesignDevelop: string = "Web Design & Development ";
export const ECommerce: string = "E-Commerce";
export const SEO: string = "SEO";
export const ActiveListTitle: string = "Active lists";
export const WowAnimations: string = "Wow Animations";
export const ApexCharts: string = "Apex Charts";
export const StarterKits: string = "Starter Kits";
export const FlushListTitle: string = "Flush lists";
export const PRODUCT: string = "PRODUCT";
export const PRODUCTDETAILS: string = "PRODUCT DETAILS ";
export const CART: string = "CART ";
export const CHECKOUT: string = "CHECKOUT";
export const ContextualClassTitle: string = "Contextual classes";
export const HorizontalListTitle: string = "Horizontal lists";
export const CustomListTitle: string = "Custom content lists";
export const ListWithCheckboxTitle: string = "Lists With Checkbox";
export const ListWithRadioTitle: string = "Lists With Radios";
export const ListWithNumberTitle: string = "Lists With Numbers";
export const JsBehaviorTitle: string = "JavaScript Behavior";
export const NumberedBadgeListTitle: string = "Numbered & Badge Lists";
export const DisableListTitle: string = "Disabled lists";
export const ScrollableListTitle: string = "Scrollable lists";
//Dropdown
export const DropdownTitle: string = "Dropdown";
export const BasicDropdownTitle: string = "Basic Dropdown";
export const Project: string = "Project";
export const Ecommerce: string = "Ecommerce";
export const Crypto: string = "Crypto";
export const Dashboard: string = "Dashboard";
export const RoundedDropdownTitle: string = "Rounded Dropdown";
export const Primary: string = "Primary";
export const Dark: string = "Dark";
export const Light: string = "Light";
export const Lighter: string = "Lighter";
export const SplitDropdownTitle: string = "Split Dropdown";
export const Widgets: string = "Widgets";
export const General: string = "General";
export const Chart: string = "Chart";
export const HeadingDropdownTitle: string = "Heading Dropdown";
export const Party: string = "Party";
export const PartyList: string = "Party List";
export const Balloons: string = "Balloons";
export const Cake: string = "Cake";
export const DropdownWithInputTitle: string = "With Input Type";
export const Inputs: string = "Inputs";
export const DefaultCheckbox: string = "Default checkbox";
export const DefaultRadio: string = "Default radio";
export const DarkNight: string = "Dark Night";
export const DarkMoon: string = "Dark moon";
export const DarkOwl: string = "Dark owl";
export const NightFall: string = "Nightfall";
export const DarkDropdownTitle: string = "Dark Dropdown";
export const UniqueDropdownTitle: string = "Unique Dropdown";
export const DropdownFormText: string = "Dropdown form";
export const RememberMe: string = "Remember me";
export const EmailAddress: string = "Email address";
export const Password: string = "Password";
export const SignIn: string = "Sign in";
export const Text: string = "Text";
export const TextBody: string =
  "Some example text that's free-flowing within the dropdown menu.";
export const TextBody2: string = "And this is more example text. ";
export const JustifyContentTitle: string = "Justify Content";
export const TextLeft: string = "Text-left";
export const Hello: string = "Hello..";
export const HowAreYou: string = "How are you?";
export const WhatYouDoing: string = "What are you doing?";
export const AlignmentsTitle: string = "Alignments";
export const WarningTop: string = "Warning top";
export const Becareful: string = "Be careful ";
export const Notifications: string = "Notifications";
export const Beware: string = "Beware ";
export const HelperCardTitle: string = "Helper Card";
export const DividerDropdownTitle: string = "Divider Dropdown";
export const DropdownSizingTitle: string = "Dropdown Sizing";
export const LargeButton: string = "Large button";
export const SmallButton: string = "Small button";
export const MediumButton: string = "Medium button";
export const VeryLargeButton: string = "Very large button";
export const VerySmallButton: string = "Very small button";
export const ExtraSmallButton: string = "Extra small button";
export const ExtraExtraSmall: string = "Extra extra small";

//Accordion
export const AccordionTitle: string = "Accordion";
export const SimpleAccordionTitle: string = "Simple Accordion";
export const WebDesignersDo: string = "What do web designers do?";
export const UseWebDesign: string = "What is the use of web design?";
export const ElementsWebDesign: string = "What are the elements of web design?";
export const FlushAccordionTitle: string = "Flush Accordion";
export const MultipleCollapseTitle: string = "Multiple Collapse Accordion";
export const ToggleFirst: string = "Toggle first element";
export const ToggleSecond: string = "Toggle second element";
export const ToggleBoth: string = "Toggle both elements";
export const IconAccordionTitle: string = "With Icons Accordion";
export const HorizontalAccordionTitle: string = "Horizontal Accordion";
export const ToggleWidthCollapse: string = "Toggle width collapse";
export const CollapesAccordionTitle: string = "Collapse Accordion";
export const LinkWithHref: string = "Link with event";
export const ButtonWithEvent: string = "Button with event";

//Modal
export const ModalTitle: string = "Modal";
export const BasicModalTitle: string = "Basic Modal";
export const Close: string = "Close";
export const SaveChanges: string = "Save Changes";
export const Simple: string = "Simple";
export const Upto: string = "Up to ";
export const SimpleText: string = ", Hurry Up Online Shopping";
export const ExploreMore: string = "Explore More";
export const Scrollingcontent: string = "Scrolling content";
export const WedDesigner: string = "Wed Designer";
export const UXDesigner: string = "UX Designer";
export const TooltipsPopovers: string = "Tooltips and popovers";
export const PopoverModal: string = "Popover in a modal";
export const This: string = "This ";
export const TriggerText: string = " triggers a popover on click.";
export const ThisLink: string = "This link";
export const And: string = "and";
export const ThatLink: string = "that link";
export const HoverText: string = " have tooltips on hover.";
export const TooltipButtonText: string = "this is amazing content";
export const TooltipsModal: string = "Tooltips in a modal";
export const OpenModalPortlab: string = "Open modal for Portlab";
export const PortlabSignUp: string = "Portlab SIGN-UP";
export const FirstName: string = "First name";
export const LastName: string = "Last name";
export const CheckText: string =
  "You accept our Terms and Privacy Policy by clicking Submit below.";
export const SignUp: string = "Sign Up";
export const LooksGood: string = "Looks good!";
export const SizeModalTitle: string = "Sizes Modal";
export const FullScreenModal: string = "Full Screen Modal";
export const Fullscreenmodal: string = "Fullscreen modal";
export const ExtraLargeModal: string = "Extra large Modal";
export const LargeModalButton: string = "Large Modal";
export const SmallModalButton: string = "Small Modal";
export const StartYourGoals: string = "Start with your goals";
export const FullscreenModalTitle: string = "Fullscreen Modal";
export const WebDesign: string = "Web Design";
export const FullscreenBelowSm: string = "Fullscreen below sm";
export const FullscreenBelowMd: string = "Fullscreen below md";
export const FullscreenBelowLg: string = "Fullscreen below lg";
export const FullscreenBelowXl: string = "Fullscreen below xl";
export const FullscreenBelowXxl: string = "Fullscreen below xxl";

export const VerticallyCentered: string = "Vertically centered";
export const CenteredModalTitle: string = "Centered modal";
export const CenteredError: string = "Ohh! Something went wrong!";
export const ToggleModalTitle: string = "Toggle between modals";
export const OpenFirstModal: string = "Open first modal";
export const RemoveAccountText: string =
  "Remove your complete account from your phone or tablet to sign out of the Gmail app.";
export const ConnectNewAccount: string = "Connect new account";
export const Cancel: string = "Cancel";
export const NotMember: string = " Not a member?";
export const Register: string = "Register";
export const YesLogout: string = "Yes, Log out";
export const LogoutText: string = "Are you sure want to logout this dashboard?";
export const AlreadyLeaving: string = "Already leaving??";
export const StaticModalTitle: string = "Static Backdrop Modal";
export const StaticBackdropModal: string = "Static backdrop modal";
export const PortlabLogin: string = "Portlab Login";
export const PortlabLoginText: string =
  "Fill in your information below to continue.";
export const Email: string = "Email";
export const CheckMeOut: string = "Check me out";
export const CustomModalsTitle: string = "Portlab custom modals";
export const ClickOut: string = "Click Out";
export const Modal1Text: string = "Modal 1 - ";
export const Modal2text: string = "Modal 2 - ";
export const Modal3Text: string = "Modal 3 - ";
export const ResultModal: string = "Result Modal";
export const ProfileModal: string = "Profile Modal";
export const BalanceModal: string = "Balance Modal";
export const BalanceText: string = "Example of Portlab dashboard balance card.";
export const TextModal: string = "Example of Portlab dashboard profile card.";
export const ResultText: string = "Example of Portlab login form.";
export const BrooklynSimmons: string = "Brooklyn Simmons";
export const BrooklynSimmonEmail: string = "@brookly.simmons";
export const YourBalance: string = "Your Balance";
export const YourTotalBalance = "768,987.90";
export const CashBackText: string = "Cash Back";
export const InvestmentConstant: string = "Investment";

//Alert
export const AlertTitle: string = "Alert";
export const LinkColorTitle: string = "Link Color In Dark Theme";
export const ThisIsA: string = "This is a";
export const WithExampleLinkCheck: string =
  "with an example link.Check it out.";
export const LinkLightColorTitle: string = "Link Color In Light Theme";
export const Oneof: string = "One of ";
export const YouTubes: string = "YouTube's";
export const LightOutlineText: string =
  " most crucial ranking factors is Watch Time.";
export const Welldone: string = "Well done! ";
export const DarkOutlineText: string =
  "You successfully read this important message.";
export const AlertWithIconsTitle: string = "Alerts With Icons and Text Actions";
export const YourTime: string = "Your time Over after ";
export const Minute: string = "minute";
export const Check: string = "Check";
export const HertText: string = "Oh snap! Change a few things up ";
export const NotificationCheck: string = " Notification check";
export const DismissingDarkAlertTitle: string = "Dismissing Dark Alerts";
export const DismissingLightAlertTitle: string = "Dismissing Light Alerts";
export const LiveAlertTitle: string = "Live Alert";
export const ShowLiveAlert: string = "Show live alert";
export const LiveAlertText: string = "Nice, you triggered this alert message!";
export const LeftBorderAlertTitle: string = "Left Border Alert";
export const LeftBarText: string =
  "You can check in on some of those fields below.";
export const AdditionalAlertTitle: string = "Additional content";

//Popover
export const PopoverTitle: string = "Popover";
export const HurryUp: string = "Hurry Up!";
export const BasicPopoverTitle: string = "Basic Popover";
export const PopoverDirectionTitle: string = "Popover Direction";
export const PopoverOnTop: string = "Popover on top";
export const PopoverOnTopText: string =
  "Popovers need the tooltip plugin considering that a dependency.";
export const PopoverOffsetTitle: string = "Popover Offset";
export const Popoveroffset50: string = "Popover offset 50";
export const Popovertitle: string = "Popover title";
export const PopoverOffsetText: string =
  "And here's some amazing content. It's very engaging. Right?";
export const PopoveroffsetDash50: string = "Popover offset -50";
export const PopoverOnLeft: string = "Popover On Left";
export const PopoverOnLeftText: string =
  "Popovers are opt-in for effectiveness causes, in this way you have to initialize them yourself.";

//Tooltip
export const TooltipTitle: string = "Tooltip";
export const BasicTooltipTitle: string = "Basic Tooltip";
export const BasicTooltipText: string = "It's magic please hover me... ";
export const SurpriseText: string = "Surprise!!! Thank you for hovering...";
export const InlineTooltipContent: string = "Inline Tooltip Content";
export const InlineTooltipText: string =
  "Here, is some content about tooltips that you can set the";
export const Tooltip: string = "tooltip";
export const InsideText: string =
  "inside the content with help of tooltip and also you can add";
export const ColoredTooltipTitle: string = "Colored Tooltip";
export const TooltipDirectionTitle: string = "Tooltip Directions";
export const HtmlTooltipTitle: string = "HTML Elements With Hover Effect";
export const FilledTooltipTitle: string = "Filled Tooltip";
// Grid
export const GridTitle: string = "Grid";
export const GridOptionsTitle: string = "GridOptions";
export const Gridbehavior: string = "Grid behavior";
export const Horizontaltimes: string = "Horizontal at all times";
export const Collapsedtostart: string =
  "Collapsed to start, horizontal above breakpoints";
export const Maxcontainerwidth: string = "Max container width";
export const None: string = "None (auto)";
export const px540: string = "540px";
export const px720: string = "720px";
export const px960: string = "960px";
export const px1140: string = "1140px";
export const px1320: string = "1320px";
export const Classprefix: string = "Class prefix";
export const col: string = ".col-";
export const colsm: string = ".col-sm-";
export const colmd: string = ".col-md-";
export const collg: string = ".col-lg-";
export const colxl: string = ".col-xl-";
export const colxxl: string = ".col-xxl-";
export const ofcolumns: string = "# of columns";
export const Gutterwidth: string = "Gutter width";
export const Gutterwidthno: string = "1.5rem (.75rem on left and right)";
export const Nestable: string = "Nestable";
export const Yes: string = "Yes";
export const Offsets: string = "Offsets";
export const Columnordering: string = "Column ordering";
export const GridColumnTitle: string = "Grid for column";
export const Class: string = "Class";
export const ValueClass: string = "Value of the class";
export const VerticalClass: string = ".align-items-*";
export const VerticalPosition: string =
  " start / center / end / baseline / stretch";
export const VerticalAlignmentTitle: string = "Vertical alignment";
export const onecolumn: string = "one column";
export const Onecolumn: string = "One column";
export const twocolumn: string = "two column";
export const Twocolumn: string = "Two column";
export const HorizontalAlignmentTitle: string = "Horizontal alignment";
export const HorizontalClass: string = ".justify-content-*";
export const HorizontalPosition: string =
  "start / center / end / around / between";
export const Level1col3: string = "Level 1: .col-3";
export const Level2col5: string = "Level 2: .col-5";
export const Level2col7: string = "Level 2: .col-7";
export const Level1col2: string = "Level 1: .col-2";
export const Level1col10: string = "Level 1: .col-10";
export const Level2col4: string = "Level 2: .col-4";
export const OrderTitle: string = "Order";
export const OrderClass: string = ".order-*";
export const OrderPosition: string = " first / 2 / 3 / 4 / 5 / last";
export const OffsetTitle: string = "Offset";
export const OffsetClass: string = ".offset-*";
export const OffsetStep: string = " 1 / 2 / ... / 11 / 12";

//Tag & Pills
export const TagAndPillsTitle: string = "Tag & Pills";
export const BadgesContextualTitle: string = "Badges Contextual Variations";
export const PillsContextualTitle: string = "Pills Contextual Variations";
export const NumberofBadge: string = "Number of Badge";
export const NumberofPills: string = "Number of Pills Tags";
export const BadgeIcons: string = "Badge Tags With Icons";
export const PillsWithIcons: string = "Rounded Pills With Icons";
export const BadgeHeadingsExample: string = "Badge Headings Example";
export const BadgeHeading: string = "Badge Heading";
export const Latest: string = "Latest";
export const Trending: string = "Trending";
export const Inbox: string = "Inbox";
export const Login: string = "Login";
export const BadgesPartButtons: string = "Badges As Part Buttons";

//Progress
export const ProgressTitle: string = "Progress";
export const BasicProgressBars: string = "Basic Progress Bars";
export const ProgressStriped: string = "Progress Bars Striped";
export const ProgressAnimated: string = "Progress Bars animated";
export const MultipleBarsTitle: string = "Multiple bars";
export const ProgressWithNumberTitle: string = "Progress With Number Steps";
export const CustomProgressTitle: string = "Custom Progress Bars";
export const SmallProgressTitle: string = "Small Progress Bars";
export const LargeProgressTitle: string = "Large Progress Bars";
export const CustomHeightProgressTitle: string = "Custom Height Progress Bars";

//Ecommerce
//Product Page
export const ProductPageTitle: string = "Product Page";
export const Dollar: string = "$";
export const WomenPinkShirt: string = "Women Pink shirt.";
export const ShareIt: string = "share it";
export const RateNow: string = "Rate Now";
export const AddToCart: string = "Add To Cart";
export const BuyNow: string = "Buy Now";
export const AddToWishList: string = "Add To WishList";
export const Brand: string = "Brand";

//Product List
export const ProductListTitle: string = "Product list";

//Payment Details
export const PaymentDetailsTitle: string = "Payment Details";
export const CreditCardTitle: string = "Credit card";
export const DebitCardTitle: string = "Debit card";
export const ExpirationDate: string = "Expiration Date";
export const CodTitle: string = "COD";
export const EmiTitle: string = "EMI";
export const NetBankingTitle: string = "Net Banking";

//Order History
export const InStock: string = "In stock";
export const DatatableOrderHistoryTitle: string = "Datatable order history";

// Add-Product
export const AddProductTitle: string = "Add-Product";
export const ProductForm: string = "Product Form";
export const ProductTitleLabel: string = "Product Title";
export const AddCategory: string = "Add Category";
export const CreateNewCategory: string = "Create New Category";
export const CategoryName: string = "Category Name";
export const PublishStatus: string = "Publish Status";
export const PublishDateTime: string = "Publish Date & Time";
export const TooltipLabelText: string = "Types of product discount";
export const InitialCost: string = "Initial cost";
export const SellingPriceText: string = "Selling price";
export const ChooseYourCurrency: string = "Choose your currency";
export const ProductStocks: string = "Product stocks";
export const InventoryText: string = "Inventory";
export const AdditionalOptionText: string = "Additional Options";
export const ShippingText: string = "Shipping";
export const StockAvailability: string = "Stock Availability";
export const LowStock: string = "Low Stock";
export const Sku: string = "SKU";
export const StockQuantity: string = "Stock Quantity";
export const RestockDate: string = "Restock Date";
export const PreOrder: string = "Pre-Order";
export const AllowBackorders: string = "Allow Backorders";
export const DigitalProduct: string = "This is a digital Product";
export const AdditionalTagTitle: string = "Additional Tag Title";
export const SpecificTags: string = "Specific Tags";
export const OrderPickupLabel: string = "Where can I pick up my order?";
export const WeightLabel: string = "Weight (kg)";
export const DimensionsLabel: string = "Dimensions";
export const ShippingClass: string = "Shipping Class";

//Product
export const ProductTitle: string = "Product";
export const Filters: string = "Filters";
export const ShowingProducts: string = "Showing Products 1 - 24 Of 200 Results";
export const Featured: string = "Featured";
export const LowestPrices: string = "Lowest Prices";
export const HighestPrices: string = "Highest Prices";
export const CategoryText: string = "Category";
export const BrandText: string = "Brand";
export const ColorsText: string = "Colors";
export const STEP = 10;
export const MIN = 25;
export const MAX = 1000;
export const ProductDetails: string = "Product Details";
export const Quantity: string = "Quantity";
export const ViewDetails: string = "View Details";

//Apps
//Project
export const AppsTitle: string = "Apps";
export const ProjectTitle: string = "Project";
export const ProjectListTitle: string = "Project List";
export const All: string = "All";
export const Doing: string = "Doing";
export const CreateNewProject: string = "Create New Project";
export const Issues: string = "Issues";
export const Resolved: string = "Resolved";
export const ProjectCreateTitle: string = "Project Create";
export const ProjectTitleLabel: string = "Project Title";
export const ClientName: string = "Client name";
export const ProjectRate: string = "Project Rate";
export const ProjectType: string = "Project Type";
export const Hourly: string = "Hourly";
export const FixPrice: string = "Fix price";
export const Priority: string = "Priority";
export const Low: string = "Low";
export const Medium: string = "Medium";
export const High: string = "High";
export const Urgent: string = "Urgent";
export const ProjectSize: string = "Project Size";
export const Small: string = "Small";
export const Big: string = "Big";
export const StartingDate: string = "Starting date";
export const EndingDate: string = "Ending date";
export const EnterSomeDetails: string = "Enter some Details";
export const UploadProjectFile: string = "Upload project file";

//File Manager
export const FileManagerTitle: string = "File Manager";
export const FileFilter: string = "file filter";
export const StorageText: string = "Storage";
export const PricingPlanText: string = "Pricing Plan";
export const TrialVersion: string = "Trial Version";
export const Free: string = "FREE";
export const Selected: string = "Selected";
export const AddNew: string = "Add New";
export const UploadText: string = "Upload";
//Dashboard
//Ecommerce
export const EcommerceDashboardTitle: string = "Ecommerce Dashboard";
export const ECommerceTitle: string = "E-Commerce";
export const Feedback: string = "Feedback";
export const SalesEventCalendarTitle: string = "Sales Event Calendar";
export const SetSalesEvents: string = "Set Sales Events";
export const Discount: string = "Discount";
export const SaleTopCountriesTitle: string = "Sale Top Countries";
export const CategoriesBySalesTitle: string = "Categories by Sales";
export const TotalEarned: string = "Total Earned";
export const SaleStatisticTitle: string = "Sale Statistic";
export const OrderOverviewTitle: string = "Order Overview";
export const TotalRevenue: string = "Total Revenue";
export const MonthlySaleTitle: string = "Monthly Sale";
export const Growth: string = "Growth";
export const InvoicesTitle: string = "Invoices";
export const Due: string = "Due";
export const Overdue: string = "Overdue";
export const Unlock: string = "UNLOCK";
export const RecentActivityTitle: string = "Recent Activity";
export const NewCustomersTitle: string = "New Customers";
export const SpecialDiscount: string = "Special Discount";
export const DealOfTheDay: string = "Deal of the  Day From";

//Widgets
//General
export const WidgetsTitle: string = "Widgets";
export const GeneralTitle: string = "General";

//Chart
export const ChartTitle: string = "Chart";
export const CompareLastMonth: string = "Compare to last month";
export const Today: string = "Today";
export const MonthlyHistoryTitle: string = "Monthly History";
export const LiveProductsTitle: string = "Live Products";
export const TurnOverTitle: string = "Turn over";
export const StockMarketTitle: string = "Stock Market";
export const ProjectDashboardTitle: string = "Project Dashboard";
export const Progress: string = "Progress";
export const ProjectSummaryTitle: string = "Project Summary";
export const UpcomingLaunchTitle: string = "Upcoming Launch App";
export const PortlabAppLaunch: string = "Portlab App Launch";
export const SetReminder: string = "Set Reminder";
export const DesignCardTitle: string = "WE ARE DESIGN AGENCY WORK WITH YOU";
export const HireUs: string = "Hire Us";
export const BudgetCardTitle: string = "Budget";
export const BudgetSpent: string = "Budget Spent";
export const ProjectCreatedTitle: string = "Project Created";
export const TodayTasksTitle: string = "Today’s Tasks";
export const ProgressCardTitle: string = "Workload Dashboard For CMS Website";
export const Projects: string = "Projects";
export const UpcomingProjectsTitle: string = "Upcoming Projects";
export const UpcomingMeetingTitle: string = "Upcoming Meeting";
export const TodaySanFransico: string = "Today san Fransico,CA";

//Editors
export const EditorsTitle: string = "Editors";
export const CkEditorTitle: string = "Ck Editor";
export const InlineEditorTitle: string = "Inline Editor";
export const YourTitle: string = "Your title";
export const Show: string = "Show";
export const MdeEditorTitle: string = "MDE Editor";
export const FirstExampleTitle: string = "First Example";
export const SecondExampleTitle: string = "Second Example";
export const Instructions: string = "Instructions";
export const AceCodeEditorTitle: string = "ACE Code Editor";
export const JavascriptModeTitle: string = "Javascript Mode";
export const HTMLModeTitle: string = "Html Mode";
export const CssModeTitle: string = "Css Mode";
export const PhpModeTitle: string = "Php Mode";

//Support Ticket
export const SupportTicketTitle: string = "Support Ticket";
export const SupportTicketList: string = "Support Ticket List";
export const SupportTicketSpan: string = "List of ticket opend by customers";
export const Profit: string = "Profit";
export const Loss: string = "Loss";

//Dashboards
export const DashboardTitle: string = "Dashboard";
export const DefaultDashboardTitle: string = "Default Dashboard";
export const DefaultTitle: string = "Default";
export const HelloRamirez: string = "Hello, Ramirez";
export const ViewProfile: string = "View Profile";
export const ThanLastWeek: string = "than last week";
export const NewsUpdateTitle: string = "News & Update";
export const MinAgo: string = "Min ago";
export const ProductStockTitle: string = "Product Stock";
export const TotalProductStock: string = "Total Product Stock";
export const RecentOrderTitle: string = "Recent Order";
export const SalesStatisticTitle: string = "Sales Statistic";
export const ViewCount: string = "View Count";
export const SeeMore: string = "See more";
export const SinceLastWeek: string = "Since last Week";
export const ReviewTitle: string = "Review";
export const SalesOverviewTitle: string = "Sales Overview";
export const ActiveTasksTitle: string = "Active Tasks";
export const TotalInvestmentTitle: string = "Total Investment";
export const LeadsStatusTitle: string = "Leads Status";
export const StatisticsTitle: string = "Statistics";
export const WeeklyTarget: string = "Weekly Target";
export const MontlyTarget: string = "Montly Target";

//job search
export const JobSearchTitle: string = "Job Search";
export const CardsViewTitle: string = "Cards View";
export const JobFilter: string = "Job Filter";
export const ListViewTitle: string = "List View";
export const JobDetailsTitle: string = "Job Details";
export const SimilarJobs: string = "Similar Jobs";
export const ProductDesignerTitle: string = "Product Designer (UI/UX Designer)";
export const JobDescription: string = "Job Description";
export const SaveThisJob: string = "Save this job";
export const Share: string = "Share";
export const ApplyTitle: string = "Apply";
export const UIDesigner: string = "UI Designer";
export const PersonalDetailsTitle: string = "Personal Details";
export const RepeatPassword: string = "Repeat Password";
export const BirthDate: string = "Birth Date";
export const YourEducationTitle: string = "Your Education";
export const CollegeName: string = "College Name";
export const Period: string = "Period";
export const DegreeLevel: string = "Degree Level";
export const Specialization: string = "Specialization";
export const YourExperienceTitle: string = "Your Experience";
export const Position: string = "Position";
export const CompanyName: string = "Company Name";
export const UploadYourFiles: string = "Upload Your Files";
export const UploadCoverLetter: string = "Upload Cover Letter";
export const UploadYourCV: string = "Upload Your CV";
export const UploadRecommendations: string = "Upload Recommendations";

//Maps
export const MapsTitle: string = "Maps";
export const GoogleMapsTitle: string = "Google Maps";
export const BasicMapTitle: string = "Basic Map";
export const MarkerMapTitle: string = "Marker Map";
export const PolygonsTitle: string = "Polygons";
export const PolylinesTitle: string = "Polylines";
export const LeafletMapTitle: string = "Leaflet Map";
export const WorldMapTitle: string = "Leaflet World Map";
export const UsaMapTitle: string = "Leaflet Usa Map";
export const IndiaMapTitle: string = "Leaflet India Map";

//faq
export const FaqTitle: string = "FAQ";
export const QuickQuestionsTitle: string = "Quick Questions are answered";
export const SearchArticlesTitle: string = "Search articles";
export const Navigation: string = "Navigation";
export const AskQuestion: string = "Ask Question";
export const LatestUpdatesTitle: string = "Latest Updates";
export const FeaturedTutorialsTitle: string = "Featured Tutorials";
export const LatestArticlesVideosTitle: string = "Latest articles and videos";

//Knowledgebase
export const KnowledgebaseTitle: string = "Knowledgebase";
export const knowledgebaseSearchText: string = "How Can I help you?";
export const BrowseArticlesTitle: string = "Browse articles by category";
export const BrowseArticlesHeader: string = "Browse Articles";

//Learning
export const LearningTitle: string = "Learning";
export const LearningListTitle: string = "Learning List";
export const ByAdmin: string = "by: Admin";
export const Hits: string = "Hits";
export const LearningFilterTitle: string = "Learning filter";
export const FindCourse: string = "Find Course";
export const Filter: string = "Filter";
export const CategoriesTitle: string = "Categories";
export const UpcomingCoursesTitle: string = "Upcoming Courses";
export const DetailedCourseTitle: string = "Detailed Course";
export const MarkJecno: string = "Mark Jecno";
export const Comment: string = "Comment";

//Charts
export const ChartsTitle: string = "Charts";
export const GoogleChartTitle: string = "Google Chart";
export const AreaChartTitle1: string = "Area Chart 1";
export const AreaChartTitle2: string = "Area Chart 2";
export const ColumnChartTitle1: string = "Column Chart 1";
export const ColumnChartTitle2: string = "Column Chart 2";
export const GanttChartTitle: string = "Gantt Chart";
export const LineChartTitle: string = "Line Chart";
export const ComboChartTitle: string = "Combo Chart";
export const BarChart2Title: string = "Bar-chart2";
export const WordTreeTitle: string = "Word Tree";
export const PieChart: string = "Pie Chart";
export const ChartJsChartTitle: string = "ChartJS Chart";
export const BarChartTitle: string = "Bar Chart";
export const LineGraphTitle: string = "Line Graph";
export const RadarGraphTitle: string = "Radar Graph";
export const DoughnutChartTitle: string = "Doughnut Chart";
export const PolarChartTitle: string = "Polar Chart";

//Miscellaneous
//gallery
export const GalleryTitle: string = "Gallery";
export const ImageGalleryTitle: string = "IMAGE GALLERY";
export const GalleryDescriptionTitle: string = "Gallery Grid With Description";
export const ImageGalleryDescriptionTitle: string =
  "IMAGE GALLERY WITH DESCRIPTION";
export const PortfolioTitle: string = "Portfolio Title";
export const MasonryGalleryTitle: string = "Masonry Gallery";
export const MasonryDescriptionTitle: string =
  "Masonry Gallery With Description";
export const ImageHoverEffectsTitle: string = "Image Hover Effects";
export const HoverEffect: string = "Hover Effect";

//blog
export const BlogTitle: string = "Blog";
export const BlogDetailsTitle: string = "Blog Details";
export const BlogSingleTitle: string = "Blog Single";
export const JolioMark: string = "Jolio Mark";
export const Designer: string = "Designer";
export const AddPostTitle: string = "Add Post";
export const PostEditTitle: string = "Post Edit";
export const DropzoneText: string = "Drop files here or click to upload.";
export const Discard: string = "Discard";
export const Type: string = "Type";
export const Category: string = "Category";
export const Content: string = "Content";

//pages
export const WeAreComingSoon: string = "WE ARE COMING SOON";
export const EnterEmailPassword: string =
  "Enter your email & password to login";
export const CreateYourAccount: string = "Create your account";
export const YourName: string = "Your Name";
export const Agreewith: string = "Agree with";
export const PrivacyPolicy: string = "Privacy Policy";
export const EnterYourPassword: string = "Enter your Password";
export const CreateYourPassword: string = "Create Your Password";
export const NewPassword: string = "New Password";
export const RetypePassword: string = "Retype Password";
export const Done: string = "Done";
export const ResetYourPassword: string = "Reset Your Password";
export const EnterYourMobileNumber: string = "Enter Your Mobile Number";
export const Resend: string = " Resend ";
export const EnterOtp: string = "Enter OTP";
export const MAINTENANCE: string = "MAINTENANCE";
export const SubContent: string =
  "Our Site is Currently under maintenance We will be back Shortly";
export const ThankYouPatience: string = "Thank You For Patience";
export const Loading: string = "Loading";
export const Finish: string = "Finish";
export const SignUpToAccount: string = "Sign up to account";
export const ContactNoLabel: string = "Contact No.";
export const Birthday: string = "Birthday:";
export const Age: string = "Age";
export const HavePassport: string = "Have Passport";

//Forms
export const FormControlTitle: string = "Form Controls";
export const TooltipFormValidationTitle: string = "Tooltip form validation";
export const UserName: string = "Username";
export const Zip: string = "Zip";
export const ValidationFormTitle: string = "Validation Form";
//Form Widgets
export const DatepickerTitle: string = "Datepicker";
export const DefaultCalendarTitle: string = "Default calendar";
export const ReactstrapCalendarTitle: string = "Reactstrap calendar";
export const DatePickerCardTitle: string = "Date picker";
export const DefaultDate: string = "Default Date";
export const HumanFriendly: string = "Human Friendly";
export const MinMaxValue: string = "Min-Max Value";
export const DisabledDates: string = "Disabled Dates";
export const MultipleDatesTitle: string = "Multiples Dates";
export const CustomizingConjunctionTitle: string = "Customizing Conjunction";
export const Range: string = "Range";
export const PreloadingDates: string = "Preloading Dates";
export const TimePickerTitle: string = "Time Picker";
export const HourPicker: string = "24-hour Time Picker";
export const TimePickerLimits: string = "Time Picker W/Limits";
export const PreloadingTime: string = "Preloading Time";
export const TimePickerRange: string = "TimePicker with Limited Time Range";
export const TimePickerMinMaxRange: string =
  "TimePicker with Min/Max Time Range";
export const DateWithTime: string = "Date With Time";
export const BaseInputsTitle: string = "Base inputs";
export const BasicFormTitle: string = "Basic form";
export const FloatingFormTitle: string = "Floating form";
export const SelectSizingTitle: string = "Select sizing";
export const HobbiesTitle: string = "What's Your Hobbies";
export const KhoKho: string = "Kho-kho";
export const ReadingBooks: string = "Reading Books";
export const Creativity: string = "Creativity";
export const FormControlSizingTitle: string = "Form control sizing";
export const FileInputTitle: string = "File input";
export const DefaultFileText: string = "Default file input example";
export const MultipleFileText: string = "Multiple files input example";
export const DisabledFileText: string = "Disabled file input example";
export const SmallFileText: string = "Small file input example";
export const LargeFileText: string = "Large file input example";
export const FlatInputStyleTitle: string = "Flat input style";
export const PleaseComments: string = "Please do comments";
export const RomanNumberTitle: string = "Select your favorite roman number";
export const MultiplePaintingTitle: string = "Select your multiple paintings";
export const BasicHtmlInputControlTitle: string = "Basic HTML input control";
export const PixelstrapThemeTitle: string =
  "Select Your Favorite Pixelstrap theme";
export const Placeholder: string = "Placeholder";
export const Number: string = "Number";
export const Telephone: string = "Telephone";
export const URL: string = "URL";
export const DateAndTime: string = "Date and time";
export const Month: string = "Month";
export const Week: string = "Week";
export const DatalistExampleTitle: string = "Datalist example";
export const Time: string = "Time";
export const ColorPicker: string = "Color picker";
export const MaximumLength: string = "Maximum Length";
export const StaticText: string = "Static Text";
export const StaticMagicalText: string = "Hello !... This is my magical text";
export const BasicFloatingInputControlTitle: string =
  "Basic floating input control";
export const ValidInput = "Valid input";
export const InputWithValue = "Input with value";
export const InvalidInput = "Invalid input";
export const OpenSelectMenu = "Open this select menu";
export const WorksWithSelects = "Works with selects";
export const InputGroupTitle = "Input group";
export const UsernameFeedback2 = "Please choose a username.";
export const Layout = "Layout";
export const Comments: string = "Comments";
export const EdgesInputStyleTitle: string = "Edges input style";
export const Male: string = "Male";
export const Female: string = "Female";
export const RaiseInputStyleTitle: string = "Raise input style";
export const ColorSelectTitle: string = "Select the color you like below";
export const CheckboxAndRadioTitle: string = "Checkbox & Radio";
export const DefaultCheckboxTitle: string = "Default checkbox";
export const Indeterminate: string = "Indeterminate";
export const IndeterminateCheckbox: string = "Indeterminate checkbox";
export const CustomCheckboxTitle: string = "Custom checkbox";
export const BorderedCheckboxTitle = "Bordered Checkbox";
export const PrimaryCheckbox = "Primary - checkbox-primary";
export const SecondaryCheckbox = "Secondary - checkbox-secondary";
export const SuccessCheckbox = "Success - checkbox-success";
export const IconCheckboxTitle = "Icon Checkbox";
export const FilledCheckboxTitle = "Filled Checkbox";
export const WarningCheckbox = "Warning - checkbox-solid-warning";
export const DangerCheckbox = "Danger - checkbox-solid-danger";
export const InfoCheckbox = "Info - checkbox-solid-info";
export const DefaultRadioTitle = "Default radio";
export const CustomRadiosTitle = "Custom Radios";
export const DefaultCheckedRadio = "Default checked radio";
export const DisabledRadios = "Disabled Radios";
export const DisabledRadioSmall = "Disabled radio";
export const DisabledCheckedRadio = "Disabled checked radio";
export const RightRadios = "Right Radios";
export const ImagesCheckboxTitle = "Images with checkbox";
export const ImagesWithRadioTitle = "Images with radio";
export const BorderedRadioTitle = "Bordered Radio";
export const IconsRadioTitle = "Icons Radio";
export const FilledRadioTitle = "Filled Radio";
export const DefaultSwitchesTitle = "Default switches";
export const InlineInputTypesTitle = "Inline input-types";
export const InlineSwitchesTitle: string = "Inline Switches";
export const AnimatedButtonsTitle: string = "Animated buttons";
export const BasicRadioAndCheckboxTitle: string = "Basic radio and checkbox";
export const RadioToggleButtonsTitle: string = "Radio toggle buttons";
export const Checked: string = "Checked";
export const Radio: string = "Radio";
export const Disabled: string = "Disabled";
export const OutlinedCheckboxStylesTitle: string = "Outlined checkbox styles";
export const SingleToggle: string = "Single toggle";
export const CheckedSuccessRadio: string = "Checked success radio";
export const DarkRadio: string = "Dark radio";
export const SubmitButton: string = "Submit form";
export const AgreePolicy: string = " I agree to the policies";
export const BrowserDefaultsTitle: string = "Browser defaults";
export const ChooseFile: string = "Choose file";
export const ConfirmationText: string =
  "Are you sure the above information is true?";
export const DescriptionLabel: string = "Description";
export const ClipboardTitle: string = "Clipboard";
export const ClipBoardOnTextInputTitle: string = "Clipboard on text input";
export const CutCopyFromInputArea: string = "Cut/copy from text input";
export const Copy: string = "Copy";
export const Cut: string = "Cut";
export const ClipboardOnTextAreaTitle: string = "Clipboard on textarea";
export const CutCopyFromTextArea: string = "Cut/copy from textarea";
export const ClipboardOnParagraphTitle: string = "Clipboard on paragraph";
export const ClipboardParagraphText: string = "Copy from paragraph";
export const CopyPortionParagraphTitle: string = "Copy portion from paragraph";
export const CopyFormText: string =
  "Web design is the process of creating websites ";
export const CopyFromParagraph: string = "Copy portion from paragraph";
export const CopyHighlightedText: string = "Copy highlighted text";
export const FormWidgetsTitle: string = "Form Widgets";
export const CustomSwitchTitle: string = "Custom switch";
export const DisabledOutlineSwitchTitle: string = "Disabled outline switch";
export const VariationOfSwitchesTitle: string = "Variation of switches";
export const TouchspinTitle: string = "Touchspin";
export const DefaultTouchspinTitle: string = "Default touchspin";
export const OutlinedTouchspinTitle: string = "Outlined touchspin";
export const IconsWithPrefixAndPostfixTitle: string =
  "Icons with prefix and postfix";
export const Pre: string = "Pre";
export const Post: string = "Post";
export const ButtonsWithPrefixPostfixTitle: string =
  "Buttons with prefix and postfix";
export const RoundedTouchspinTitle: string = "Rounded touchspin";
export const TypeaheadTitle: string = "Typeahead";
export const BasicTypeaheadTitle: string = "Basic typeahead";
export const PrefetchTitle: string = "Prefetch";
export const BloodhoundTitle: string = "Bloodhound (Suggestion Engine)";
export const RemoteTypeaheadTitle: string = "Remote typeahead";
export const CustomTemplatesTitle: string = "Custom templates";
export const MultipleSectionsHeadersTitle: string =
  "Multiple sections with headers";
export const ScrollableDropdownMenuTitle: string = "Scrollable Dropdown Menu";
export const RTLSupportTitle: string = "RTL Support";
export const BordersIconsTitle: string = "Borders with icons";
export const IconsSwitchTitle: string = "Icons switch";
export const SwitchIconsTitle: string = "Switch with Icons";
export const SwitchSizingTitle: string = "Switch sizing";
export const UncheckedSwitchTitle: string = "Unchecked switch";
//Input Groups
export const InputGroupsTitle: string = "Input Groups";
export const ButtonAddonsTitle: string = "Button addons";
export const CustomFormsTitle: string = "Custom forms";
export const Options: string = "Options";
export const FavoriteColorsTitle: string = "Select Your Favorite Colors";
export const FavoriteChocolatesTitle: string =
  "Select Your Favorite Chocolates";
export const FavoriteThemeTitle: string = "Select Your Favorite Theme";
export const CustomFileInputTitle: string = "Custom file input";
export const Upload: string = "Upload";
export const ButtonsWithDropdownsTitle: string = "Buttons with dropdowns";
export const ToggleDropdown: string = "Dropdown";
export const SegmentedButtonsTitle: string = "Segmented buttons";
export const Info: string = "Info";
export const Secondary: string = "Secondary";
export const CheckboxesAndRadiosTitle: string = "Checkboxes and radios";
export const SizingTitle: string = "Sizing";
export const SmallInput: string = "Small";
export const DefaultInput: string = "Default";
export const LargeInput: string = "Large";
export const MultipleInputsTitle: string = "Multiple inputs";
export const FirstAndLastName: string = "First and last name";
export const BasicInputGroupsTitle: string = "Basic input groups";
export const Basic: string = "Basic";
export const YourVanityUrl: string = "Your vanity URL";
export const WithTextArea: string = "With textarea";
export const Wrapping: string = "Wrapping";
export const FlexNowrapCodeText: string = ".flex-nowrap";
export const VariationAddonsTitle: string = "Variation of addons";
export const LeftAddon = "Left Addon";
export const RightAddon = "Right Addon";
export const JointAddon = "Joint Addon";
export const LeftAndRightAddon = "Left & Right Addon";
export const SolidStyle = "Solid style";
export const FlatStyle = "Flat style";
export const RaiseStyle = "Raise style";
export const LeftAndRight = "Left & Right";
//Input Mask
export const InputMaskTitle: string = "Input Mask";
export const DateFormatTitle: string = "Date Format";
export const Date: string = "Date";
export const DateFormatType: string = "Date format type";
export const TimeFormatTitle: string = "Time Format";
export const TimeFormatType: string = "Time format type";
export const HourMonthType: string = "Hour/month type";
export const DefaultInputMaskTitle: string = "Default Input Mask";
export const Currency: string = "Currency";
export const Prefix: string = "Prefix";
export const Delimiter: string = "Delimiter";
export const PhoneNumber: string = "Phone Number";
export const Tailprefix: string = "Tailprefix";
//Mega Options
export const MegaOptionsTitle: string = "Mega Options";
export const VariationRadioTitle: string = "Variation radio";
export const VariationCheckboxTitle: string = "Variation checkbox";
export const VariationActivitiesTitle: string =
  "Which of the following activities do you enjoy doing in your free time?";
export const UpgradeTitle: string = "It's time to ";
export const Upgrade: string = "upgrade";
export const UpgradeParagraph: string =
  "Select the theme that best suits your requirements, and you're ready to go!";
export const WithoutBordersStyleTitle: string = "Without borders style";
export const SolidBorderStyleTitle: string = "Solid border style";
export const OfferStyleBorderTitle: string = "Offer style border";
export const InlineStyleTitle: string = "Inline style";
export const VerticalStyleTitle: string = "Vertical style";
export const HorizontalStyleTitle: string = "Horizontal style";
export const MasterCard: string = "MasterCard";
export const PayMethodTitle: string = "Select your payment method";
export const Visa: string = "Visa";

//Buttons
export const ButtonsTitle: string = "Buttons";
export const DefaultStyleTitle: string = "Default Style";
export const DefaultButtonTitle: string = "Default buttons";
export const DefaultButtonText: string = "Bootstrap state buttons";
export const FlatButtonTitle: string = "Flat button";
export const RaisedButtonTitle: string = "Raised with Edge buttons";
export const SizingButtonsTitle: string = "Sizing buttons";
export const ActiveButtonsTitle: string = "Active Buttons";
export const DisabledButtonsTitle: string = "Disabled buttons";
export const OutlineButtonsTitle: string = "Outline buttons";
export const BoldBorderOutlineButtonsTitle: string =
  "Bold Border outline buttons";
export const GradienButtonsTitle: string = "Gradien buttons";
export const ButtonGroupTitle: string = "Button Group";
export const Option1: string = "Option 1";
export const Option2: string = "Option 2";
export const NestingTitle: string = "Nesting";
export const NestingButtonSpan: string = "Make nesting buttons";
export const DropdownText: string = "Dropdown";
export const DropdownLink: string = "Dropdown link";
export const VerticalTitle: string = "Vertical";
export const VerticalText: string = "Make vertical buttons";
export const ButtonHeading: string = "Button";
export const FlatButtonSubTitle: string = `Add <code>.btn-square</code> class for flat button`;
export const RaisedButtonSubTitle: string = `Add <code>.btn-pill</code> and <code>.btn-air-*</code> class button`;
export const SizingButtonSubTitle: string = `Add <code>.btn-lg</code><code>.btn-sm</code><code>.btn-xs</code> class for Different Sizing buttons`;
export const ActiveButtonSubTitle: string = `Add <code>.active</code> class for active state`;
export const DisabledButtonSubTitle: string = `Add <code>.active</code> class for active state`;
export const OutlineButtonSubTitle: string = `Add <code>.btn-outline-*</code> class for border button`;
export const BoldBorderOutlineButtonSubTitle: string = `Add <code>.btn-outline-*-2x</code> class for bold outline`;
export const GradienButtonsSubTitle: string = `Add <code>.btn-*-gradien</code> class for gradien button`;

//Charts
export const ApexChartTitle: string = "Apex Chart";
export const BasicAreaChartTitle: string = "Basic Area Chart";
export const AreaSpalineChartTitle: string = "Area Spaline Chart";
export const ColumnChartTitle: string = "Column Chart";
export const BubbleChartTitle: string = "3d Bubble Chart";
export const SteplineChartTitle: string = "Stepline Chart";
export const PieChartTitle: string = "Pie Chart";
export const DonutChartTitle: string = "Donut Chart";
export const MixedChartTitle: string = "Mixed Chart";
export const CandlestickChartTitle: string = "Candlestick Chart";
export const RadarChartTitle: string = "Radar Chart";
export const RadialBarChartTitle: string = "Radial Bar Chart";
//Tables
export const DataTablesTitle: string = "Data Tables";
export const BasicDataTablesTitle: string = "Basic DataTables";
export const ZeroConfigurationTitle: string = "Zero Configuration";
export const StateSavingTableTitle: string = "State saving";
export const ScrollVerticalDynamicHeightTitle: string =
  "Scroll - vertical, dynamic height";
export const AdvanceInitTitle: string = "Advance Init";
export const StockResultTitle: string = "Stock Result";
export const RowCreateCallbackTitle: string = "Row Create Callback";
export const APIDataTablesTitle: string = "API DataTables";
export const AddRowsTitle: string = "Add rows";
export const AddNewRow: string = "Add new row";
export const ChildRowsTitle: string =
  "Child rows (show extra / detailed information)";
export const ID: string = "ID";
export const FullName: string = "Full name";
export const ExtraInfo: string = "Extra info";
export const ExtraInformation: string =
  "And any further details here (images etc)...";
export const RowsSelectionAndDeletionTitle: string =
  "Row Selection And Deletion (Single Row)";
export const DeleteRow: string = "Delete Row";
export const CustomFilteringTitle: string = "Custom filtering - range search";
export const CustomFilteringSearchSpan: string =
  "This example shows a search being performed on the age column in the data, based upon two inputs.";
export const MinimumAge: string = "Minimum age:";
export const MaximumAge: string = "Maximum age:";
export const DataSourceTitle: string = "DATA Source DataTables";
export const HtmlTableTitle: string = "HTML (DOM) sourced data";
export const AjaxSourcedDataTitle: string = "Ajax sourced data";
export const JavascriptSourcedDataTitle: string = "Javascript sourced data";
export const ServerSideProcessingTitle: string = "Server-side processing";
export const Clear: string = "Clear";

//pages
export const PagesTitle: string = "Pages";
export const SamplePageTitle: string = "Sample Page";
export const SampleCardTitle: string = "Sample Card";
export const SampleCardSpan: string =
  "lorem ipsum dolor sit amet, consectetur adipisicing elit";
export const BackToHomePage: string = "BACK TO HOME PAGE";

//Form Layout
export const FormLayoutTitle: string = "Form Layout";
export const FormWizard2Title: string = "Step Form Wizard";
export const CustomHorizontalTitle: string = "Custom horizontal wizard";
export const State: string = "State";
export const AgreeTerms: string = "Agree to terms and conditions";
export const Continue: string = "Continue";
export const Previous: string = "Previous";
export const AadhaarNumber: string = "Aadhaar Number";
export const PAN: string = "PAN";
export const ChooseBanks: string = "Choose from these popular banks";
export const DescribeIssue: string = "If no, could you please describe?";
export const SuccessfullyCompleted: string = "Successfully Completed";
export const BusinessVerticalTitle: string = "Business vertical wizard";
export const AccountType: string = "Select the type of account";
export const AccountName: string = "Account Name";
export const ProjectDescriptionText: string = "project and write a description";
export const SelectTeamWith: string = "Select team size with ";
export const ContactDetails: string = "Contact details";
export const OrganizationName: string = "Organization Name";
export const JoinOrganizationType: string = "Join organization type";
export const OrganizationDescription: string = "Organization description";
export const CardHolder: string = "Card Holder";
export const CardNumber: string = "Card Number";
export const Expiration: string = "Expiration(MM/YY)";
export const CVVNumber: string = "CVV Number";
export const CustomVerticalTitle: string = "Custom vertical wizard";
export const BusinessWizardTitle: string = "Business horizontal wizard";

//Tables
export const ReactstrapTablesTitle: string = "Reactstrap Tables";
export const ReactstrapBasicTablesTitle: string = "Reactstrap Basic Tables";
export const BasicTableWithBorderTitle: string =
  "Basic Table With Border Bottom Color";
export const InverseTableTitle: string = "Inverse Table";
export const HoverableRowsTitle: string =
  "Hoverable Rows With Horizontal Border";
export const InverseTablePrimaryTitle: string =
  "Inverse Table with Primary background";
export const CaptionTitle: string = "Caption";
export const UserList: string = "List of users";
export const TableHeadOptionsTitle: string = "Table head options";
export const StripedRowInverseTableTitle: string =
  "Striped Row with Inverse Table";
export const BreckpointSpecificTitle: string = "Breckpoint Specific";
export const ResponsiveLightBackgroundTitle: string =
  "Responsive Tables With Light Background";
export const SizingTablesTitle: string = "Sizing Tables";
export const CustomColorHoverStrippedTitle: string =
  "Custom Table Color With Hover And Stripped";
export const DashedBorderTitle: string = "Dashed Border";
export const TableComponentsTitle: string = "Table Components";
export const UIComponentsTitle: string = "UI Components";
export const ProgressbarTitle: string = "Progressbar";
export const CheckboxTitle: string = "Checkbox";
export const RadioButtonsTitle: string = "Radio Buttons";
export const Select: string = "Select";
export const BadgesTitle: string = "Badges";
export const TooltipTriggersTitle: string = "Tooltip Triggers";
export const SwitchTitle: string = "Switch";
export const DefaultButtonsTitle: string = "Default Button";
export const FormWizardTitle: string = "Form Wizard";
export const NumberingWizardTitle: string = "Numbering wizard";
export const ConfirmPassword: string = "Confirm Password";
export const PlaceholderName: string = "Placeholder Name";
export const UploadDocumentation: string = "Upload Documentation";
export const AboveInformationCorrect: string =
  "All the above information is correct";
export const Github: string = "Git hub";
export const SelectState: string = "Select State";
export const GiveFeedback: string = "Give Feedback";
export const Congratulations: string = "Congratulations ";
export const StudentValidationFormTitle: string = "Student validation form";
export const Next: string = " Next";
export const AddProfile: string = "Add Profile";
export const PortfolioURL: string = " Portfolio URL";
export const ProjectDescription: string = " Project Description";
export const SocialLinks: string = " Social Links ";
export const Twitter: string = " Twitter";
export const Submit: string = "Submit";
export const WhyThisPositionText: string =
  "Why do you want to take this position?";
export const VerticalValidationWizardTitle: string =
  "Vertical validation wizard";
export const ZipCode: string = "Zip Code";
export const ContactNumber: string = "Contact Number";
export const SelectPaymentMethod: string = "Select your payment method";
export const NetBanking: string = " NET BANKING";
export const SelectYourBank: string = " SELECT YOUR BANK";
export const ShippingFormTitle: string = "Shipping form";
export const BillingInformation: string = "Billing Information ";
export const FillFollowingInformation: string =
  "Fill up the following information ";
export const RememberMeNext: string = "Remember me for next time";
export const OtherNotes: string = "Other Notes";
export const ProceedToNext: string = "Proceed to Next";
export const ShippingMethod: string = "Shipping Method";
export const ShippingInformation: string = "Shipping Information";
export const SavedAddress: string = "Saved Address";
export const Information: string = "Information";
export const AddressType: string = "Address Type";
export const Office: string = "Office";
export const PaymentInformation: string = "Payment Information";
export const Paypal: string = "Paypal";
export const CreditCard: string = "Credit Card";
export const CashOnDeliveryTitle: string = "Cash On Delivery";
export const OrderConfirmed: string = "Thank you! Your order is confirmed.";
export const OrderID: string = "Order ID";
export const CurrentCartTitle: string = "Current Cart";
export const ProductDetail: string = "Product Detail";
export const Price: string = "Price";
export const NewProducts: string = "New Products";
export const TwoFactorTitle: string = "Two Factor";
export const TwoFactorAuthenticationTitle: string = "Two-factor authentication";
export const FactorAuthentication: string = "2 factor authentication";
export const EmailVerificationTitle: string = "Email verification";
export const Verify: string = "Verify";
export const VerificationCodeTitle: string = "Verification code";
export const Call: string = "Call";
export const OR: string = "OR";
export const YourCode: string = "Your OTP Code here:";

//Bonus Ui
export const TimelineTitle: string = "Timeline";
export const TimelineTitle1: string = "Timeline 1";
export const BasicTimelineTitle: string = "Basic Timeline";
export const HoverTimelineTitle: string = "Hovering Timeline";
export const Location: string = "Location";
export const HoverFresherText1: string = "Ofwrrior Company";
export const HoverFresherText2: string =
  "A fresher's interview is to be conducted";
export const MollyBoake: string = "Molly Boake";
export const HoverMeetupText1: string = "US Meeting";
export const HoverMeetupText2: string =
  "2209 Leverton Cove RoadSpringfield, MA 01109";
export const VariationTimelineTitle: string = "Variation Timeline";
export const UpdatedProduct: string = "Updated Product";
export const AddedNewItems: string = "Added New Items";
export const TelloJust: string = "Tello just like your product";
export const HorizontalTimelineTitle: string = "Horizontal Timeline";
export const ReadMore: string = "Read more";
export const DesignerText: string = "Designer";
export const AppIdeas: string = "app-ideas";
export const BlogBadge: string = "Blog";
export const AudioTesting: string = "Audio testing";
export const MeetUp: string = "Meet-up";
export const Resolutions: string = "Resolutions";

//Icons
export const IconsTitle: string = "Icons";
export const FlagIconsTitle: string = "Flag Icons";
export const Markup = "MARKUP";
export const FontAwesomeIconTitle: string = "Font Awesome Icon";
export const IcoIconTitle: string = "ICO Icon";
export const ThemifyIconTitle: string = "Themify Icon";
export const FeatherIconsTitle: string = "Feather Icons";
export const ImageCropperTitle: string = "Image Cropper";
export const WhetherIconTitle: string = "Whether Icon";
export const WhetherIconsWithAnimations: string =
  "Whether Icons With Animations";

//Basic Card
export const BasicCardTitle: string = "Basic Card";
export const FlatCardTitle: string = "Flat Card";
export const WithoutShadowTitle: string = "Without shadow Card";
export const IconHeadingTitle: string = "Icon In Heading";
export const IconHeadingParagraph: string = "Use the any icons  for heading. ";
export const IconHeadingCodeText: string = "[font-awesome/ico-font/feather].";
export const DarkCardTitle: string = "Dark Color Card";
export const CardFooterText: string = "Card Footer";
export const InfoColorHeaderTitle: string = "Info Color Header";
export const InfoColorBodyTitle: string = "Info Color Body";
export const InfoColorFooterTitle: string = "Info Color Footer";
export const WebDesignerText: string = "Web Designer";
export const WebDeveloper: string = "Web Developer";

//Creative Card
export const CreativeCardTitle: string = "Creative Card";
export const BorderLeftTitle: string = "Border Left";
export const BorderRightTitle: string = "Border Right";
export const BorderTopTitle: string = "Border Top";
export const BorderBottomTitle: string = "Border Bottom";
export const BorderPrimaryTitle: string = "Border Primary State";
export const TeresaMosteller: string = "Teresa J. Mosteller";
export const GloriaAcheson: string = "Gloria D. Acheson";
export const BryanOwens: string = "Bryan A. Owens";
export const BorderWarningTitle: string = "Border Warning State";
export const BorderWarningText: string =
  "known for delivery of useful and usable solutions";
export const BorderWarningText1: string = "Solve your problem with us";
export const BorderWarningText2: string = "Certified Professionals";
export const BorderWarningText3: string = "Growth-Driven ";
export const BorderSecondaryTitle: string = "Border Secondary State";
export const AbsoluteCard: string = "Absolute Card";
export const PaginationsTitle: string = "Paginations";
export const DefaultPaginationTitle: string = "Default Pagination";
export const ActiveDisablePageTitle: string =
  "Pagination With Active And Disabled";
export const IconPaginationTitle: string = "Pagination With Icons";
export const RoundedPaginationTitle: string = "Rounded Pagination";
export const PaginationAlignTitle: string = "Pagination Alignment";
export const PaginationSizeTitle: string = "Pagination Sizing";

//Breadcrumb
export const BreadcrumbTitle: string = "Breadcrumb";
export const DefaultBreadcrumbTitle: string = "Default Breadcrumb";
export const DividerBreadcrumbTitle: string = "Divider Breadcrumb";
export const WithIconBreadcrumbTitle: string = "With Icons Breadcrumb";
export const VariationBreadcrumbTitle: string = "Variation Of Breadcrumb";
export const HomeArrow: string = "Home -> ";
export const TablesArrow: string = "Tables -> ";
export const BootstrapArrow: string = "Bootstrap Tables -> ";
export const BasicTables: string = "Basic Tables";
export const ColoredBreadcrumbTitle: string = "Colored Breadcrumb";
export const Icons: string = "Icons";
export const FlagIcon: string = "Flag-icon";
export const Gallery: string = "Gallery";
export const GalleryGrid: string = "Gallery-grid";

//Range Slider
export const RangeSliderTitle: string = "Range Slider";
export const BasicRangeTitle: string = "Basic Slider";
export const NagativeValueTitle: string = "Negative Values";
export const DisabledSliderTitle: string = "Disabled";
export const FormatedLabelTitle: string = "Formated Label";
export const DraggableTitle: string = "Draggable Range";
export const SweetAlertTitle: string = "Sweet Alert";
export const BasicAlertTitle: string = "Basic Example";
export const ClickIt: string = "Click it!";
export const TitleWithTextTitle: string = "Title With A Text Under";
export const Surprise: string = "Surprise!";
export const InfoAlertTitle: string = "Info Alert";
export const Informational: string = "Informational";
export const WarningAlertTitle: string = "Warning Alert";
export const Warning: string = "Warning";
export const PikachuAlertTitle: string = "Pikachu Alert";
export const Pikachu: string = "Pikachu";
export const QuestionAlertTitle: string = "Questions Alert";
export const QuestionsState: string = "Questions state";
export const UserNameAlertTitle: string = "Username Alert";
export const Username: string = "Username";
export const SuccessMessageTitle: string = "Success Message";
export const LoginSuccessfully: string = "Login successfully";
export const DangerAlertTitle: string = "Danger Alert";
export const DangerMode: string = "Danger mode";
export const WarningModeTitle: string = "Warning Mode";
export const AlertMode: string = "Alert mode";
export const AutoCloseAlertTitle: string = "Auto Close Timer";
export const Timer: string = "Timer";
export const MovieMode: string = "Movie mode";
export const CopyText: string = "Copy text";
export const MovieAlertTitle: string = "AJAX Request Movie";

//Owl Carousel
export const OwlCarouselTitle: string = "Owl Carousel";
export const SlidesOnlyTitle: string = "Slides Only";
export const WithControlsTitle: string = "With Controls";
export const AutoPlayVariantTitle: string = "Auto Play Variant";
export const MouseOverTitle: string = "Mouse Wheel Variant";
export const WithIndicatorsTitle: string = "With Indicators";
export const WithCaptionTitle: string = "With Captions";
export const CrossFadeTitle: string = "Cross-Fade";
export const IndividualCarouselTitle: string =
  "Individual Carousel-Item Interval";
export const DisableTouchSwipingTitle: string = "Disable Touch Swiping";
export const DarkVariantTitle: string = "Dark Variant";

//Ribbons
export const RibbonsTitle: string = "Ribbons";
export const LeftSideRibbonsTitle: string = "Variations Of Left Ribbons";
export const RightSideRibbonTitle: string = "Variations Of Right Ribbons";
export const RatingTitle: string = "Rating";
export const RatingBars: string = "1 to 10 Rating Bar";
export const MovieRatingBar: string = "Movie Rating Bar";
export const SquareRatingBar: string = "Square Rating Bar";
export const PillRatingBar: string = "Pill Rating Bar";
export const ReversedRatingBar: string = "Reversed Rating Bar";
export const StarRatingBar: string = "Star Rating Bar";
export const HalfStarRatingBar: string = "Half Star Rating Bar";
export const ThumbUpDownRate: string = "Thumb Up/Down Rating Bar";
export const HeartRatingBar: string = "Heart Rating Bar";

//Dropzone
export const DropzoneTitle: string = "Dropzone";
export const DefaultDropzoneTitle: string = "Default File Upload";
export const ImagePreviewTitle: string = "Image Preview";
export const SingleFileuploadTitle: string = "Single File Upload";
export const MultiFileUploadTitle: string = "Multi File Upload";

//Tour
export const TourTitle: string = "Tour";
export const WilliamEmail: string = "William@jourrapide.com";
export const January: string = "02 January 1988";
export const BOD: string = "BOD";
export const WilliamJennings: string = "William C. Jennings";
export const ContactNo: string = "US 310-273-0666";
export const LibbyStreet: string = "4377 Libby Street Beverly Hills";
export const Follower: string = "Follower";
export const Following: string = "Following";
export const Jan25: string = "25 Jan";
export const MinRead6: string = "6 min read";
export const Min3Ago: string = "3 min ago";
export const Min1Read: string = "1 min read";
export const Hours10Ago: string = "10 hours ago";
export const Profile2Head: string =
  "Wonderful piece that successfully conveys the magnificence of the mountains and the natural world.";
export const Profile2Text: string =
  " English Romantic painter, printer, and watercolourist ";
export const Dec09: string = "09 Dec";
export const Min2Read: string = "2 min read";

//Theme customizer
export const LayoutTypeTitle: string = "Layout Type";
export const SidebarTypeTitle: string = "Sidebar Type";
export const SidebarIconTitle: string = "Sidebar Icon";
export const UnlimitedColorTitle: string = "Unlimited Color";
export const ApplyButton: string = "Apply";
export const LightLayoutTitle: string = "Light layout";
export const DarkLayoutTitle: string = "Dark layout";
export const MixLayoutTitle: string = "Mix Layout";
export const PreviewSettings: string = "PREVIEW SETTINGS";
export const ConfigurationTitle: string = "Configuration";
export const CopyTextButton: string = "Copy Text";
export const QuickOption: string = "Quick Option";

//auth
export const SignInAccount: string = "Sign in to account";
export const RememberPassword: string = "Remember password";
export const ForgotPassword: string = "Forgot password?";
export const SignInWith: string = "Or Sign in with";
export const LinkedIn: string = "LinkedIn";
export const TwitterLink: string = "Twitter";
export const FacebookLink: string = "Facebook";
export const DontHaveAccount: string = "Don't have account?";
export const CreateAccount: string = "Create Account";

//Apps
//Chat
export const ChatTitle: string = "Chat";
export const PrivateChatTitle: string = "Private Chat";
export const GroupChatTitle: string = "Group-Chat";

//Users
export const UsersTitle: string = "Users";
export const UserProfileTitle: string = "User Profile";
export const EditProfileTitle: string = "Edit Profile";
export const MyProfileTitle: string = "My Profile";
export const Save: string = "Save";
export const Bio: string = "Bio";
export const Website: string = "Website";
export const Company: string = "Company";
export const City: string = "City";
export const AboutMe: string = "About Me";
export const UpdateProfile: string = "Update Profile";
export const AddProjectsAndUploadTitle: string = "Add projects And Upload";
export const Edit: string = "Edit";
export const Update: string = "Update";
export const Delete: string = "Delete";
export const UserCardsTitle: string = "User Cards";
export const Posts: string = "Posts";

//Social app
export const SocialAppTitle: string = "Social App";
export const GeneralManager: string = "general manager";
export const Elana: string = "ElANA";
export const Timeline: string = "Timeline";
export const About: string = "About";
export const Friends: string = "Friends";
export const Photos: string = "Photos";
export const MyPage: string = "My Page";
export const Messages: string = "Messages";
export const Notification: string = "notification";
export const Likes: string = "Likes";
export const View: string = "View";
export const NewLikesThisWeek: string = "35 New Likes This Week";
export const MutualFriendsTitle: string = "Mutual Friends";
export const ActivityFeedTitle: string = "Activity Feed";
export const Photo: string = "Photo";
export const ProfileIntroTitle: string = "Profile Intro";
export const FavouriteTvShows: string = "Favourite TV shows";
export const FavouriteMusicBands: string = "Favourite Music Bands";
export const SocialNetworks: string = "Social Networks";
export const Facebook: string = "Facebook";
export const Dribbble: string = "Dribbble";
export const AddFriend: string = "Add Friend";
export const Followers: string = "Followers";
export const FollowingsTitle: string = "Followings";
export const LatestPhotosTitle: string = "Latest Photos";
export const FriendsTitle: string = "Friends";
export const PepoleKnownTitle: string = "Pepole You May Know";
export const MoreCommnets: string = "More Commnets";
export const ViewedProfileTitle: string = "Viewed Your Profile";
export const ActivityLogTitle: string = "Activity Log";

//Search Result
export const SearchPagesTitle: string = "Search Pages";
export const SearchWebsiteTitle: string = "Search Website";
export const Search: string = "Search";
export const SearchResultPixelstrap: string = 'Search result for "Pixelstrap"';
export const BlogShadowText: string =
  "Accusamus et iusto odio dignissimos ducimus qui blanditiis.";
export const ImageTabTitle: string = "About 12,120 results (0.50 seconds)";
export const VideoTabTitle: string = "About 6,000 results (0.60 seconds)";

//Tasks
export const TasksTitle: string = "Tasks";
export const NewTaskTitle: string = "New Task";
export const AddTaskTitle: string = "Add Task";
export const TaskTitleLabel: string = "Task Title";
export const SubTaskTitle: string = "Sub task";
export const Mail: string = "Mail";
export const Views: string = "Views";
export const CreateTag: string = "Create Tag";
export const TagName: string = "Tag Name";
export const TagColor: string = "Tag color";
export const TodayTasks: string = "Today's Tasks";
export const DelayedTasks: string = "Delayed Tasks";
export const UpcomingTasks: string = "Upcoming Tasks";
export const ThisWeekTasks: string = "This week tasks";
export const ThisMonthTasks: string = "This month tasks";
export const Newsletter: string = "Newsletter";
export const Business: string = "Business";
export const Holidays: string = "Holidays";
export const CreatedByMe: string = "Created by me";
export const AssignedToMe: string = "Assigned to me";
export const MyTasks: string = "My tasks";

//Calendar
export const CalendarTitle: string = "Calendar Basic";
export const CalendarFilter: string = "calendar filter";
export const DraggableEventsTitle: string = "Draggable Events";
export const RemoveAfterDrop: string = "remove after drop";

//ToDo
export const ToDoTitle: string = "To-Do";
export const ToDoFilter: string = "To Do filter";

//Bookmarks
export const BookmarksTitle: string = "Bookmarks";
export const BookmarkFilter: string = "bookmark filter";
export const NewBookmark: string = "New Bookmark";
export const Tags: string = "Tags";
export const AddBookmark: string = "Add Bookmark";
export const WebUrl: string = "Web Url";
export const Title: string = "Title";
export const Group: string = "Group";
export const Collection: string = "Collection";
export const NoBookmarksFoundText: string = "No Bookmarks Found";
export const SharedWithMe: string = "Shared with me";
export const MyBookmark: string = "My bookmark";
export const Favourites: string = "Favourites";
export const Important: string = "Important";
export const Organization: string = "Organization";

//Contacts
export const ContactsTitle: string = "Contacts";
export const ContactFilter: string = "contact filter";
export const MarkJenco: string = "MARK JENCO";
export const MarkJencoMail: string = "Markjecno@gmail.com";
export const NewContacts: string = "New Contacts";
export const AddContactText: string = "Add Contact";
export const Name: string = "Name";
export const Phone: string = "Phone";
export const Personal: string = "Personal";
export const ZeroContact: string = "0 Contacts";
export const OrgenizationTitle: string = "Organization";
export const FollowUp: string = "Follow Up";
export const Ideas: string = "Ideas";
export const ContactHistory: string = "Contact History";
export const ContactHistoryNotModified: string =
  "Contact has not been modified yet.";
export const ContactCreated: string = "Contact Created";
export const EditMoreInformation: string = "Edit more information";
export const MobileNo: string = "Mobile No";
export const PrintPreview: string = "Print preview";
export const History: string = "History";
export const Gender: string = "Gender";

//Email
export const EmailTitle: string = "Email";
export const EmailApplicationTitle: string = "Email Application";
export const EmailFilter: string = "email filter";
export const Markjenco: string = "MARKJENCO";
export const MarkjencoMail: string = "Markjecno@gmail.com";
export const NewMail: string = "NEW MAIL";
export const More: string = "More";
export const Action: string = "Action";
export const AnotherAction: string = "Another action";
export const SomethingElseHere: string = "Something else here";
export const Attachments: string = "ATTACHMENTS";
export const DownloadAll: string = "Download All";
export const EmailComposeTitle: string = "Email Compose";
export const NewMessage: string = "New Message";
export const SendText: string = "SEND";
export const LetterBoxTitle: string = "Letter Box";
export const ComposeEmail: string = "Compose Email";
export const AddLabel: string = "Add Label";
export const Add: string = "Add";
export const ComposeMessage: string = "Compose Message";
export const SaveDraft: string = "Save as draft";
export const Send: string = "Send";

//Ecommerce
//Invoice
export const InvoiceTitle: string = "Invoice";
export const Print: string = "Print";

//Cart
export const CartTitle: string = "Cart";
export const CartEmpty: string = "Your Cart is Empty";
export const ExploreItems: string = "Explore more and shortlist some items.";
export const Apply: string = "Apply";
export const TotalPrice: string = "Total Price";
export const ContinueShopping: string = "continue shopping";
export const CheckOut: string = "check out";

//Wishlist
export const WishlistTitle: string = "Wishlist";
export const MoveToCart: string = "Move to Cart";

//Checkout
export const CheckoutTitle: string = "Checkout";
export const BillingDetails: string = "Billing Details";
export const PlaceOrder: string = "Place Order";
export const Country: string = "Country";
export const Address: string = "Address";
export const TownCity: string = "Town/City";
export const StateCountry: string = "State/Country";
export const PostalCode: string = "Postal Code";
export const Product: string = "Product";
export const Total: string = "Total";
export const Subtotal: string = "Subtotal";
export const Shipping: string = "Shipping";
export const CheckPayments: string = "Check Payments";
export const CashOnDelivery: string = "Cash On Delivery";
export const PayPal: string = "PayPal";

//Pricing
export const PricingTitle: string = "Pricing";
export const BecomeMemberTitle: string = "Become member";
export const SimplePricingTitle: string = "Simple Pricing Card";
export const Purchase: string = "Purchase";
export const Plan: string = "Plan";
export const EmailAccounts: string = "50 Email Accounts";
export const Maintenance: string = "Maintenance";
export const SubDomains: string = "15 Subdomains";
