import * as Paths from "./../files/Paths";
import * as Headers from "./../files/Headers";
import request from "./../files/Request";

// Types
import { ListFilterType } from "Types/Common/ListFilterType";
import {Company} from "Types/Crm/Company";

// Constants
import { defaultPageAndSizeForAll } from "Utils/Constants";

export const getCompanies = async (filter: ListFilterType, filterObj: any) => {
  const filterParams = {
    name: filterObj?.name,
    companyType: filterObj?.companyType,
    contacts: filterObj?.contacts,
    phone: filterObj?.phone,
    district: filterObj?.district,
    city: filterObj?.city
  };
  return request.get(
    `${Paths.crm}/company/all`,
    {
      page: filter.page,
      size: filter.size,
      ...(filter?.sort && {sort: filter?.sort[0]}),
      ...filterParams
    },
    { headers: Headers.headers }
  );
};

export const getCompaniesAll = async () => {
  return request.get(
    `${Paths.crm}/company/all`,
    {
      page: defaultPageAndSizeForAll.page,
      size: defaultPageAndSizeForAll.size
    },
    { headers: Headers.headers }
  );
};

export const getCompany = async (id: any) => {
  return request.get(
    `${Paths.crm}/company/${id}`, {},
    { headers: Headers.headers }
  );
};

export const deleteCompany = async (id: string) => {
  return request.delete(
    `${Paths.crm}/company/${id}`, {},
    {
      headers: Headers.headers,
    }
  );
};

export const addCompany = async (company: Company) => {
  return request.post(
    `${Paths.crm}/company`, company, {},
    {
      headers: Headers.headers,
    }
  );
};

export const updateCompany = async (company: Company) => {
  return request.put(
    `${Paths.crm}/company`, company,
    {
      headers: Headers.headers,
    }
  );
};