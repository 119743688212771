import React, { useEffect, useState } from "react";
import Breadcrumbs from "CommonElements/Breadcrumbs";
import { Card, CardBody, Col, Container, Input, Label, Row } from "reactstrap";
import { Form as AntForm, Button, Upload, UploadFile, UploadProps } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Btn } from "AbstractElements";
import { toast } from "react-toastify";
import { SubmitHandler, useForm } from "react-hook-form";
import { documentFileAccept } from "Utils/Constants";

// Services
import * as educationDocumentService from "Services/EducationServices/Document";
import * as educationCategoryService from "Services/EducationServices/Category";
import * as educationSubjectService from "Services/EducationServices/Subject";

// Types
import { Subject } from "Types/Education/Subject";
import { Category } from "Types/Education/Category";

// Components

export default function UpdateDocument() {
  const { t } = useTranslation();
  const [form] = AntForm.useForm();
  const navigate = useNavigate();
  const { id } = useParams<{ id: string | undefined }>();

  const [categoryList, setCategoryList] = useState([] as Category[]);
  const [subjectList, setSubjectList] = useState([] as Subject[]);
  const [showSubjectList, setShowSubjectList] = useState<boolean>(false);

  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [uploading, setUploading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<any>();

  const updateDocument: SubmitHandler<any> = async (formData) => {
    const newData = {
      name: formData.name,
      subject: formData.subject,
      file: fileList[0],
    };
    // const { data, error }: any = await educationDocumentService.updateDocument(
    //   newData
    // );
    // if (error) toast.error(t(`ERRORS.${error?.errorCode}`));
    // else {
    //   toast.success(t("COMMON_MESSAGES.updatedSuccessfully"));
    //   navigate(`${process.env.PUBLIC_URL}/education/document/list`);
    // }
  };

  const getDocument = async () => {
    // const { data, error }: any =
    //   await educationCategoryService.getCategoriesAll(id);
    // if (error) toast.error(t(`ERRORS.${error?.errorCode}`));
    // form.setFieldsValue(data?.body);
  };

  const getCategories = async () => {
    const { data, error }: any =
      await educationCategoryService.getCategoriesAll();
    setCategoryList(data?.body?.content);
    if (error) {
      toast.error(t(`ERRORS.${error?.errorCode}`));
    }
  };

  const getCategorySubjects = async (id: any) => {
    setShowSubjectList(true);
    const { data, error }: any = await educationSubjectService.getSubjectsAll({
      categoryId: id,
    });
    setSubjectList(data?.body?.content);
    if (error) {
      toast.error(t(`ERRORS.${error?.errorCode}`));
    }
  };

  useEffect(() => {
    getDocument();
  }, [id]);

  const documentUploadProps: UploadProps = {
    multiple: false,
    accept: documentFileAccept,
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      setFileList([...fileList, file]);
      return false;
    },
    fileList,
  };

  return (
    <div className="page-body">
      <Breadcrumbs
        pageTitle={t("EducationPages.UpdateDocument")}
        parent={t("EducationPages.Education")}
        subParent={t("EducationPages.DocumentPageTitle")}
        subParentLink={`${process.env.PUBLIC_URL}/education/document/list`}
        title={t("EducationPages.UpdateDocument")}
      />
      <Container fluid className="add-contact-form">
        <Row>
          <Col sm={12}>
            <Card>
              <CardBody>
                <AntForm
                  className="theme-form"
                  onFinish={updateDocument}
                  autoComplete="off"
                >
                  <Row>
                    <Col>
                      <Label>{t("Common.name") + " *"}</Label>
                      <AntForm.Item
                        name="name"
                        validateTrigger={["onChange", "onBlur"]}
                        rules={[
                          {
                            required: true,
                            whitespace: true,
                            message: t(
                              "FormValidations.EducationPages.DocumentNameRequired"
                            ),
                          },
                        ]}
                      >
                        <Input placeholder={`${t("Common.name")}`} />
                      </AntForm.Item>
                    </Col>
                  </Row>
                  {/* <Row>
                    <Col>
                      <Label>{t("EducationPages.Category") + " *"}</Label>
                      <AntForm.Item
                        name="category"
                        validateTrigger={["onChange", "onBlur"]}
                        rules={[
                          {
                            required: true,
                            whitespace: true,
                            message: t(
                              "FormValidations.EducationPages.CategoryRequired"
                            ),
                          },
                        ]}
                      >
                        <select
                          className="form-control"
                          onChange={(e) => getCategorySubjects(e.target.value)}
                        >
                          <option value="">{t("Common.PleaseSelect")}</option>
                          {categoryList.map((cat, ind) => (
                            <option key={ind} value={cat.id}>
                              {cat.name}
                            </option>
                          ))}
                        </select>
                      </AntForm.Item>
                    </Col>
                  </Row>
                  {showSubjectList === true && (
                    <Row>
                      <Col>
                        <Label>{t("EducationPages.Subject") + " *"}</Label>
                        <AntForm.Item
                          name="subject"
                          validateTrigger={["onChange", "onBlur"]}
                          rules={[
                            {
                              required: true,
                              whitespace: true,
                              message: t(
                                "FormValidations.EducationPages.SubjectRequired"
                              ),
                            },
                          ]}
                        >
                          <select className="form-control">
                            <option value="">{t("Common.PleaseSelect")}</option>
                            {subjectList.map((subject, ind) => (
                              <option key={ind} value={subject.id}>
                                {subject.name}
                              </option>
                            ))}
                          </select>
                        </AntForm.Item>
                      </Col>
                    </Row>
                  )} */}
                  <Row>
                    <Col>
                      <Label>{t("EducationPages.DocumentFile") + " *"}</Label>
                      <AntForm.Item
                        name="file"
                        valuePropName="fileList"
                        getValueFromEvent={(e) => e.fileList}
                        // validateTrigger={["onChange", "onBlur"]}
                        rules={[
                          {
                            required: true,
                            message: t(
                              "FormValidations.EducationPages.DocumentFileRequired"
                            ),
                          },
                        ]}
                      >
                        <Upload {...documentUploadProps}>
                          <Button
                            icon={<UploadOutlined />}
                            disabled={fileList.length >= 1}
                          >
                            {t("Common.SelectFile")}
                          </Button>
                        </Upload>
                      </AntForm.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="text-end">
                        <Btn color="success" className="me-3">
                          {t("Common.Save")}
                        </Btn>
                        <Link
                          className="btn btn-danger"
                          to={`${process.env.PUBLIC_URL}/education/document/list`}
                        >
                          {t("Common.cancel")}
                        </Link>
                      </div>
                    </Col>
                  </Row>
                </AntForm>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
