import React from "react";
import Breadcrumbs from "CommonElements/Breadcrumbs";
import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Label,
  Row,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { Btn } from "AbstractElements";
import { toast } from "react-toastify";
import { SubmitHandler, useForm } from "react-hook-form";

// Services
import * as vehicleClassService from "Services/DebitServices/VehicleClass";

export default function AddVehicleClass() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<any>();

  const addVehicleClass: SubmitHandler<any> = async (formData) => {
    const newData = {
      ...formData,
    };
    const { error }: any = await vehicleClassService.addVehicleClass(newData);
    navigate(`${process.env.PUBLIC_URL}/debit/vehicle-class/list`);
    if (error) toast.error(t(`ERRORS.${error?.errorCode}`));
    else toast.success(t("COMMON_MESSAGES.addedSuccessfully"));
  };

  return (
    <div className="page-body">
      <Breadcrumbs
        pageTitle={t("DebitPages.AddVehicleClass")}
        parent={t("DebitPages.Debit")}
        subParent={t("DebitPages.VehicleClassPageTitle")}
        subParentLink={`${process.env.PUBLIC_URL}/debit/vehicle-class/list`}
        title={t("DebitPages.AddVehicleClass")}
      />
      <Container fluid>
        <Row>
          <Col sm={12}>
            <Card>
              <CardBody>
                <Form
                  className="theme-form"
                  onSubmit={handleSubmit(addVehicleClass)}
                >
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>{t("Common.name")}</Label>
                        <input
                          className="form-control"
                          type="text"
                          placeholder={`${t(
                            "DebitPages.VehicleClass.vehicleClassName"
                          )} *`}
                          {...register("name", { required: true })}
                        />
                        <span className="form-error-text">
                          {errors.name &&
                            t("FormValidations.DebitPages.NameRequired")}
                        </span>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>{t("Common.description")}</Label>
                        <textarea
                          className="form-control"
                          cols={30}
                          rows={5}
                          {...register("description", { required: true })}
                        ></textarea>
                        <span className="form-error-text">
                          {errors.description &&
                            t("FormValidations.DebitPages.DescriptionRequired")}
                        </span>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="text-end">
                        <Btn color="success" className="me-3">
                          {t("Common.add")}
                        </Btn>
                        <Link
                          className="btn btn-danger"
                          to={`${process.env.PUBLIC_URL}/debit/vehicle-class/list`}
                        >
                          {t("Common.cancel")}
                        </Link>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
