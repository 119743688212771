import { MessageHeader, ProfileType } from "../../Types/LayoutTypes";

export const cartHeaderData = [
  {
    id: 1,
    src: '06.jpg',
    name: 'Winter T-shirt'
  },
  {
    id: 2,
    src: '02.jpg',
    name: 'Men Shirt'
  },
]


export const messageHeaderData: MessageHeader[] = [
  {
    id: 1,
    src: 'user/3.jpg',
    name: 'Helen Walter',
    text: 'Do you want to go see movie?'
  },
  {
    id: 2,
    src: 'user/6.jpg',
    name: 'Jason Borne',
    text: 'Thank you for rating us.'
  },
  {
    id: 3,
    src: 'user/10.jpg',
    name: 'Sarah Loren',
    text: 'What`s the project report update?'
  },
];

export const profileHeaderData: ProfileType[] = [
  {
    id: 1,
    icon: 'User',
    text: 'ProfilePages.MyProfile',
    url: `${process.env.PUBLIC_URL}/profile/user-profile`
  },
  // {
  //   id: 2,
  //   icon: 'Calendar',
  //   text: 'Calendar',
  //   url: `${process.env.PUBLIC_URL}/applications/calendar-basic`
  // },
  // {
  //   id: 3,
  //   icon: 'CheckSquare',
  //   text: 'Todos',
  //   url: `${process.env.PUBLIC_URL}/applications/to-do`
  // }
]

export const languagesData = [
  {
    data: "en",
    language: "English",
  },
  {
    data: "tr",
    language: "Turkish",
  }
];