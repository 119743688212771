import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import Breadcrumbs from "../../../CommonElements/Breadcrumbs";
import { Container, Row } from "reactstrap";
import RightBackground from "./RightBackground";

export default function DefaultDashboard() {
  const { t } = useTranslation();

  return (
    <Fragment>
      <Breadcrumbs
        pageTitle={t("Dashboard.DashboardTitle")}
        parent={""}
        title={t("Dashboard.DashboardTitle")}
      />
      <Container className="default-dashboard" fluid>
        <Row>
          <RightBackground />
        </Row>
      </Container>
    </Fragment>
  );
}
