import { Fragment } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { routes } from "./Route";
import Layout from "../Layout/Layout";
import { authRoutes } from "./AuthRoutes";
import { usePermissions } from "Hooks/usePermissions";

// dummy permmission list
const userPermissions = ["view_dashboard", "view_works", "view_work_orders"];

export default function LayoutRoutes() {
  const { hasPermission } = usePermissions();

  return (
    <Fragment>
      <Routes>
        {routes.map(({ path, element, permissions }, i) => {
          const hasAccess = hasPermission(userPermissions, permissions || []);
          if (!hasAccess) {
            return (
              <Route
                key={i}
                path={path}
                element={<Navigate to="/dashboard/default" replace />}
              />
            );
          }
          return (
            <Route element={<Layout />} key={i}>
              <Route path={path} element={element} />
            </Route>
          );
        })}
        {authRoutes.map(({ path, element }, i) => (
          <Route key={i} path={path} element={element} />
        ))}
      </Routes>
    </Fragment>
  );
}
