import React, { useEffect, useState } from "react";
import Breadcrumbs from "CommonElements/Breadcrumbs";
import {
  Card,
  CardBody,
  Col,
  Collapse,
  Container,
  Label,
  Row,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import DataTable, { TableColumn } from "react-data-table-component";
import { Href, defaultPageAndSize } from "Utils/Constants";
import { Link } from "react-router-dom";
import { Filter } from "react-feather";
import { P, UL, LI, Btn } from "AbstractElements";
import { toast } from "react-toastify";
import { Spin, Tooltip } from "antd";
import dayjs from "dayjs";
import FilterInput from "Components/Filters/FilterInput";

// Services
import * as laboratoryService from "Services/MetriksServices/Laboratory";
import * as offerService from "Services/MetriksServices/Offer";

// Types
import { ListFilterType } from "Types/Common/ListFilterType";
import { Laboratory } from "Types/Emission/Laboratory";
import CommonModal from "Utils/CommonComponents/UiKitsCommon/CommonModal";
import { pdfBase64File } from "Utils/samplePdfFile";

export default function OfferList() {
  const { t } = useTranslation();

  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const handleFilterToggle = () => {
    setIsFilterOpen((prevState) => !prevState);
  };

  const [showOfferPdfModal, setShowOfferPdfModal] = useState<boolean>(false);
  const toggleShowOfferPdfModal = () => {
    setShowOfferPdfModal(!showOfferPdfModal);
  };
  const showOfferPdfModalData = {
    isOpen: showOfferPdfModal,
    header: true,
    toggler: toggleShowOfferPdfModal,
    title: t("OfferPages.OfferPdfFile"),
    size: "xl",
    bodyClass: "dark-modal",
    center: true,
  };

  const [laboratoryListData, setLaboratoryListData] = useState(
    [] as Laboratory[]
  );
  const [filter, setFilter] = useState(defaultPageAndSize as ListFilterType);
  const [totalRows, setTotalRows] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  const pdfBase64 = pdfBase64File;

  const base64ToBlob = (base64: any, contentType = "", sliceSize = 512) => {
    const byteCharacters = atob(base64.split(",")[1]); // Base64 verisini decode et
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);

      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
  };

  const blob = base64ToBlob(pdfBase64, "application/pdf");
  const blobURL = URL.createObjectURL(blob);

  useEffect(() => {}, []);

  const columns: TableColumn<any>[] = [
    {
      name: t("OfferPages.OfferCode"),
      sortField: "",
      selector: (row) => row.code,
      sortable: true,
      cell: (row) => (
        <P className="f-light-darker">{row.code ? row.code : "-"}</P>
      ),
    },
    {
      name: t("OfferPages.OfferCustomer"),
      sortField: "",
      selector: (row) => row?.customer?.name,
      sortable: true,
      cell: (row) => (
        <P className="f-light-darker">{row?.customer?.name ? row.customer?.name: "-"}</P>
      ),
    },
    {
      name: t("OfferPages.InvoiceType"),
      sortField: "invoiceType",
      selector: (row) => row.invoiceType,
      sortable: true,
      cell: (row) => (
        <P className="f-light-darker">{t(`OfferPages.${row.invoiceType}`)}</P>
      ),
    },
    {
      name: t("Common.status"),
      sortField: "staus",
      width: "200px",
      selector: (row) => row.status,
      sortable: true,
      cell: (row) => <P className="f-light-darker">{t(`OfferPages.${row.status}`)}</P>,
    },
    {
      name: t("Common.createdDate"),
      sortField: "createdDate",
      width: "200px",
      selector: (row) => row.createdDate,
      sortable: true,
      cell: (row) => (
        <P className="f-light-darker">
          {dayjs(row.createdDate).format("DD-MM-YYYY HH:mm")}
        </P>
      ),
    },
    {
      name: t("OfferPages.TotalPrice"),
      sortField: "totalPrice",
      selector: (row) => row.totalPrice,
      sortable: true,
      cell: (row) => (
        <P className="f-light-darker">{row.totalPrice ? row.totalPrice + "₺": "-"}</P>
      ),
    },
    {
      name: t("Common.Actions"),
      width: "90px",
      cell: (row) => (
        <>
          <UL className="action simple-list flex-row">
            <LI className="other me-2">
              <Tooltip placement="bottom" title={t("Common.showFile")}>
                <Link to={``} onClick={() => toggleShowOfferPdfModal()}>
                  <i className="fa fa-file-pdf-o"></i>
                </Link>
              </Tooltip>
            </LI>
            <LI className="edit">
              <Tooltip placement="bottom" title={t("Common.Edit")}>
                <Link
                  to={`${process.env.PUBLIC_URL}/offers/offer/edit/${row.id}`}
                >
                  <i className="icon-pencil-alt" />
                </Link>
              </Tooltip>
            </LI>
            <LI className="delete">
              <Tooltip placement="bottom" title={t("Common.Delete")}>
                <Link onClick={() => deleteOffer(row.id)} to={""}>
                  <i className="icon-trash" />
                </Link>
              </Tooltip>
            </LI>
          </UL>
        </>
      ),
      sortable: false,
    },
  ];

  const [loadingTable, setLoadingTable] = useState(false);
  const [filterObj, setFilterObj] = useState({});
  const [clearSignal, setClearSignal] = useState(false);

  const getOffers = async (filter: ListFilterType) => {
    setLoadingTable(true);
    const { data, error }: any = await offerService.getOffers(
      filter,
      filterObj
    );
    setLoadingTable(false);
    setLaboratoryListData(data?.body?.content);
    setTotalRows(data?.body?.totalElements);
    setTotalPages(data?.body?.totalPages);
    if (error) {
      setLoadingTable(false);
      toast.error(t(`ERRORS.${error?.errorCode}`));
    }
  };

  const handlePageChange = (page: number) => {
    setFilter({
      ...filter,
      page: page - 1,
    });
  };

  const handleRowsPerPageChange = (newRowsPerPage: number) => {
    if (!laboratoryListData.length) return;
    setFilter({
      ...filter,
      size: newRowsPerPage,
    });
  };

  // table sorting
  const handleSortColumn = (column: any, direction: string) => {
    getOffers({
      ...filter,
      sort: [`${column.sortField + "," + direction.toUpperCase()}`],
    });
  };

  const deleteOffer = async (id: string) => {
    const { error }: any = await offerService.deleteOffer(id);
    if (error) toast.error(t(`ERRORS.${error?.errorCode}`));
    else {
      toast.success(t("COMMON_MESSAGES.deletedSuccessfully"));
      getOffers(filter);
    }
  };

  useEffect(() => {
    getOffers(filter);
  }, [filter]);

  // Flter functions
  const searchFunc = (filterObj: any) => {
    setFilterObj({
      ...filterObj,
    });
    getOffers(filter);
  };

  const clearFilter = () => {
    setClearSignal(true);
    setFilterObj({});
  };

  useEffect(() => {
    if (clearSignal) {
      getOffers(filter);
      setTimeout(() => setClearSignal(false), 0);
    }
  }, [clearSignal, filterObj]);

  return (
    <div className="page-body">
      <Breadcrumbs
        pageTitle={t("OfferPages.OfferPageTitle")}
        parent={t("OfferPages.Metriks")}
        title={t("OfferPages.OfferPageTitle")}
      />
      <Container fluid>
        <Row>
          <Col sm={12}>
            <Card>
              <CardBody>
                <div className="list-product-header">
                  <div>
                    <a
                      href={Href}
                      onClick={handleFilterToggle}
                      className="d-none"
                    >
                      <div className="light-box">
                        {isFilterOpen ? (
                          <i className="icon-close filter-close" />
                        ) : (
                          <Filter />
                        )}
                      </div>
                    </a>
                    {/* <Link
                      className="btn btn-primary"
                      to={`${process.env.PUBLIC_URL}/offers/offer/add`}
                    >
                      <i className="fa fa-plus me-2"></i>{" "}
                      {t("OfferPages.AddOffer")}
                    </Link> */}
                  </div>
                  <Collapse className={isFilterOpen ? "show" : ""}>
                    <Card className="list-product-body">
                      <CardBody>
                        <Row className="row-cols-xl-5 row-cols-lg-4 row-cols-md-4 row-cols-sm-2 row-cols-2 g-3">
                          <Col>
                            <Label className="mb-2">{t("Common.name")}</Label>
                            <FilterInput
                              inputType={"text"}
                              inputName={"name"}
                              setFilterObjFun={setFilterObj}
                              clearSignal={clearSignal}
                            />
                          </Col>
                          <Col>
                            <Label className="mb-2">{t("Common.code")}</Label>
                            <FilterInput
                              inputType={"text"}
                              inputName={"code"}
                              setFilterObjFun={setFilterObj}
                              clearSignal={clearSignal}
                            />
                          </Col>
                          <Col>
                            <Label className="mb-2">
                              {t(
                                "ExperimentPages.LaboratoryPages.barcodingCode"
                              )}
                            </Label>
                            <FilterInput
                              inputType={"text"}
                              inputName={"barcodingCode"}
                              setFilterObjFun={setFilterObj}
                              clearSignal={clearSignal}
                            />
                          </Col>
                        </Row>
                        <Row className="mt-4">
                          <Col className="d-flex justify-content-end">
                            <Btn
                              color="default"
                              size="small"
                              onClick={() => clearFilter()}
                            >
                              {t("Common.clearAllFilters")}
                            </Btn>
                            <Btn
                              color="primary"
                              size="small"
                              onClick={() => searchFunc(filterObj)}
                            >
                              <i className="icofont icofont-ui-search"></i>
                            </Btn>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Collapse>
                </div>
                <div className="list-product">
                  <DataTable
                    data={laboratoryListData}
                    columns={columns}
                    progressComponent={<Spin />}
                    progressPending={loadingTable}
                    pagination
                    paginationServer
                    paginationTotalRows={totalRows}
                    paginationPerPage={filter.size}
                    onChangePage={handlePageChange}
                    onChangeRowsPerPage={handleRowsPerPageChange}
                    onSort={handleSortColumn}
                    paginationComponentOptions={{
                      rowsPerPageText: t("Common.rowsPerPage"),
                      rangeSeparatorText: t("Common.rangeSeparatorText"),
                    }}
                    noDataComponent={<>{t("Common.noData")}</>}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <CommonModal modalData={showOfferPdfModalData}>
          <div>
            <div className="my-5">
              <iframe
                src={blobURL}
                title="My PDF"
                width="100%"
                height="1000px"
              />
            </div>
          </div>
        </CommonModal>
      </Container>
    </div>
  );
}
