/* eslint-disable react/jsx-no-undef */
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Card, Col, Container, Row } from "reactstrap";
import Breadcrumbs from "CommonElements/Breadcrumbs";
import ProfileDetail from "./components/ProfileDetail";
import { Image } from "AbstractElements";
import { RootState } from "redux-toolkit/store";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserDetailOwn } from "redux-toolkit/actions/UserActions";
// import { usePermissions } from 'Hooks/usePermissions';

export default function UserProfile() {
  const { t } = useTranslation();

  const dispatch = useDispatch<any>();
  const userInfos = useSelector((state: RootState) => state.user);

  // permission hook example
  // const { hasPermission } = usePermissions();

  useEffect(() => {
    dispatch(fetchUserDetailOwn());
  }, [dispatch]);

  return (
    <div className="page-body">
      <Breadcrumbs
        pageTitle={t("ProfilePages.MyProfile")}
        parent={""}
        title={t("ProfilePages.MyProfile")}
      />
      <Container fluid>
        <div className="user-profile">
          {/* permission hook example */}
          {/* {hasPermission('USER_LIST') && (<Row>
            <Col sm={12}>
              Deneme - UserList var
            </Col>
          </Row>)} */}
          {/* permission hook example */}
          <Row>
            <Col sm={12}>
              <Card className="hovercard text-center">
                <div className="cardheader" />
                <div className="user-image">
                  {(userInfos.picUrl !== null || userInfos.picUrl !== "") ? (
                    <div className="avatar no-image">
                      <div>{userInfos.firstName.charAt(0) + userInfos.lastName.charAt(0)}</div>
                    </div>
                  ) : (
                    <div className="avatar">
                      <Image
                        src={userInfos.picUrl}
                        className="step1"
                        alt={userInfos.firstName + " " + userInfos.lastName}
                      />
                    </div>
                  )}

                  <div className="icon-wrapper d-none">
                    <i className="icofont icofont-pencil-alt-5 step2" />
                  </div>
                </div>
                <ProfileDetail />
              </Card>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
}

