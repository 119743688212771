import React, { useEffect, useState } from "react";
import Breadcrumbs from "CommonElements/Breadcrumbs";
import { Card, CardBody, Col, Container, Input, Label, Row } from "reactstrap";
import { Form as AntForm, Select as AntSelect } from "antd";
import { useTranslation } from "react-i18next";
import {Link, useNavigate, useParams} from "react-router-dom";
import { Btn } from "AbstractElements";
import { toast } from "react-toastify";
import { SubmitHandler, useForm } from "react-hook-form";

// Services
import * as companyService from "Services/CrmServices/Company";
import { Company } from "Types/Crm/Company";
import * as workGroupService from "Services/MetriksServices/WorkGroup";
import WorkLaboratories from "Pages/Metriks/Works/Work/Components/WorkLaboratories";

export default function UpdateWorkGroup() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [savedData, setSavedData] = useState<any>(null);
  const [companyList, setCompanyList] = useState([] as Company[]);

  const { id } = useParams<{ id: string | undefined }>();
  const [form] = AntForm.useForm();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<any>();

  const getWorkGroup = async () => {
    const { data, error }: any = await workGroupService.getWorkGroup(id);
    setSavedData(data?.body);
    if (error) toast.error(t(`ERRORS.${error?.errorCode}`));
    form.setFieldsValue({
      ...data?.body,
      companyId: data?.body?.company?.id
    });
  };

  useEffect(() => {
    getWorkGroup()
  }, []);
  
  const getCompanies = async () => {
    const { data, error }: any = await companyService.getCompaniesAll();
    setCompanyList(data?.body?.content);
    if (error) toast.error(t(`ERRORS.${error?.errorCode}`));
  };

  const updateWorkGroup: SubmitHandler<any> = async (formData) => {
    const updateData: any = {
      description: formData.description
    };
    const { data, error }: any = await workGroupService.updateWorkGroup(updateData);
    if (error) toast.error(t(`ERRORS.${error?.errorCode}`));
    else {
      toast.success(t("COMMON_MESSAGES.addedSuccessfully"));
      setSavedData(data?.body);
    }
  };

  useEffect(() => {
    getCompanies();
  }, []);
  

  return (
    <div className="page-body">
      <Breadcrumbs
        pageTitle={t("ExperimentPages.WorkPages.UpdateWork")}
        parent={t("ExperimentPages.WorkPages.WorkPageTitle")}
        subParentLink={`${process.env.PUBLIC_URL}/emission/work/list`}
        title={t("ExperimentPages.WorkPages.UpdateWork")}
      />
      <Container fluid className="add-contact-form">
        <Row>
          <Col sm={12}>
            <Card>
              <CardBody>
                <AntForm
                  className="theme-form"
                  onFinish={updateWorkGroup}
                  autoComplete="off"
                  form={form}
                >
                  <Row>
                    <Col>
                      <Label>{t("Common.Company") + " *"}</Label>
                      <AntForm.Item
                        name="companyId"
                        validateTrigger={["onChange", "onBlur"]}
                        rules={[
                          {
                            required: true,
                            message: t(
                              "FormValidations.CrmPages.CompanyRequired"
                            ),
                          },
                        ]}
                      >
                        <AntSelect
                          size="large"
                          disabled
                          options={companyList.map((company) => {
                            return { label: company?.name, value: company?.id };
                          })}
                        ></AntSelect>
                      </AntForm.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Label>{t("Common.Note")}</Label>
                      <AntForm.Item
                        name="description"
                        validateTrigger={["onChange", "onBlur"]}
                      >
                        <Input placeholder={`${t("Common.Note")}`} />
                      </AntForm.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="text-end">
                        <Btn color="success" className="me-3">
                          {t("Common.update")}
                        </Btn>
                        <Link
                          className="btn btn-danger"
                          to={`${process.env.PUBLIC_URL}/emission/work/list`}
                        >
                          {t("Common.cancel")}
                        </Link>
                      </div>
                    </Col>
                  </Row>
                </AntForm>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      {savedData?.id ? (
        <div>
          <WorkLaboratories work={savedData} />
        </div>
      ) : null}
    </div>
  );
}
