import * as Paths from "./../files/Paths";
import * as Headers from "./../files/Headers";
import request from "./../files/Request";

// Types
import { ListFilterType } from "Types/Common/ListFilterType";
import {Contact} from "Types/Crm/Contact";

export const getContacts = async (filter: ListFilterType, filterObj: any) => {
  const filterParams = {
    name: filterObj?.name,
    phones: filterObj?.phones,
    email: filterObj?.email,
    companyName: filterObj?.company,
    title: filterObj?.title,
    companyId: filterObj?.companyId,
  };
  return request.get(
    `${Paths.crm}/contact/all`,
    {
      page: filter.page,
      size: filter.size,
      ...(filter?.sort && {sort: filter?.sort[0]}),
      ...filterParams
    },
    { headers: Headers.headers }
  );
};

export const getContact = async (id: string) => {
  return request.get(
    `${Paths.crm}/contact/${id}`, {},
    {
      headers: Headers.headers,
    },
  );
};

export const deleteContact = async (id: string) => {
  return request.delete(
    `${Paths.crm}/contact/${id}`, {},
    {
      headers: Headers.headers,
    }
  );
};

export const addContact = async (contact: Contact) => {
  return request.post(
    `${Paths.crm}/contact`, contact, {},
    {
      headers: Headers.headers,
    }
  );
};

export const updateContact = async (contact: Contact) => {
  return request.put(
    `${Paths.crm}/contact`, contact,
    {
      headers: Headers.headers,
    }
  );
};