export enum WorkRootType {
  CREATE = "CreateWorkRootType",
  DISCOVERY = "DiscoveryWorkRootType",
  OFFER = "OfferWorkRootType",
  PLANNING = "PlanningWorkRootType",
  EQUIPMENT = "EquipmentWorkRootType",
  MEASURE = "MeasureWorkRootType",
  SAMPLE =  "SampleWorkRootType",
  LABORATORY = "LaboratoryWorkRootType",
  REPORT_CHECK = "ReportCheckWorkRootType",
  SIGNING = "SigningWorkRootType",
  PAYMENT = "PaymentWorkRootType",
  REPORT_SENDING = "ReportSendingWorkRootType",
  COMPLETED = "CompletedWorkRootType"
}