import React, { useEffect, useState } from "react";
import Breadcrumbs from "CommonElements/Breadcrumbs";
import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Label,
  Row,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { Btn } from "AbstractElements";
import { toast } from "react-toastify";
import { SubmitHandler, useForm } from "react-hook-form";

// Services
import * as inventoryService from "Services/DebitServices/Inventory";
import * as vehicleService from "Services/DebitServices/Vehicle";
import * as deviceService from "Services/DebitServices/Device";
import * as disposableService from "Services/DebitServices/Disposable";

// Enums
import { ProductTypes } from "Enums/Debit/Product";

export default function AddInventory() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [selectedType, setSelectedType] = useState("");
  const [productList, setProductList] = useState([]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<any>();

  const productTypes = ProductTypes;

  const addInventory: SubmitHandler<any> = async (formData) => {
    const newData = {
      ...formData,
      stock: Number(formData.stock),
      controlPeriod: Number(formData.controlPeriod),
    };
    console.log(newData)
    const { error }: any = await inventoryService.addInventory(newData);
    navigate(`${process.env.PUBLIC_URL}/debit/inventory/list`);
    if (error) toast.error(t(`ERRORS.${error?.errorCode}`));
    else toast.success(t("COMMON_MESSAGES.addedSuccessfully"));
  };

  const getDevices = async () => {
    const { data, error }: any = await deviceService.getDevicesAll();
    setProductList(data?.body?.content);
    if (error) toast.error(error?.meta.errorDescription);
  };

  const getVehicles = async () => {
    const { data, error }: any = await vehicleService.getVehiclesAll();
    setProductList(data?.body?.content);
    if (error) toast.error(error?.meta.errorDescription);
  };

  const getDisposables = async () => {
    const { data, error }: any = await disposableService.getDisposableAll();
    setProductList(data?.body?.content);
    if (error) toast.error(error?.meta.errorDescription);
  };

  const showNamesWithType = (productItem: any) => {
    switch (selectedType) {
      case "DEVICE":
        return productItem?.brand?.name + " " + productItem?.name;
      case "VEHICLE":
        return productItem?.brand?.name + " " + productItem?.model + " " + productItem?.licencePlate;
      case "DISPOSABLE":
        return productItem?.brand?.name + " " + productItem?.name;
    }
  }

  useEffect(() => {
    selectedType === "DEVICE" && getDevices();
    selectedType === "VEHICLE" && getVehicles();
    selectedType === "DISPOSABLE" && getDisposables();
  }, [selectedType]);

  return (
    <div className="page-body">
      <Breadcrumbs
        pageTitle={t("DebitPages.AddInventory")}
        parent={t("DebitPages.Debit")}
        subParent={t("DebitPages.InventoryPageTitle")}
        subParentLink={`${process.env.PUBLIC_URL}/debit/inventory/list`}
        title={t("DebitPages.AddInventory")}
      />
      <Container fluid>
        <Row>
          <Col sm={12}>
            <Card>
              <CardBody>
                <Form
                  className="theme-form"
                  onSubmit={handleSubmit(addInventory)}
                >
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>{t("Common.productType") + " *"}</Label>
                        <select
                          className="form-control"
                          {...register("productType", { required: true })}
                          onChange={(e) => {
                            setSelectedType(e.target.value);
                            register("productType").onChange(e);
                          }}
                        >
                          <option value="">{t("Common.PleaseSelect")}</option>
                          {Object.keys(productTypes).map((key) => (
                            <option key={key} value={key}>
                              {t(
                                `Common.${
                                  ProductTypes[key as keyof typeof ProductTypes]
                                }`
                              )}
                            </option>
                          ))}
                        </select>
                        <span className="form-error-text">
                          {errors.productType &&
                            t("FormValidations.DebitPages.ProductTypeRequired")}
                        </span>
                      </FormGroup>
                    </Col>
                  </Row>
                  {selectedType.length > 0 && (
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label>{t("Common.product") + " *"}</Label>
                          <select
                            className="form-control"
                            {...register("productId", { required: true })}
                          >
                            <option value="">{t("Common.PleaseSelect")}</option>
                            {productList.map((item: any, ind) => (
                              <option key={ind} value={item.id}>
                                {showNamesWithType(item)}
                              </option>
                            ))}
                          </select>
                          <span className="form-error-text">
                            {errors.productId &&
                              t(
                                "FormValidations.DebitPages.ProductTypeRequired"
                              )}
                          </span>
                        </FormGroup>
                      </Col>
                    </Row>
                  )}
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>{t("Common.stock") + " *"}</Label>
                        <input
                          className="form-control"
                          type="number"
                          placeholder={`${t("Common.stock")} *`}
                          {...register("stock", { required: true })}
                        />
                        <span className="form-error-text">
                          {errors.stock &&
                            t("FormValidations.DebitPages.ProductTypeRequired")}
                        </span>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>{t("Common.controlPeriod")}</Label>
                        <input
                          className="form-control"
                          type="number"
                          placeholder={`${t("Common.controlPeriod")}`}
                          {...register("controlPeriod", { required: false })}
                        />
                        <span className="form-error-text">
                          {errors.controlPeriod &&
                            t(
                              "FormValidations.DebitPages.ControlPeriodRequired"
                            )}
                        </span>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>{t("Common.laboratories")}</Label>
                        <textarea
                          className="form-control"
                          cols={30}
                          rows={5}
                          {...register("laboratories", { required: false })}
                        ></textarea>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="text-end">
                        <Btn color="success" className="me-3">
                          {t("Common.add")}
                        </Btn>
                        <Link
                          className="btn btn-danger"
                          to={`${process.env.PUBLIC_URL}/debit/inventory/list`}
                        >
                          {t("Common.cancel")}
                        </Link>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
