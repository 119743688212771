import React, { useState } from "react";
import Breadcrumbs from "CommonElements/Breadcrumbs";
import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Label,
  Row,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { Btn } from "AbstractElements";
import { toast } from "react-toastify";
import { SubmitHandler, useForm } from "react-hook-form";
import { Switch } from "antd";

// Services
import * as permissionService from "Services/ManagementPanelServices/Permission";

export default function AddPermission() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<any>();

  const [enabled, setEnabled] = useState(true);

  const addPermission: SubmitHandler<any> = async (formData) => {
    const newData = {
      ...formData,
      active: enabled,
    };
    const { error }: any = await permissionService.addPermission(newData);
    navigate(`${process.env.PUBLIC_URL}/management/permission/list`);
    if (error) toast.error(t(`ERRORS.${error?.errorCode}`));
    else toast.success(t("COMMON_MESSAGES.addedSuccessfully"));
  };

  return (
    <div className="page-body">
      <Breadcrumbs
        pageTitle={t("UserPages.AddPermission")}
        parent={t("UserPages.ManagementPanel")}
        subParent={t("UserPages.PermissionPageTitle")}
        subParentLink={`${process.env.PUBLIC_URL}/management/permission/list`}
        title={t("UserPages.AddPermission")}
      />
      <Container fluid>
        <Row>
          <Col sm={12}>
            <Card>
              <CardBody>
                <Form
                  className="theme-form"
                  onSubmit={handleSubmit(addPermission)}
                >
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>{t("Common.name")}</Label>
                        <input
                          className="form-control"
                          type="text"
                          placeholder={`${t("UserPages.PermissionName")} *`}
                          {...register("name", { required: true })}
                        />
                        <span className="form-error-text">
                          {errors.name &&
                            t("FormValidations.UserPages.NameRequired")}
                        </span>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup className="d-flex align-items-center h-100">
                        <Label>{t("Common.Enabled")}</Label>
                        <div className="mx-3">
                          <Switch
                            checked={enabled}
                            onChange={(e: any) => {
                              setEnabled(e);
                            }}
                          />
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="text-end">
                        <Btn color="success" className="me-3">
                          {t("Common.add")}
                        </Btn>
                        <Link
                          className="btn btn-danger"
                          to={`${process.env.PUBLIC_URL}/management/permission/list`}
                        >
                          {t("Common.cancel")}
                        </Link>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
