import React, { useEffect, useState } from "react";
import Breadcrumbs from "CommonElements/Breadcrumbs";
import { Card, CardBody, Col, Container, Input, Label, Row } from "reactstrap";
import { Form as AntForm } from "antd";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Btn } from "AbstractElements";
import { toast } from "react-toastify";
import { SubmitHandler, useForm } from "react-hook-form";

// Services
import * as educationCategoryService from "Services/EducationServices/Category";

// Types

// Components
import CategorySubjects from "./Components/CategorySubjects";

export default function UpdateCategory() {
  const { t } = useTranslation();
  const [form] = AntForm.useForm();

  const [savedData, setSavedData] = useState<any>({});
  const { id } = useParams<{ id: string | undefined }>();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<any>();

  const getCategory = async (id: any) => {
    const { data, error }: any = await educationCategoryService.getCategory(id);
    setSavedData(data?.body);
    if (error) toast.error(t(`ERRORS.${error?.errorCode}`));
    form.setFieldsValue(data?.body);
  };

  const updateCategory: SubmitHandler<any> = async (formData) => {
    const updatedData = {
      ...formData,
      id: id,
    };
    const { data, error }: any = await educationCategoryService.updateCategory(
      updatedData
    );
    if (error) toast.error(t(`ERRORS.${error?.errorCode}`));
    else {
      toast.success(t("COMMON_MESSAGES.addedSuccessfully"));
      setSavedData(data?.body);
    }
  };

  useEffect(() => {
    getCategory(id);
  }, [id]);

  return (
    <div className="page-body">
      <Breadcrumbs
        pageTitle={t("EducationPages.UpdateCategory")}
        parent={t("EducationPages.Education")}
        subParent={t("EducationPages.CategoryPageTitle")}
        subParentLink={`${process.env.PUBLIC_URL}/education/category/list`}
        title={t("EducationPages.UpdateCategory")}
      />
      <Container fluid className="add-contact-form">
        <Row>
          <Col sm={12}>
            <Card>
              <CardBody>
                <AntForm
                  className="theme-form"
                  onFinish={updateCategory}
                  autoComplete="off"
                  form={form}
                >
                  <Row>
                    <Col>
                      <Label>{t("EducationPages.CategoryName") + " *"}</Label>
                      <AntForm.Item
                        name="name"
                        validateTrigger={["onChange", "onBlur"]}
                        rules={[
                          {
                            required: true,
                            whitespace: true,
                            message: t(
                              "FormValidations.EducationPages.CategoryNameRequired"
                            ),
                          },
                        ]}
                      >
                        <Input
                          placeholder={`${t("EducationPages.CategoryName")}`}
                        />
                      </AntForm.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="text-end">
                        <Btn color="success" className="me-3">
                          {t("Common.Save")}
                        </Btn>
                        <Link
                          className="btn btn-danger"
                          to={`${process.env.PUBLIC_URL}/education/category/list`}
                        >
                          {t("Common.cancel")}
                        </Link>
                      </div>
                    </Col>
                  </Row>
                </AntForm>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      {savedData?.id ? (
        <div>
          <CategorySubjects category={savedData} />
        </div>
      ) : null}
    </div>
  );
}
