import React, {useEffect, useState} from "react";
import Breadcrumbs from "CommonElements/Breadcrumbs";
import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Label,
  Row,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { Btn } from "AbstractElements";
import { toast } from "react-toastify";
import { SubmitHandler, useForm } from "react-hook-form";
import {Select} from "antd";

// Services
import * as tableService from "Services/MetriksServices/Table";
import * as experimentService from "Services/MetriksServices/Experiment";

export default function AddTable() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [experiments, setExperiments] = useState([] as any[]);
  const [selectedExperiments, setSelectedExperiments] = useState([] as any[]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<any>();

  const addTable: SubmitHandler<any> = async (formData) => {
    const newData = {
      ...formData,
      experiments: selectedExperiments.map((o : any) => {
        return {id: o};
      }),
    };
    const { error }: any = await tableService.addTable(newData);
    navigate(`${process.env.PUBLIC_URL}/experiments/table/list`);
    if (error) toast.error(t(`ERRORS.${error?.errorCode}`));
    else toast.success(t("COMMON_MESSAGES.addedSuccessfully"));
  };

  const getExperiments = async () => {
    const { data, error }: any = await experimentService.getExperimentsAll();
    setExperiments(data?.body?.content);
    if (error) {
      toast.error(t(`ERRORS.${error?.errorCode}`));
    }
  };

  useEffect(() => {
    getExperiments();
  }, []);

  return (
    <div className="page-body">
      <Breadcrumbs
        pageTitle={t("ExperimentPages.TablePages.AddTable")}
        parent={t("ExperimentPages.LaboratoryPages.Metriks")}
        title={t("ExperimentPages.TablePages.AddTable")}
        subParent={t("ExperimentPages.TablePages.TablePageTitle")}
        subParentLink={`${process.env.PUBLIC_URL}/experiments/table/list`}
      />
      <Container fluid>
        <Row>
          <Col sm={12}>
            <Card>
              <CardBody>
                <Form
                  className="theme-form"
                  onSubmit={handleSubmit(addTable)}
                >
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>{t("Common.name") + " *"}</Label>
                        <input
                          className="form-control"
                          type="text"
                          placeholder={`${t("Common.name")} *`}
                          {...register("name", { required: true })}
                        />
                        <span className="form-error-text">
                          {errors.name &&
                            t("FormValidations.ExperimentPages.TablePages.TableNameRequired")}
                        </span>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>{t("Common.description")}</Label>
                        <input
                          className="form-control"
                          type="text"
                          placeholder={`${t("Common.description")}`}
                          {...register("description", { required: false })}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>{t("ExperimentPages.TablePages.ExperimentsParameters")}</Label>
                        <Select
                          mode="multiple"
                          style={{ width: "100%" }}
                          {...register("experiments", { required: false })}
                          onChange={(e) => {
                            setSelectedExperiments(e);
                          }}
                          options={experiments.map((o : any) => {
                            return {label: o.name, value: o.id};
                          })}
                        >
                        </Select>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="text-end">
                        <Btn color="success" className="me-3">
                          {t("Common.add")}
                        </Btn>
                        <Link
                          className="btn btn-danger"
                          to={`${process.env.PUBLIC_URL}/experiments/table/list`}
                        >
                          {t("Common.cancel")}
                        </Link>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
