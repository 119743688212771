import { SidebarMenuType } from "Types/LayoutTypes";


export const menuList: SidebarMenuType[] = [
  {
    title: 'Menu.Dashboard',
    menucontent: 'Menu.Dashboard',
    permissions: ['view_dashboard'],
    menu: [
      {
        id: 1,
        title: 'Menu.Dashboard',
        icon: 'home',
        type: 'link',
        url: `${process.env.PUBLIC_URL}/dashboard/default`,
        permissions: ['view_dashboard'],
      }
    ],
  },
  {
    title: 'Çevre Lab. Yönetİm Modülü',
    menucontent: 'Menu.Works',
    permissions: ['view_works', 'view_work_orders'],
    menu: [
      // {
      //   id: 1,
      //   title: 'Menu.Works',
      //   icon: 'task',
      //   type: 'sub',
      //   active: false,
      //   permissions: ['view_works', 'view_work_orders'],
      //   menu: [
      //     {
      //       title: 'Menu.Works',
      //       type: 'link',
      //       url: `${process.env.PUBLIC_URL}/emission/work/list`,
      //       permissions: ['view_works'],
      //     },
      //     {
      //       title: 'Menu.WorkOrders',
      //       type: 'link',
      //       url: `${process.env.PUBLIC_URL}/emission/work-order/list`,
      //       permissions: ['view_work_orders'],
      //     }
      //   ],
      // },
      // {
      //   id: 2,
      //   title: 'Menu.Laboratory',
      //   icon: 'search',
      //   type: 'sub',
      //   active: false,
      //   menu: [
      //     {
      //       title: 'Menu.Samples',
      //       type: 'link',
      //       url: `${process.env.PUBLIC_URL}/laboratory/sample/list`,
      //     }
      //   ],
      // },
      {
        id: 3,
        title: 'Menu.Experiments',
        icon: 'job-search',
        type: 'sub',
        active: false,
        permissions: [],
        menu: [
          {
            title: 'Menu.Experiments',
            type: 'link',
            url: `${process.env.PUBLIC_URL}/experiments/experiment/list`,
            permissions: [],
          },
          {
            title: 'Menu.Tables',
            type: 'link',
            url: `${process.env.PUBLIC_URL}/experiments/table/list`,
            permissions: [],
          },
          {
            title: 'Menu.AnalysisMethods',
            type: 'link',
            url: `${process.env.PUBLIC_URL}/experiments/analysis-methods/list`,
            permissions: [],
          },
          {
            title: 'Menu.Laboratory',
            type: 'link',
            url: `${process.env.PUBLIC_URL}/experiments/laboratory/list`,
            permissions: [],
          }
        ],
      },
      // {
      //   id: 4,
      //   title: 'Menu.Offer',
      //   icon: 'file',
      //   type: 'sub',
      //   active: false,  
      //   permissions: [],
      //   menu: [
      //     {
      //       title: 'Menu.Offer',
      //       type: 'link',
      //       url: `${process.env.PUBLIC_URL}/offers/offer/list`,
      //       permissions: [],
      //     },
      //   ],
      // },
      // {
      //   id: 4,
      //   title: 'Menu.Plannings',
      //   icon: 'to-do',
      //   type: 'sub',
      //   active: false,
      //   menu: [
      //     {
      //       title: 'Menu.Plannings',
      //       type: 'link',
      //       url: `${process.env.PUBLIC_URL}/plannings/planning/add`,
      //     },
      //   ],
      // }
    ],
  },
  // {
  //   title: 'Menu.DebitSidebar',
  //   menucontent: 'Menu.Debit',
  //   permissions: [],
  //   menu: [
  //     {
  //       id: 1,
  //       title: 'Menu.Debit',
  //       icon: 'bookmark',
  //       type: 'sub',
  //       active: false,
  //       permissions: [],
  //       menu: [
  //         {
  //           title: 'Menu.Brand',
  //           type: 'link',
  //           url: `${process.env.PUBLIC_URL}/debit/brand/list`,
  //           permissions: [],
  //         },
  //         {
  //           title: 'Menu.Debit',
  //           type: 'link',
  //           url: `${process.env.PUBLIC_URL}/debit/main/list`,
  //           permissions: [],
  //         },
  //         {
  //           title: 'Menu.Inventory',
  //           type: 'link',
  //           url: `${process.env.PUBLIC_URL}/debit/inventory/list`,
  //           permissions: [],
  //         },
  //         {
  //           title: 'DebitPages.DevicePageTitle',
  //           type: 'link',
  //           url: `${process.env.PUBLIC_URL}/debit/device/list`,
  //           permissions: [],
  //         },
  //         {
  //           title: 'Menu.Disposable',
  //           type: 'link',
  //           url: `${process.env.PUBLIC_URL}/debit/disposable/list`,
  //           permissions: [],
  //         },
  //         {
  //           title: 'Menu.Vehicle',
  //           type: 'link',
  //           url: `${process.env.PUBLIC_URL}/debit/vehicle/list`,
  //           permissions: [],
  //         },
  //         {
  //           title: 'Menu.VehicleClass',
  //           type: 'link',
  //           url: `${process.env.PUBLIC_URL}/debit/vehicle-class/list`,
  //           permissions: [],
  //         }
  //       ],
  //     }
  //   ],
  // },
  {
    title: 'Menu.CrmSidebar',
    menucontent: 'Menu.Crm',
    permissions: [],
    menu: [
      {
        id: 1,
        title: 'Menu.Crm',
        icon: 'learning',
        type: 'sub',
        active: false,
        permissions: [],
        menu: [
          // {
          //   title: 'Menu.Project',
          //   type: 'link',
          //   url: `${process.env.PUBLIC_URL}/crm/project/list`,
          // },
          {
            title: 'Menu.Company',
            type: 'link',
            url: `${process.env.PUBLIC_URL}/crm/company/list`,
            permissions: [],
          },
          {
            title: 'Menu.Contact',
            type: 'link',
            url: `${process.env.PUBLIC_URL}/crm/contact/list`,
            permissions: [],
          }
        ],
      }
    ],
  },
  // {
  //   title: 'Menu.ManagementSidebar',
  //   menucontent: 'Menu.ManagementPanel',
  //   permissions: [],
  //   menu: [
  //     {
  //       id: 1,
  //       title: 'Menu.ManagementPanel',
  //       icon: 'knowledgebase',
  //       type: 'sub',
  //       active: false,
  //       permissions: [],
  //       menu: [
  //         {
  //           title: 'Menu.Users',
  //           type: 'link',
  //           url: `${process.env.PUBLIC_URL}/management/user/list`,
  //           permissions: [],
  //         },
  //         {
  //           title: 'Menu.UserGroups',
  //           type: 'link',
  //           url: `${process.env.PUBLIC_URL}/management/user-group/list`,
  //           permissions: [],
  //         },
  //         {
  //           title: 'Menu.Permissions',
  //           type: 'link',
  //           url: `${process.env.PUBLIC_URL}/management/permission/list`,
  //           permissions: [],
  //         },
  //         // {
  //         //   title: 'Menu.CompanySettings',
  //         //   type: 'link',
  //         //   url: `${process.env.PUBLIC_URL}/management/company-settings`,
  //         // },
  //         // {
  //         //   title: 'Menu.InvoiceSettings',
  //         //   type: 'link',
  //         //   url: `${process.env.PUBLIC_URL}/management/invoice-settings`,
  //         // },
  //         // {
  //         //   title: 'Menu.EmailSettings',
  //         //   type: 'link',
  //         //   url: `${process.env.PUBLIC_URL}/management/email-settings`,
  //         // },
  //       ],
  //     }
  //   ],
  // },
  // {
  //   title: 'Menu.EducationSidebar',
  //   menucontent: 'Menu.EducationPanel',
  //   permissions: [],
  //   menu: [
  //     {
  //       id: 1,
  //       title: 'Menu.EducationPanel',
  //       icon: 'blog',
  //       type: 'sub',
  //       active: false,
  //       permissions: [],
  //       menu: [
  //         {
  //           title: 'Menu.Insructions',
  //           type: 'link',
  //           url: `${process.env.PUBLIC_URL}/education/instruction/list`,
  //           permissions: [],
  //         },
  //         {
  //           title: 'Menu.Documents',
  //           type: 'link',
  //           url: `${process.env.PUBLIC_URL}/education/document/list`,
  //           permissions: [],
  //         },
  //         {
  //           title: 'Menu.Categories',
  //           type: 'link',
  //           url: `${process.env.PUBLIC_URL}/education/category/list`,
  //           permissions: [],
  //         },
  //       ],
  //     }
  //   ],
  // }
];