import React, { useEffect, useRef, useState } from "react";
import Breadcrumbs from "CommonElements/Breadcrumbs";
import { Card, CardBody, Col, Container, Label, Row } from "reactstrap";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Btn, LI, P, UL } from "AbstractElements";
import { useForm } from "react-hook-form";

import { DatePicker, Divider, Tooltip } from "antd";
import DataTable, { TableColumn } from "react-data-table-component";
import CommonModal from "Utils/CommonComponents/UiKitsCommon/CommonModal";

import { GoogleMap, useLoadScript, MarkerF } from "@react-google-maps/api";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import { toast } from "react-toastify";

// Services
import * as offerService from "Services/MetriksServices/Offer";
import * as vehicleService from "Services/DebitServices/Vehicle";
import * as experimentService from "Services/MetriksServices/Experiment";
import * as tableService from "Services/MetriksServices/Table";

// Types
import { VehicleType } from "Types/Debit/VehicleType";
import { dateFormats, defaultPageAndSizeForAll } from "Utils/Constants";
import dayjs from "dayjs";
import { pdfBase64File } from "Utils/samplePdfFile";

export default function AddOffer() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams<{ id: string | undefined }>();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<any>();

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: String(process.env.REACT_APP_GOOGLE_MAPS_API_KEY),
    libraries: ["places", "geometry"],
  });
  const center = { lat: 39.3344, lng: 36.2357 };

  const {
    ready,
    value,
    setValue,
    suggestions: { status, data },
    clearSuggestions,
  } = usePlacesAutocomplete({});

  const [map, setMap] = useState<any>(null);
  const [directionsService, setDirectionsService] = useState<any>(null);
  const [directionsRenderer, setDirectionsRenderer] = useState<any>(null);
  const [distance, setDistance] = useState<any>(null);
  const [userLocation, setUserLocation] = useState<any>(null);
  const [selectedLocation, setSelectedLocation] = useState<any>(null);
  const [selectedLocPlaceId, setSelectedLocPlaceId] = useState<any>(null);
  const [query, setQuery] = useState("");
  const [suggestionss, setSuggestions] = useState([]);

  // get user location
  useEffect(() => {
    // if (navigator.geolocation) {
    //   navigator.geolocation.getCurrentPosition((position) => {
    //     const { latitude, longitude } = position.coords;
    //     setUserLocation({ lat: latitude, lng: longitude });
    //   });
    // }

    // set default location as Aritsan
    setUserLocation({ lat: 40.7219069, lng: 29.9595689 });
  }, []);

  // load DirectionsService and DirectionsRenderer services
  useEffect(() => {
    if (map && !directionsService) {
      setDirectionsService(new window.google.maps.DirectionsService());
      setDirectionsRenderer(new window.google.maps.DirectionsRenderer());
    }

    ready && getLocationDetails(selectedLocPlaceId);
  }, [map]);

  const handleSelect = async (description: string, placeId: string) => {
    setValue(description, false);
    clearSuggestions();
    const result = await getGeocode({ address: description });
    const { lat, lng } = await getLatLng(result[0]);

    console.log(placeId, value);

    setSelectedLocPlaceId(placeId);

    setQuery(description);
    setSuggestions([]);

    getLocationDetails(placeId);
  };

  const getLocationDetails = (placeId: string) => {
    // Get details with Google Places API service
    const service = new window.google.maps.places.PlacesService(map);
    service.getDetails({ placeId }, (place, status) => {
      if (status === window.google.maps.places.PlacesServiceStatus.OK) {
        const location = place?.geometry?.location;
        setSelectedLocation(location);

        // Calculate distance
        if (userLocation) {
          const userLatLng = new window.google.maps.LatLng(
            userLocation.lat,
            userLocation.lng
          );
          const selectedLatLng = location as any;
          const distanceInMeters =
            window.google.maps.geometry.spherical.computeDistanceBetween(
              userLatLng,
              selectedLatLng
            );
          setDistance(distanceInMeters);
          setTransportAndAccommodation({
            transportOptions: [
              {
                ...transportAndAccommodation.transportOptions[0],
                distance: distanceInMeters,
                location: {
                  description: value,
                  selectedLocPlaceId: selectedLocPlaceId,
                },
              },
            ],
            accommodationPrice: transportAndAccommodation.accommodationPrice,
          });
          console.log(selectedLatLng);
        }

        // Create direction receipe
        if (directionsService && directionsRenderer) {
          directionsRenderer.setMap(map); // Add Renderer to map
          const request = {
            origin: userLocation,
            destination: location,
            travelMode: window.google.maps.TravelMode.DRIVING, // Travel with car
          };

          directionsService.route(request, (result: any, status: any) => {
            if (status === window.google.maps.DirectionsStatus.OK) {
              directionsRenderer.setDirections(result); // Draw direction receipe on map
            } else {
              console.error("Directions request failed due to " + status);
            }
          });
        }

        map.setZoom(16);
        map.panTo(location);
      }
    });
  };

  const [addTableModal, setAddTableModal] = useState<boolean>(false);
  const toggleAddTableModal = () => {
    setAddTableModal(!addTableModal);
  };
  const addTableModalData = {
    isOpen: addTableModal,
    header: true,
    toggler: toggleAddTableModal,
    title: t("ExperimentPages.TablePages.AddTable"),
    size: "xl",
    bodyClass: "dark-modal",
    center: true,
  };

  const [generalSettingsModal, setGeneralSettingsModal] =
    useState<boolean>(false);
  const toggleGeneralSettingsModal = () => {
    setGeneralSettingsModal(!generalSettingsModal);
  };
  const generalSettingsModalData = {
    isOpen: generalSettingsModal,
    header: true,
    toggler: toggleGeneralSettingsModal,
    title: t("OfferPages.GeneralSettings"),
    size: "xl",
    bodyClass: "dark-modal",
    center: true,
  };

  const [addDiscountModal, setAddDiscountModal] = useState<boolean>(false);
  const toggleAddDiscountModal = () => {
    setAddDiscountModal(!addDiscountModal);
  };
  const addDiscountModalData = {
    isOpen: addDiscountModal,
    header: true,
    toggler: toggleAddDiscountModal,
    title: t("OfferPages.Discount"),
    size: "xl",
    bodyClass: "dark-modal",
    center: true,
  };

  const [addReportPriceModal, setAddReportPriceModal] =
    useState<boolean>(false);
  const toggleAddReportPriceModal = () => {
    setAddReportPriceModal(!addReportPriceModal);
  };
  const addReportPriceModalData = {
    isOpen: addReportPriceModal,
    header: true,
    toggler: toggleAddReportPriceModal,
    title: t("OfferPages.ReportPrice"),
    size: "xl",
    bodyClass: "dark-modal",
    center: true,
  };

  const [
    addTransportAndAccommodationModal,
    setAddTransportAndAccommodationModal,
  ] = useState<boolean>(false);
  const toggleAddTransportAndAccommodationModal = () => {
    setAddTransportAndAccommodationModal(!addTransportAndAccommodationModal);
    if (addTransportAndAccommodationModal) {
      setValue("");
      ready && getLocationDetails(selectedLocPlaceId);
    }
  };
  const addTransportAndAccommodationModalData = {
    isOpen: addTransportAndAccommodationModal,
    header: true,
    toggler: toggleAddTransportAndAccommodationModal,
    title: t("OfferPages.TransportAndAccommodation"),
    size: "xl",
    bodyClass: "dark-modal",
    center: true,
  };

  const [addExperimentModal, setAddExperimentModal] = useState<boolean>(false);
  const toggleAddExperimentModal = () => {
    setAddExperimentModal(!addExperimentModal);
  };
  const addExperimentModalData = {
    isOpen: addExperimentModal,
    header: true,
    toggler: toggleAddExperimentModal,
    title: t("OfferPages.AddExperiment"),
    size: "xl",
    bodyClass: "dark-modal",
    center: true,
  };

  const [showOfferPdfModal, setShowOfferPdfModal] = useState<boolean>(false);
  const toggleShowOfferPdfModal = () => {
    setShowOfferPdfModal(!showOfferPdfModal);
  };
  const showOfferPdfModalData = {
    isOpen: showOfferPdfModal,
    header: true,
    toggler: toggleShowOfferPdfModal,
    title: t("OfferPages.OfferPdfFile"),
    size: "xl",
    bodyClass: "dark-modal",
    center: true,
  };

  const [vehicleListData, setVehicleListData] = useState([] as VehicleType[]);
  const [selectedVehicle, setSelectedVehicle] = useState("");
  const [addOfferResponseBody, setAddOfferResponseBody] = useState<any>(null);

  const getVehicles = async () => {
    const { data, error }: any = await vehicleService.getVehiclesAll();
    setVehicleListData(data?.body?.content);
    if (error) {
      toast.error(t(`ERRORS.${error?.errorCode}`));
    }
  };

  useEffect(() => {
    getVehicles();
  }, []);

  const [experimentListData, setExperimentListData] = useState([] as any[]);
  const getExperiments = async () => {
    const { data, error }: any = await experimentService.getExperiments(
      {
        page: defaultPageAndSizeForAll.page,
        size: defaultPageAndSizeForAll.size,
      },
      {}
    );
    setExperimentListData(data?.body?.content);
    if (error) {
      toast.error(t(`ERRORS.${error?.errorCode}`));
    }
  };

  const [experimentTableListData, setExperimentTableListData] = useState(
    [] as any[]
  );
  const getExperimentTables = async () => {
    const { data, error }: any = await tableService.getTablesForOffer();
    setExperimentTableListData(data?.body?.content);
    if (error) {
      toast.error(t(`ERRORS.${error?.errorCode}`));
    }
  };

  useEffect(() => {
    getExperiments();
    getExperimentTables();
  }, []);

  useEffect(() => {
    if (addTransportAndAccommodationModal && selectedLocation) {
      directionsRenderer.setMap(map);
    }
  }, [addTransportAndAccommodationModal, selectedLocation]);

  const [selectedExperiments, setSelectedExperiments] = useState([] as any[]);
  // [
  //   {
  //     id: "1",
  //     name: "Tanecikli Maddenin (TOZ) Kütle Derişiminin Tayini",
  //     code: "AK",
  //     unitPrice: 500,
  //     unit: 1,
  //     totalPrice: 500,
  //   },
  //   {
  //     id: "2",
  //     name: "Gaz Halindeki Toplam Organik Karbonun (TOC) Kütle Derişimin Tayini",
  //     code: "KD",
  //     unitPrice: 1500,
  //     unit: 2,
  //     totalPrice: 3000,
  //   },
  // ]

  const [selectedTables, setSelectedTables] = useState([] as any[]);
  // [
  //   {
  //     id: 1,
  //     name: "Table-9.1",
  //     experiments: [
  //       {
  //         id: "1",
  //         tableId: 1,
  //         name: "Tanecikli Maddenin (TOZ) Kütle Derişiminin Tayini",
  //         code: "AK",
  //         unitPrice: 500,
  //         unit: 1,
  //         totalPrice: 500,
  //       },
  //       {
  //         id: "2",
  //         tableId: 1,
  //         name: "Gaz Halindeki Toplam Organik Karbonun (TOC) Kütle Derişimin Tayini",
  //         code: "KD",
  //         unitPrice: 1500,
  //         unit: 2,
  //         totalPrice: 3000,
  //       },
  //     ],
  //   },
  //   {
  //     id: 2,
  //     name: "Table-9.2",
  //     experiments: [
  //       {
  //         id: "1",
  //         tableId: 2,
  //         name: "Tanecikli Maddenin (TOZ) Kütle Derişiminin Tayini",
  //         code: "AK",
  //         unitPrice: 500,
  //         unit: 1,
  //         totalPrice: 500,
  //       },
  //       {
  //         id: "2",
  //         tableId: 2,
  //         name: "Gaz Halindeki Toplam Organik Karbonun (TOC) Kütle Derişimin Tayini",
  //         code: "KD",
  //         unitPrice: 1500,
  //         unit: 2,
  //         totalPrice: 3000,
  //       },
  //     ],
  //   },
  // ]

  const [transportAndAccommodation, setTransportAndAccommodation] = useState({
    transportOptions: [
      {
        vehicleId: "",
        distance: distance,
        fuelPrice: 0,
        consumeRate: 0,
        location: {
          description: value,
          selectedLocPlaceId: selectedLocPlaceId ? selectedLocPlaceId : "",
        },
      },
    ],
    accommodationPrice: 0,
  });

  const [discount, setDiscount] = useState({
    discountRate: 0,
    discountPrice: 0,
  });
  const [vatRate, setVatRate] = useState(20);

  const [reportPrice, setReportPrice] = useState(0);

  const [generalSettings, setGeneralSettings] = useState<any>({
    invoiceType: "INVOICED",
  });

  const [selectedtRows, setSelectedRows] = useState([]);
  const handleSelectedRowsChange = (rows: any) => {
    setSelectedRows(rows.selectedRows);
  };

  const addExperimentsToTable = () => {
    const updatedSelectedRowData = selectedtRows?.map((row: any) => {
      return {
        id: row.id,
        name: row.name,
        code: row.code,
        unitPrice: row.price,
        unit: 1,
        totalPrice: row.price,
      };
    });
    setSelectedExperiments([...selectedExperiments, ...updatedSelectedRowData]);
    toggleAddExperimentModal();
    setSelectedRows([]);
  };

  const addTablesToTable = () => {
    const updatedSelectedRowData = selectedtRows?.map((row: any) => {
      return {
        id: row.id,
        name: row.name,
        code: row.code,
        experiments: row.experiments?.map((exp: any) => {
          return {
            ...exp,
            unit: 1,
            unitPrice: exp.price,
            totalPrice: exp.price,
            tableId: row.id,
          };
        }),
      };
    });
    setSelectedTables([...selectedTables, ...updatedSelectedRowData]);
    toggleAddTableModal();
    setSelectedRows([]);
  };

  const handleUnitChange = (experimentId: any, tableId: any, unitVal: any) => {
    if (!tableId) {
      setSelectedExperiments((prevList: any) =>
        prevList.map((item: any) =>
          item.id === experimentId
            ? { ...item, unit: unitVal, totalPrice: item.unitPrice * unitVal }
            : item
        )
      );
    } else {
      setSelectedTables((prevTables) =>
        prevTables.map((table) =>
          table.id === tableId
            ? {
                ...table,
                experiments: table.experiments.map((experiment: any) =>
                  experiment.id === experimentId
                    ? {
                        ...experiment,
                        unit: unitVal,
                        totalPrice: experiment.unitPrice * unitVal,
                      }
                    : experiment
                ),
              }
            : table
        )
      );
    }
  };

  const handleUnitPriceChange = (
    experimentId: any,
    tableId: any,
    unitPriceVal: any
  ) => {
    if (!tableId) {
      setSelectedExperiments((prevList: any) =>
        prevList.map((item: any) =>
          item.id === experimentId
            ? {
                ...item,
                unitPrice: unitPriceVal,
                totalPrice: item.unit * unitPriceVal,
              }
            : item
        )
      );
    } else {
      setSelectedTables((prevTables) =>
        prevTables.map((table) =>
          table.id === tableId
            ? {
                ...table,
                experiments: table.experiments.map((experiment: any) =>
                  experiment.id === experimentId
                    ? {
                        ...experiment,
                        unitPrice: unitPriceVal,
                        totalPrice: experiment.unit * unitPriceVal,
                      }
                    : experiment
                ),
              }
            : table
        )
      );
    }
  };

  const handleDelete = (experimentId: any, tableId: any) => {
    if (tableId && experimentId) {
      setSelectedTables((prevTables) =>
        prevTables
          .map((table) =>
            table.id === tableId
              ? {
                  ...table,
                  experiments: table.experiments.filter(
                    (exp: any) => exp.id !== experimentId
                  ),
                }
              : table
          )
          .filter(
            (table) => table.experiments.length > 0 || table.id !== tableId
          )
      );
    } else if (tableId) {
      setSelectedTables((prevTables) =>
        prevTables.filter((table) => table.id !== tableId)
      );
    } else if (experimentId) {
      setSelectedExperiments((prevExperiments) =>
        prevExperiments.filter((exp: any) => exp.id !== experimentId)
      );
    }
  };

  const calculateTableSubTotal = (tableId: any) => {
    const table = selectedTables.find((t) => t.id === tableId);
    if (table) {
      return table.experiments?.reduce(
        (sum: any, experiment: any) => sum + experiment?.totalPrice,
        0
      );
    }
    return 0;
  };

  const calculateTotalExperimentPrice = () => {
    const tablesTotal = selectedTables?.reduce(
      (sum, table) =>
        sum +
        table?.experiments?.reduce(
          (expSum: any, exp: any) => expSum + exp.totalPrice,
          0
        ),
      0
    );

    const experimentsTotal = selectedExperiments?.reduce(
      (sum, exp: any) => sum + exp.totalPrice,
      0
    );
    return Number((tablesTotal + experimentsTotal).toFixed(2));
  };

  const calculateTransportAndAccommodationPrice = () => {
    if (Object.keys(transportAndAccommodation).length === 0) return 0;

    let totalPrice = transportAndAccommodation.accommodationPrice;

    transportAndAccommodation.transportOptions.forEach((option: any) => {
      totalPrice +=
        ((option.distance * option.consumeRate) / 100000) *
        option.fuelPrice *
        2;
    });

    return Number(totalPrice.toFixed(2));
  };

  const calculateDiscount = () => {
    let totalDiscount = discount.discountPrice;
    totalDiscount +=
      ((calculateTotalExperimentPrice() +
        calculateTransportAndAccommodationPrice() -
        discount.discountPrice) *
        discount.discountRate) /
      100;
    return Number(totalDiscount.toFixed(2));
  };

  const calculateReportPrice = () => {
    return Number(reportPrice.toFixed(2));
  };

  const calculateVAT = () => {
    let vat =
      ((calculateTotalExperimentPrice() +
        calculateTransportAndAccommodationPrice() +
        calculateReportPrice() -
        calculateDiscount()) *
        vatRate) /
      100;
    return Number(vat.toFixed(2));
  };

  const calculateTotalPrice = () => {
    let total =
      calculateTotalExperimentPrice() +
      calculateTransportAndAccommodationPrice() +
      calculateVAT() -
      calculateDiscount();
    return Number(total.toFixed(2));
  };

  const columns: TableColumn<any>[] = [
    {
      name: t("OfferPages.Experiment"),
      sortField: "name",
      selector: (row) => row.name,
      sortable: false,
      cell: (row) => <P className="f-light">{row.name}</P>,
    },
    {
      name: t("Common.code"),
      sortField: "code",
      width: "120px",
      selector: (row) => row.code,
      sortable: false,
      center: true,
      cell: (row) => <P className="f-light">{row.code}</P>,
    },
    {
      name: t("OfferPages.UnitPrice"),
      sortField: "unitPrice",
      width: "150px",
      selector: (row) => row.unitPrice,
      sortable: false,
      cell: (row) => (
        <input
          className="form-control"
          type="number"
          placeholder={`${t("OfferPages.UnitPrice")}`}
          defaultValue={row.unitPrice}
          onChange={(event) =>
            handleUnitPriceChange(row.id, row.tableId, event.target.value)
          }
        />
      ),
    },
    {
      name: t("OfferPages.Unit"),
      sortField: "unit",
      width: "100px",
      selector: (row) => row.unit,
      sortable: false,
      cell: (row) => (
        <input
          className="form-control"
          type="number"
          placeholder={`${t("OfferPages.Unit")}`}
          defaultValue={row.unit}
          onChange={(event) =>
            handleUnitChange(row.id, row.tableId, event.target.value)
          }
        />
      ),
    },
    {
      name: t("OfferPages.TotalPrice"),
      sortField: "totalPrice",
      right: true,
      width: "150px",
      selector: (row) => row.totalPrice,
      sortable: false,
      cell: (row) => (
        <P className="f-light">{formatCurrency(row.totalPrice)}</P>
      ),
    },
    {
      name: "",
      width: "30px",
      sortable: false,
      cell: (row) => (
        <>
          {row?.id ? (
            <>
              <UL className="action simple-list flex-row">
                <LI className="delete">
                  <Tooltip placement="bottom" title={t("Common.Delete")}>
                    <Link
                      onClick={() => handleDelete(row.id, row.tableId)}
                      to={""}
                    >
                      <i className="icon-trash" />
                    </Link>
                  </Tooltip>
                </LI>
              </UL>
            </>
          ) : null}
        </>
      ),
    },
  ];
  const modalExperimentColumns: TableColumn<any>[] = [
    {
      name: t("OfferPages.Experiment"),
      sortField: "name",
      selector: (row) => row.name,
      sortable: false,
      cell: (row) => <div className="f-light">{row.name}</div>,
    },
    {
      name: t("Common.code"),
      sortField: "code",
      width: "120px",
      selector: (row) => row.code,
      sortable: false,
      center: true,
      cell: (row) => <div className="f-light">{row.code}</div>,
    },
    {
      name: t("OfferPages.TotalPrice"),
      sortField: "price",
      right: true,
      width: "150px",
      selector: (row) => row.price,
      sortable: false,
      cell: (row) => <div className="f-light">{formatCurrency(row.price)}</div>,
    },
  ];
  const modalExperimentTableColumns: TableColumn<any>[] = [
    {
      name: t("Common.name"),
      sortField: "name",
      selector: (row) => row.name,
      sortable: false,
      cell: (row) => <div className="f-light">{row.name}</div>,
    },
    {
      name: t("OfferPages.ExperimentCount"),
      sortField: "experimentCount",
      width: "120px",
      selector: (row) => row.experimentCount,
      sortable: false,
      center: true,
      cell: (row) => <div className="f-light">{row.experimentCount}</div>,
    },
    {
      name: t("OfferPages.TotalPrice"),
      sortField: "totalAmount",
      right: true,
      width: "150px",
      selector: (row) => row.totalAmount,
      sortable: false,
      cell: (row) => (
        <div className="f-light">{formatCurrency(row.totalAmount)}</div>
      ),
    },
  ];

  const renderExperimentTable = () => {
    return selectedExperiments?.length > 0 ? (
      <div className="list-product">
        <DataTable
          data={selectedExperiments}
          columns={columns}
          pagination={false}
          noDataComponent={<>{t("Common.noData")}</>}
        />
      </div>
    ) : null;
  };

  const formatCurrency = (value: any) => {
    return new Intl.NumberFormat("tr-TR", {
      style: "currency",
      currency: "TRY",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(value);
  };

  const renderTables = () => {
    return selectedTables?.length > 0
      ? selectedTables?.map((table, index) => (
          <div className="list-product" key={index}>
            <div className="experiment-table-head d-flex align-items-center justify-content-between">
              <span>
                {table.name} ({t("OfferPages.SubTotal")}:{" "}
                {formatCurrency(calculateTableSubTotal(table.id))})
              </span>
              <>
                <UL className="action simple-list flex-row">
                  <LI className="delete">
                    <Tooltip placement="bottom" title={t("Common.Delete")}>
                      <Link
                        onClick={() => handleDelete(null, table.id)}
                        to={""}
                      >
                        <i className="icon-trash" />
                      </Link>
                    </Tooltip>
                  </LI>
                </UL>
              </>
            </div>
            <DataTable
              data={table.experiments}
              columns={columns}
              noTableHead={handleTableHeaders(index)}
              pagination={false}
              noDataComponent={<>{t("Common.noData")}</>}
            />
          </div>
        ))
      : null;
  };

  const handleTableHeaders = (index: any) => {
    if (selectedExperiments?.length > 0) {
      return true;
    } else if (index > 0) {
      return true;
    }
    return false;
  };

  const addOffer = async () => {
    const addOfferData = {
      workId: "fb4a01e3-d767-4014-ad87-47f696c233b4",
      workOrderId: id,
      transportationOptions: [
        {
        ...transportAndAccommodation.transportOptions[0],
        vehicleId: selectedVehicle,
        },
      ],
      transportAndAccommodationPrice: calculateTransportAndAccommodationPrice(),
      totalPriceWithoutVAT: calculateTotalExperimentPrice(),
      totalDiscount: calculateDiscount(),
      totalVAT: calculateVAT(),
      totalPrice: calculateTotalPrice(),
      discountRate: discount.discountRate,
      discountPrice: discount.discountPrice,
      reportPrice: reportPrice,
      accommodationPrice: transportAndAccommodation.accommodationPrice,
      experimentUnits: selectedExperiments?.map((exp: any) => {
        return {
          experiment: { id: exp.id },
          unit: exp.unit,
          unitPrice: exp.unitPrice,
        };
      }),
      tableUnits: selectedTables?.map((tbl) => {
        return {
          experimentTable: {
            id: tbl.id,
          },
          experimentUnits: tbl.experiments.map((tblExp: any) => {
            return {
              unitPrice: tblExp.unitPrice,
              unit: tblExp.unit,
              experiment: {
                id: tblExp.id,
              },
            };
          }),
        };
      }),
      offerDate: generalSettings?.offerDate
        ? generalSettings?.offerDate
        : dayjs().format(dateFormats.D_M_Y_H_m_s_T),
      invoiceType: generalSettings?.invoiceType,
      description: generalSettings?.description,
    };

    const { data, error }: any = await offerService.addOffer(addOfferData);
    setAddOfferResponseBody(data);
    if (error) toast.error(t(`ERRORS.${error?.errorCode}`));
    else {
      toast.success(t("COMMON_MESSAGES.addedSuccessfully"));
      // navigate(`${process.env.PUBLIC_URL}/offers/offer/list`);
      navigate(`${process.env.PUBLIC_URL}/offers/offer/edit/${data?.body.id}`)
    }
  };

  const pdfBase64 = pdfBase64File;

  const base64ToBlob = (base64: any, contentType = "", sliceSize = 512) => {
    const byteCharacters = atob(base64.split(",")[1]); // Base64 verisini decode et
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);

      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
  };

  const blob = base64ToBlob(pdfBase64, "application/pdf");
  const blobURL = URL.createObjectURL(blob);

  // @ts-ignore
  return (
    <div className="page-body offer-page">
      <Breadcrumbs
        pageTitle={t("OfferPages.AddOffer")}
        parent={t("OfferPages.Metriks")}
        subParent={t("OfferPages.OfferPageTitle")}
        subParentLink={`${process.env.PUBLIC_URL}/offers/offer/list`}
        title={t("OfferPages.AddOffer")}
      />
      <Container fluid>
        <Row>
          <Col sm={12}>
            <Card>
              <CardBody>
                <div className="list-product-header d-flex align-items-center justify-content-between">
                  <div>
                    <Tooltip
                      placement="bottom"
                      title={t("OfferPages.GeneralSettings")}
                    >
                      <Link
                        className="btn btn-outline-primary"
                        to={``}
                        onClick={() => toggleGeneralSettingsModal()}
                      >
                        <i className="fa fa-cog p-0"></i>
                      </Link>
                    </Tooltip>
                  </div>
                  <div>
                    <Link
                      className="btn btn-outline-info"
                      to={``}
                      onClick={() => toggleAddTableModal()}
                    >
                      <i className="fa fa-plus me-2"></i>{" "}
                      {t("OfferPages.AddTable")}
                    </Link>
                    <Link
                      className="btn btn-outline-secondary"
                      to={``}
                      onClick={() => toggleAddExperimentModal()}
                    >
                      <i className="fa fa-plus me-2"></i>{" "}
                      {t("OfferPages.AddExperiment")}
                    </Link>
                  </div>
                </div>
                {renderExperimentTable()}
                {renderTables()}
                <Divider style={{ borderColor: "#aa9e9e" }} />
                <div className="price-item d-flex align-items-center justify-content-between">
                  <span>{t("OfferPages.TotalPriceWithoutVAT")}</span>
                  <span className="me-4">
                    {formatCurrency(calculateTotalExperimentPrice())}
                  </span>
                </div>
                <div className="price-item d-flex align-items-center justify-content-between">
                  <div className="flex align-content-center">
                    <span>
                      {t("OfferPages.TransportAndAccommodationPrice")}
                    </span>
                    <Link
                      className="btn btn-xs btn-outline-primary ms-2"
                      to={``}
                      onClick={() => toggleAddTransportAndAccommodationModal()}
                    >
                      <i className="fa fa-plus"></i>
                      {""}
                    </Link>
                  </div>
                  <span className="me-4">
                    {formatCurrency(calculateTransportAndAccommodationPrice())}
                  </span>
                </div>
                <div className="price-item d-flex align-items-center justify-content-between">
                  <span>
                    {t("OfferPages.Discount")}
                    <Link
                      className="btn btn-xs btn-outline-primary ms-2"
                      to={``}
                      onClick={() => toggleAddDiscountModal()}
                    >
                      <i className="fa fa-plus"></i>
                      {""}
                    </Link>
                  </span>
                  <span className="me-4">
                    {formatCurrency(calculateDiscount())}
                  </span>
                </div>
                <div className="price-item d-flex align-items-center justify-content-between">
                  <span>
                    {t("OfferPages.ReportPrice")}
                    <Link
                      className="btn btn-xs btn-outline-primary ms-2"
                      to={``}
                      onClick={() => toggleAddReportPriceModal()}
                    >
                      <i className="fa fa-plus"></i>
                      {""}
                    </Link>
                  </span>
                  <span className="me-4">
                    {formatCurrency(calculateReportPrice())}
                  </span>
                </div>
                <div className="price-item d-flex align-items-center justify-content-between">
                  <div className="flex align-content-center">
                    <span>{t("OfferPages.TotalVAT")}</span>
                  </div>
                  <span className="me-4">{formatCurrency(calculateVAT())}</span>
                </div>
                <div className="price-item price-item-subtotal d-flex align-items-center justify-content-between">
                  <div className="flex align-content-center">
                    <span className="fw-bold">
                      {t("OfferPages.GrandTotal")}
                    </span>
                  </div>
                  <span className="me-4 fw-bold">
                    {formatCurrency(calculateTotalPrice())}
                  </span>
                </div>
                <Row className="mt-3">
                  <Col className="d-flex justify-content-between">
                    {addOfferResponseBody?.id && (
                      <div className="text-start">
                        <Tooltip
                          placement="bottom"
                          title={t("Common.showFile")}
                        >
                          <Link
                            className="btn btn-outline-primary"
                            to={``}
                            onClick={() => toggleShowOfferPdfModal()}
                          >
                            <i className="fa fa-file-pdf-o"></i>
                          </Link>
                        </Tooltip>
                      </div>
                    )}
                    <div className="text-end w-100">
                      <Btn
                        color="success"
                        className="me-3"
                        onClick={() => addOffer()}
                      >
                        {t("Common.add")}
                      </Btn>
                      <Link
                        className="btn btn-danger"
                        to={`${process.env.PUBLIC_URL}/offers/offer/list`}
                      >
                        {t("Common.cancel")}
                      </Link>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <CommonModal modalData={generalSettingsModalData}>
          <Row>
            <Col xs={12}>
              <Label>{t("OfferPages.OfferDate")}</Label>
              <DatePicker
                placeholder={t("Common.OfferDate")}
                format={dateFormats.D_M_Y_H_m}
                className={`form-control mb-2`}
                value={dayjs(generalSettings?.offerDate)}
                showTime
                onChange={(e: any) => {
                  setGeneralSettings({
                    ...generalSettings,
                    offerDate: dayjs(e).format(dateFormats.D_M_Y_H_m_s_T),
                  });
                }}
              />
            </Col>
            <Col xs={12}>
              <Label>{t("OfferPages.Invoice")}</Label>
              <select
                className="form-control mb-2"
                defaultValue={generalSettings?.invoiceType}
                onChange={(e) => {
                  setGeneralSettings({
                    ...generalSettings,
                    invoiceType: e.target.value,
                  });
                }}
              >
                <option value="">{t("Common.PleaseSelect")}</option>
                <option key={1} value={"SAVED"}>
                  {t("OfferPages.InvoiceWillBeIssue")}
                </option>
                <option key={1} value={"FREE"}>
                  {t("Common.free")}
                </option>
              </select>
            </Col>
            <Col xs={12}>
              <Label>{t("Common.description")}</Label>
              <input
                className="form-control mb-2"
                type="text"
                placeholder={`${t("Common.description")}`}
                defaultValue={generalSettings?.description}
                onChange={(e) => {
                  setGeneralSettings({
                    ...generalSettings,
                    description: e.target.value,
                  });
                }}
              />
            </Col>
          </Row>
        </CommonModal>
        <CommonModal modalData={addDiscountModalData}>
          <Row>
            <Col xs={12} sm={6}>
              <Label>{t("OfferPages.DiscountAmount")}</Label>
              <input
                className="form-control"
                type="number"
                placeholder={`${t("OfferPages.UnitPrice")}`}
                defaultValue={discount.discountPrice}
                onChange={(e) => {
                  setDiscount({
                    ...discount,
                    discountPrice: Number(e.target.value),
                  });
                }}
              />
            </Col>
            <Col xs={12} sm={6}>
              <Label>{t("OfferPages.DiscountRate")}</Label>
              <input
                className="form-control"
                type="number"
                placeholder={`${t("OfferPages.UnitPrice")}`}
                defaultValue={discount.discountRate}
                onChange={(e) => {
                  setDiscount({
                    ...discount,
                    discountRate: Number(e.target.value),
                  });
                }}
              />
            </Col>
          </Row>
        </CommonModal>
        <CommonModal modalData={addReportPriceModalData}>
          <Row>
            <Col>
              <Label>{t("OfferPages.ReportPrice")}</Label>
              <input
                className="form-control"
                type="number"
                placeholder={`${t("OfferPages.ReportPrice")}`}
                defaultValue={reportPrice}
                onChange={(e) => {
                  setReportPrice(Number(e.target.value));
                }}
              />
            </Col>
          </Row>
        </CommonModal>
        <CommonModal modalData={addTransportAndAccommodationModalData}>
          <>
            <div>
              <input
                value={value}
                className="form-control mb-2"
                onChange={(e) => {
                  setValue(e.target.value);
                  if (e.target.value === "") {
                    setSelectedLocation(null);
                  }
                }}
                placeholder={t("OfferPages.SelectLocation")}
              />
              {data.length > 0 && (
                <ul className="map-suggestions-list">
                  {data.map(({ description, place_id }) => (
                    <li
                      key={place_id}
                      onClick={() => handleSelect(description, place_id)}
                    >
                      {description}
                    </li>
                  ))}
                </ul>
              )}
            </div>
            <div className="Map">
              {!isLoaded ? (
                <h3>{t("Common.loading")}</h3>
              ) : (
                <>
                  <div className="map-wrapper">
                    <GoogleMap
                      mapContainerClassName="map_container"
                      center={userLocation}
                      onLoad={(mapInstance: any) => setMap(mapInstance)}
                      zoom={12}
                    >
                      {" "}
                      {userLocation && (
                        <MarkerF
                          position={userLocation}
                          title={t("OfferPages.YourLocation")}
                        />
                      )}
                      {selectedLocation && (
                        <MarkerF
                          position={selectedLocation}
                          title={t("OfferPages.SelectedLocation")}
                        />
                      )}
                    </GoogleMap>
                  </div>
                  {distance !== null && (
                    <div className="d-flex align-items-center justify-content-end mt-2">
                      {t("OfferPages.Distance")}: {(distance / 1000).toFixed(3)}{" "}
                      {t("OfferPages.KM")}
                    </div>
                  )}
                  <div className="mt-3">
                    <Label>{t("OfferPages.Vehicle")}</Label>
                    <select
                      className="form-control mb-2"
                      onChange={(e) => {
                        setSelectedVehicle(e.target.value.split("_")[0]);
                        setTransportAndAccommodation({
                          transportOptions: [
                            {
                              ...transportAndAccommodation.transportOptions[0],
                              vehicleId: e.target.value.split("_")[0],
                              consumeRate: Number(e.target.value.split("_")[1]),
                            },
                          ],
                          accommodationPrice:
                            transportAndAccommodation.accommodationPrice,
                        });
                      }}
                    >
                      <option value="">{t("Common.PleaseSelect")}</option>
                      {vehicleListData?.map((item, ind) => (
                        <option
                          key={ind}
                          value={item.id + "_" + item.consumeRate}
                          selected={
                            transportAndAccommodation?.transportOptions[0]
                              ?.vehicleId === item.id
                          }
                        >
                          {item?.brand?.name} {item.model} {item.licencePlate}
                        </option>
                      ))}
                    </select>
                    <Label>
                      {t("OfferPages.Distance") +
                        " (" +
                        t("Common.Meter") +
                        ")"}
                    </Label>
                    <input
                      value={distance}
                      className="form-control mb-2"
                      type="number"
                      onChange={(e) => {
                        const newDistance = Number(e.target.value);
                        setDistance(newDistance);
                        setTransportAndAccommodation({
                          transportOptions: [
                            {
                              ...transportAndAccommodation.transportOptions[0],
                              distance: Number(e.target.value),
                            },
                          ],
                          accommodationPrice:
                            transportAndAccommodation.accommodationPrice,
                        });
                      }}
                      placeholder={t("OfferPages.Distance")}
                    />
                    <Label>{t("OfferPages.GasLiterPrice")}</Label>
                    <input
                      // value={
                      //   transportAndAccommodation.transportOptions[0].fuelPrice
                      // }
                      className="form-control mb-2"
                      type="number"
                      onChange={(e) => {
                        setTransportAndAccommodation({
                          transportOptions: [
                            {
                              ...transportAndAccommodation.transportOptions[0],
                              fuelPrice: Number(e.target.value),
                            },
                          ],
                          accommodationPrice:
                            transportAndAccommodation.accommodationPrice,
                        });
                      }}
                      placeholder={t("OfferPages.GasLiterPrice")}
                    />
                    <Label>{t("OfferPages.AccommoationPrice")}</Label>
                    <input
                      className="form-control mb-2"
                      // defaultValue={Number(0).toFixed(2)}
                      // value={transportAndAccommodation.accommodationPrice}
                      type="number"
                      onChange={(e) => {
                        setTransportAndAccommodation({
                          ...transportAndAccommodation,
                          accommodationPrice: Number(e.target.value),
                        });
                      }}
                      placeholder={t("OfferPages.AccommoationPrice")}
                    />
                  </div>
                </>
              )}
            </div>
          </>
        </CommonModal>
        <CommonModal modalData={addTableModalData}>
          <>
            <DataTable
              data={experimentTableListData}
              columns={modalExperimentTableColumns}
              pagination={true}
              selectableRows={true}
              selectableRowsHighlight
              selectableRowsComponent={"input"}
              onSelectedRowsChange={(rows) => handleSelectedRowsChange(rows)}
              noDataComponent={<>{t("Common.noData")}</>}
            />
            <Row className="mt-3">
              <Col>
                <div className="text-end">
                  <Btn
                    color="danger"
                    onClick={() => toggleAddTableModal()}
                    className="me-3"
                  >
                    {t("Common.cancel")}
                  </Btn>
                  <Btn color="success" onClick={() => addTablesToTable()}>
                    {t("OfferPages.AddTable")}
                  </Btn>
                </div>
              </Col>
            </Row>
          </>
        </CommonModal>
        <CommonModal modalData={addExperimentModalData}>
          <>
            <DataTable
              data={experimentListData}
              columns={modalExperimentColumns}
              pagination={true}
              selectableRows={true}
              selectableRowsHighlight
              selectableRowsComponent={"input"}
              onSelectedRowsChange={(rows) => handleSelectedRowsChange(rows)}
              noDataComponent={<>{t("Common.noData")}</>}
            />
            <Row className="mt-3">
              <Col>
                <div className="text-end">
                  <Btn
                    color="danger"
                    onClick={() => toggleAddExperimentModal()}
                    className="me-3"
                  >
                    {t("Common.cancel")}
                  </Btn>
                  <Btn color="success" onClick={() => addExperimentsToTable()}>
                    {t("OfferPages.AddExperiment")}
                  </Btn>
                </div>
              </Col>
            </Row>
          </>
        </CommonModal>
        <CommonModal modalData={showOfferPdfModalData}>
          <div>
            <div className="my-5">
              <iframe
                src={blobURL}
                title="My PDF"
                width="100%"
                height="1000px"
              />
            </div>
          </div>
        </CommonModal>
      </Container>
    </div>
  );
}

// [
//   {
//     name: "name1T",
//     id: "10",
//     experiments: [
//       {
//         id: "1",
//         tableId: 1,
//         name: "Tanecikli Maddenin (TOZ) Kütle Derişiminin Tayini",
//         acc: "AK",
//         unitPrice: 500,
//         unit: 1,
//         totalPrice: 500,
//       },
//       {
//         id: "2",
//         tableId: 1,
//         name: "Gaz Halindeki Toplam Organik Karbonun (TOC) Kütle Derişimin Tayini",
//         acc: "KD",
//         unitPrice: 1500,
//         unit: 2,
//         totalPrice: 3000,
//       },
//     ],
//   },
//   {
//     name: "name2T",
//     id: "20",
//     experiments: [
//       {
//         id: "1",
//         tableId: 1,
//         name: "Tanecikli Maddenin (TOZ) Kütle Derişiminin Tayini",
//         acc: "AK",
//         unitPrice: 500,
//         unit: 1,
//         totalPrice: 500,
//       },
//     ],
//   },
//   {
//     name: "name3T",
//     id: "30",
//     experiments: [
//       {
//         id: "1",
//         tableId: 1,
//         name: "Tanecikli Maddenin (TOZ) Kütle Derişiminin Tayini",
//         acc: "AK",
//         unitPrice: 500,
//         unit: 1,
//         totalPrice: 500,
//       },
//       {
//         id: "2",
//         tableId: 1,
//         name: "Gaz Halindeki Toplam Organik Karbonun (TOC) Kütle Derişimin Tayini",
//         acc: "KD",
//         unitPrice: 1500,
//         unit: 2,
//         totalPrice: 3000,
//       },
//     ],
//   },
// ]

// [
//   {
//     name: "name1",
//     id: "3",
//     code: "AK",
//     unitPrice: 200,
//     unit: 1,
//     totalPrice: 200,
//   },
//   {
//     name: "name2",
//     id: "4",
//     code: "AK",
//     unitPrice: 300,
//     unit: 1,
//     totalPrice: 300,
//   },
//   {
//     name: "name3",
//     id: "5",
//     code: "AK",
//     unitPrice: 700,
//     unit: 1,
//     totalPrice: 700,
//   },
//   {
//     name: "name4",
//     id: "6",
//     code: "AK",
//     unitPrice: 600,
//     unit: 1,
//     totalPrice: 600,
//   },
// ]
