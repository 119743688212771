import * as Paths from "./../files/Paths";
import * as Headers from "./../files/Headers";
import request from "./../files/Request";

// Types
import { ListFilterType } from "Types/Common/ListFilterType";
import { defaultPageAndSizeForAll } from "Utils/Constants";

export const getOffers = async (filter: ListFilterType, filterObj: any) => {
  const filterParams = {
    name: filterObj?.name,
    code: filterObj?.code,
    barcodingCode: filterObj?.barcodingCode
  };
  return request.get(
    `${Paths.node}/offer/all`,
    {
      page: filter.page,
      size: filter.size,
      ...(filter?.sort && {sort: filter?.sort[0]}),
      ...filterParams
    },
    { headers: Headers.headers }
  );
};

export const getLaboratoriesAll = async () => {
  return request.get(
    `${Paths.emission}/laboratory/all/minimal`,
    {
      page: defaultPageAndSizeForAll.page,
      size: defaultPageAndSizeForAll.size
    },
    { headers: Headers.headers }
  );
};

export const getLaboratoryTypeAll = async () => {
  return request.get(
    `${Paths.emission}/laboratory-type/all`,
    {
      page: defaultPageAndSizeForAll.page,
      size: defaultPageAndSizeForAll.size
    },
    { headers: Headers.headers }
  );
};

export const getOfferById = async (id: any) => {
  return request.get(
    `${Paths.node}/offer/${id}`, {},
    { headers: Headers.headers }
  );
};

export const deleteOffer = async (id: string) => {
  return request.delete(
    `${Paths.node}/offer/${id}`,
    {},
    {
      headers: Headers.headers,
    }
  );
};

export const updateOffer = async (offer: any) => {
  return request.put(`${Paths.node}/offer`, offer, {
    headers: Headers.headers,
  });
};

export const addOffer = async (offer: any) => {
  return request.post(`${Paths.node}/offer`, offer, {}, {
    headers: Headers.headers,
  });
};
