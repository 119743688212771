import React from "react";
import { Col, Row } from "reactstrap";
import SalesStatistic from "./SalesStatistic";
import CustomerCard from "Utils/CommonComponents/DashboardsCommon/DefaultCommon/CustomerCard";
import { customerCardData } from "../../../../Data/DashboardsData/DefaultData";
import SalesOverview from "./SalesOverview";
import NotificationCard from "./NotificationCard";
import StatisticsCard from "./StatisticsCard";

export default function RightBackground() {
  return (
    <Col xl={12} className="col-xl-100 box-col-12">
      <Row>
        <CustomerCard data={customerCardData} />
        <NotificationCard />
        <SalesOverview />
        <SalesStatistic />
        <Row className="proorder-xl-9 pe-0">
          <Col xl={3} md={12} className="col-xl-100 pe-0">
            <Row>
              <StatisticsCard />
            </Row>
          </Col>
        </Row>
      </Row>
    </Col>
  );
}
